import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import validator from 'validator';
import { Typography } from '../../typography';
import { Button } from '../../button';
import { Input, InputPhoneNumber } from '../../input';
import { Select } from '../../select';
import { ModalBase, ModalWrapper, ModalFooterButtons } from '../ModalComponents';
import { getPhoneNumberValue } from '../../../utils';
import {
  tableDotsActions,
  gateAllTableDotsActions,
  containerTypes,
  INPUT_ERRORS,
  REGEX,
} from '../../../const';

export const gateModal: any = {};

export const ConfirmModal = () => {
  const [open, setOpen] = useState(false);
  const [containerNumber, setContainerNumber] = useState('');
  const [containerType, setContainerType] = useState('');
  const [driverFirstName, setDriverFirstName] = useState('');
  const [driverLastName, setDriverLastName] = useState('');
  const [licensePlateNumber, setLicensePlateNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [authorizedLicensePlateNumbers, setAuthorizedLicensePlateNumbers] = useState([]);
  const [comment, setComment] = useState('');

  const [, setUpdate] = useState<any>();

  const validators = {
    driverFirstName:
      !!driverFirstName &&
      (!validator.isLength(driverFirstName, { max: 30 }) || !REGEX.USER_NAME.test(driverFirstName)),
    driverLastName:
      !!driverLastName &&
      (!validator.isLength(driverLastName, { max: 30 }) || !REGEX.USER_NAME.test(driverLastName)),
    licensePlateNumber: licensePlateNumber
      ? !validator.isLength(licensePlateNumber, { min: 4, max: 10 })
        ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER_LENGTH
        : !REGEX.LICENSE_PLATE_NUMBER.test(licensePlateNumber)
        ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER
        : ''
      : '',
    phoneNumber: phoneNumber
      ? !isValidPhoneNumber(`+${getPhoneNumberValue(phoneNumber)}`)
        ? INPUT_ERRORS.NOT_VALID.PHONE_NUMBER
        : ''
      : '',
    comment: comment
      ? !validator.isLength(comment, { min: 10, max: 200 })
        ? INPUT_ERRORS.NOT_VALID.COMMENT_LENGTH
        : ''
      : '',
  };
  if (gateModal.type === 'GATE_OUT') {
    validators.licensePlateNumber = !validator.isLength(licensePlateNumber, {
      min: 4,
      max: 10,
    })
      ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER_LENGTH
      : !REGEX.LICENSE_PLATE_NUMBER.test(licensePlateNumber)
      ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER
      : '';
  }

  useEffect(() => {
    gateModal.open = () => {
      gateModal.action = [...tableDotsActions, ...gateAllTableDotsActions].find(
        (obj) => obj.value === gateModal.val,
      )?.label;
      gateModal.name = `${gateModal.action} ${gateModal.containerNumber}`;

      if (gateModal.type === 'GATE_IN') {
        setContainerType(gateModal.containerType);
        setDriverFirstName(gateModal.driverFirstName || '');
        setDriverLastName(gateModal.driverLastName || '');
        setLicensePlateNumber(gateModal.licensePlateNumber || '');
        if (gateModal.phoneNumber) setPhoneNumber(gateModal.phoneNumber);
      }
      if (gateModal.type === 'GATE_OUT' && gateModal.action === 'Edit') {
        setDriverFirstName(gateModal.driverFirstName || '');
        setDriverLastName(gateModal.driverLastName || '');
        setLicensePlateNumber(gateModal.licensePlateNumber || '');
      }
      if (gateModal.type === 'GATE_OUT' && gateModal.action === 'Complete') {
        setContainerNumber(gateModal.containerNumber);
        setContainerType(gateModal.containerType);
        setDriverFirstName(gateModal.driverFirstName || '');
        setDriverLastName(gateModal.driverLastName || '');
        setLicensePlateNumber(gateModal.licensePlateNumber || '');
        if (gateModal.phoneNumber) setPhoneNumber(gateModal.phoneNumber);
        setAuthorizedLicensePlateNumbers(gateModal.authorizedLicensePlateNumbers || []);
        setComment(gateModal.comment || '');
      }

      gateModal.isSubmitClicked = false;
      if (gateModal.type !== '') setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    Object.keys(gateModal).forEach((key) => {
      if (key !== 'open') delete gateModal[key];
    });
    setOpen(!open);
  };

  const handleCompleteClick = () => {
    if (gateModal.action !== 'Complete') return true;

    gateModal.isSubmitClicked = true;
    setUpdate(Math.random());

    const otherProps: any = {};
    let otherBool = false;
    if (gateModal.type === 'GATE_IN') {
      otherProps.containerType = containerType;
      otherBool = !containerType;
    }
    if (gateModal.type === 'GATE_OUT' && gateModal.action === 'Complete') {
      otherProps.licensePlateNumber = licensePlateNumber;
      otherBool = !licensePlateNumber;
    }

    if (
      gateModal.type === 'GATE_IN' ||
      (gateModal.type === 'GATE_OUT' && gateModal.action === 'Complete')
    ) {
      if (!driverFirstName || !driverLastName || !licensePlateNumber || !phoneNumber || otherBool) {
        const props: any = {
          ...otherProps,
        };
        const messages = Object.keys(props)
          .filter((key: any) => !props[key])
          .map((key) => `${key} is required`)
          .join('\n');
        if (messages) return;
      }
      if ([...Object.values(validators).map((val) => !val)].some((val) => !val)) {
        return;
      }

      gateModal.type = '';
      gateModal.containerNumber = containerNumber;
      gateModal.containerType = containerType;
      gateModal.driverFirstName = driverFirstName;
      gateModal.driverLastName = driverLastName;
      gateModal.licensePlateNumber = licensePlateNumber;
      gateModal.phoneNumber = getPhoneNumberValue(phoneNumber);
      gateModal.comment = comment;
    }

    gateModal.isSubmitClicked = false;
    return true;
  };

  const handleSubmitClick = async () => {
    const isValid = handleCompleteClick();
    if (!isValid) return;

    await gateModal.fc();
    setOpen(false);
  };

  return (
    <ModalBase id={'confirm-universal-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          Are you sure to {gateModal.name}?
        </Typography>
        {open &&
          (gateModal.type === 'GATE_IN' || gateModal.type === 'GATE_OUT') &&
          gateModal.action === 'Complete' && (
            <>
              {gateModal.type === 'GATE_OUT' && (
                <Select
                  label={'Allowed License Plate Numbers*'}
                  options={authorizedLicensePlateNumbers}
                  value={licensePlateNumber}
                  onChange={(e) => setLicensePlateNumber(e.target.value)}
                />
              )}
              {gateModal.type === 'GATE_IN' && (
                <Select
                  label={`Container Type*`}
                  placeholder={'Select Container Type'}
                  options={containerTypes}
                  value={containerType}
                  onChange={(e) => {
                    setContainerType(e.target.value);
                  }}
                  error={gateModal.isSubmitClicked && !containerType}
                  helperText={
                    gateModal.isSubmitClicked &&
                    !containerType &&
                    INPUT_ERRORS.REQUIRED.CONTAINER_TYPE
                  }
                />
              )}
              <Input
                label={`Driver First Name`}
                placeholder={'Driver First Name'}
                data-size={'medium'}
                defaultValue={driverFirstName}
                onChange={(e) => setDriverFirstName(e.target.value)}
                error={validators.driverFirstName}
                helperText={validators.driverFirstName ? INPUT_ERRORS.NOT_VALID.DRIVER_NAME : ''}
              />
              <Input
                label={`Driver Last Name`}
                placeholder={'Driver Last Name'}
                data-size={'medium'}
                defaultValue={driverLastName}
                onChange={(e) => setDriverLastName(e.target.value)}
                error={validators.driverLastName}
                helperText={validators.driverLastName ? INPUT_ERRORS.NOT_VALID.DRIVER_NAME : ''}
              />
              <InputPhoneNumber
                label={`Driver Phone Number`}
                placeholder={'Driver Phone Number'}
                data-size={'medium'}
                value={phoneNumber}
                defaultValue={'+998'}
                defaultCountry={'UZ'}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={!!validators.phoneNumber}
                helperText={validators.phoneNumber}
              />
              {gateModal.type === 'GATE_IN' && (
                <Input
                  label={`License Plate Number`}
                  placeholder={'License Plate Number'}
                  data-size={'medium'}
                  defaultValue={licensePlateNumber}
                  onChange={(e) => setLicensePlateNumber(e.target.value)}
                  error={!!validators.licensePlateNumber}
                  helperText={validators.licensePlateNumber}
                />
              )}
              {gateModal.type === 'GATE_OUT' && (
                <Input
                  data-size={'medium'}
                  label={'Comment'}
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                  error={!!validators.comment}
                  helperText={validators.comment}
                />
              )}
            </>
          )}
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size={'medium'} data-color={'primary'} onClick={handleSubmitClick}>
            {gateModal.action}
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
