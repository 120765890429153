export const getFilesChanges = (upload: any[], uploadFiles: any[], deleteFiles: any[]) => {
  let data: any[] = [];

  if (upload.length) {
    data = upload.map((img: any, i: any) => ({
      action: 'upload',
      file: uploadFiles[i],
    }));
  }
  if (deleteFiles.length) data = [...data, ...deleteFiles];

  return data;
};
