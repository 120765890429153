import { useParams, Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../../dashboard';
import { YardCompaniesPage } from './YardCompaniesComponents';
import { CompanyViewHeader, CompanyViewForm } from '../CompanyViewComponents';
import { useStore } from '../../../context';
import { useYardCompanyQuery } from '../../../hooks';
import { showToRoles } from '../../../utils';

export const YardCompaniesView = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const params: any = useParams();
  const { data, isFetching } = useYardCompanyQuery({ id: params.id });

  if (isFetching) return <></>;
  if (!showToRoles(['SUPER_ADMIN'], user) || !data)
    return <Navigate to={'/dashboard/yardcompanies'} replace />;

  return (
    <YardCompaniesPage>
      <CompanyViewHeader data={data} />
      <DashboardFormWrapper flow={'row'}>
        <CompanyViewForm data={data} />
      </DashboardFormWrapper>
    </YardCompaniesPage>
  );
};
