import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { chipDefaultColors } from './chip-default-colors';
import { colors } from '../../const';

type ColorTypes =
  | 'lightblue'
  | 'blue'
  | 'lightgrey'
  | 'grey'
  | 'lightgreen'
  | 'green'
  | 'lightred'
  | 'red'
  | 'lightyellow'
  | 'yellow';
const themeColors = {
  lightblue: { main: colors['--primary-40'], contrastText: colors['--primary-100'] },
  blue: { main: colors['--primary-100'], contrastText: colors['--white'] },
  lightgrey: { main: colors['--gray-40'], contrastText: colors['--gray-80'] },
  grey: { main: colors['--gray-80'], contrastText: colors['--white'] },
  lightgreen: { main: colors['--green-40'], contrastText: colors['--green-100'] },
  green: { main: colors['--green-90'], contrastText: colors['--white'] },
  lightred: { main: colors['--red-40'], contrastText: colors['--red-100'] },
  red: { main: colors['--red-80'], contrastText: colors['--white'] },
  lightyellow: { main: colors['--yellow-40'], contrastText: colors['--yellow-100'] },
  yellow: { main: colors['--yellow-100'], contrastText: colors['--white'] },
};

const theme = (color: ColorTypes) =>
  createTheme({
    palette: {
      primary: themeColors[color],
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            height: 'max-content',
            padding: '0.4rem 0.8rem',
            borderRadius: 'var(--border-radius-4)',

            lineHeight: 'var(--line-height-2)',
            fontSize: 'var(--font-size-2)',
            fontWeight: 'var(--font-weight-400)',
            fontFamily: 'Inter',

            '& > span': {
              padding: 0,
            },
          },
          sizeSmall: {
            padding: '0.2rem 0.8rem',
          },
        },
      },
    },
  });

interface ChipProps extends MuiChipProps {
  'data-color'?: ColorTypes;
}

export const Chip: React.FC<ChipProps> = ({ ...props }: ChipProps) => {
  const dataColor = props['data-color'] || chipDefaultColors(props.label) || 'blue';

  return (
    <ThemeProvider theme={theme(dataColor)}>
      <MuiChip color={'primary'} {...props} />
    </ThemeProvider>
  );
};
