export const REGEX = {
  ZIP_CODE: /^[A-Za-z0-9\s-]+$/,
  PHONE_NUMBER: /^\+?\d+$/,
  CONTAINER_NUMBER: /^[A-Z]{3}[U,J,Z]{1}\d{6}\d{1}$/,
  EMAIL:
    /^(?!\.)(?!.*\.$)(?!.*\.\.)(?!.*[._-]$)(?!.*[._-]{2})[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.+[a-zA-Z]{2,}$/,
  STREET_ADDRESS: /^(?!.*--)(?!.*,,)(?!.*\.\.)(?!.*,-)(?!.*,-)[A-Za-z0-9\s,-.]*$/,
  CITY: /^[A-Za-z\s-]+$/,
  COUNTRY: /^[A-Za-z\s-]+$/,
  COMPANY_NAME: /^[A-Za-z0-9](?!.*?[^\s\w.-])([A-Za-z\d\s\-.]*)(?<![.\s-])$/,
  CODE: /^[A-Za-z0-9]+$/,
  USER_NAME: /^[A-Za-z]+( [A-Za-z]*)*$/,
  PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[?!/])[A-Za-z\d?!/]+/,
  LICENSE_PLATE_NUMBER:
    /^(?!.*[!@#$%^&*()+=[\]{}|;':",.<>?])(?!.*-{2,})[^\p{P}-][\p{L}\p{N}-]*[^\p{P}-]$/u,
  RELEASE_NUMBER:
    /^(?!-)(?!.*--)(?=.*[A-Za-z0-9])[A-Za-z0-9]+([-]{1}[A-Za-z0-9]+){0,1}([-]{1}[A-Za-z0-9]+){0,1}$/,
  URL: /(https?:\/\/[^\s]+)|(http?:\/\/[^\s]+)/g,
};
