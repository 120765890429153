import axios from 'axios';
import { clientYardService } from './client-yard';
import { uniqueArray } from '../../utils';
import { User } from '../../types';

export const createCompany = ({ path, props }: any) =>
  axios.post(path, props).catch((err) => {
    throw new Error(err.response.data.message);
  });

class YardCompanies {
  getAll = (data: any = {}) => axios.get('/yard-companies', { ...data }).then((res) => res.data);

  get = (data: any) =>
    axios.get(`/yard-companies/${data.params.id}`, {}).then((res) => res.data.data);
}

class Yards {
  getAll = (data: any = {}) =>
    axios
      .get(`/yards`, {
        ...data,
      })
      .then((res) => res.data);

  options = async (data: any) => {
    if (!data.yardCompanyId) return;

    const res = await this.getAll({
      params: {
        yardCompanyId: data.yardCompanyId,
        notLoading: true,
      },
    });

    const country = uniqueArray(res.data, (obj) => obj.address.country).map((obj) => ({
      label: obj.address.country,
      id: obj.address.country,
    }));
    const city = uniqueArray(res.data, (obj) => obj.address.city).map((obj) => ({
      label: obj.address.city,
      country: obj.address.country,
      id: obj.address.city,
    }));
    const yard = res.data.map((obj: any) => ({
      label: obj.name,
      country: obj.address.country,
      city: obj.address.city,
      id: obj.id,
    }));

    return { country, city, yard };
  };

  clientsForYard = (data: any) =>
    axios
      .get(`/yards/${data.id}/clients`, {
        params: data.params,
      })
      .then((res) => res.data.data);
}

class Clients {
  getAll = (data: any = {}) =>
    axios
      .get(`/clients`, {
        ...data,
      })
      .then((res) => res.data);

  get = (props: any) => axios.get(`/clients/${props.params.id}`);

  edit = ({ id, ...props }: any) => axios.patch(`/clients/${id}`, props);
}

class BelongsToOptions {
  YardCompany = (companyName?: string, withoutAll?: boolean) => async () => {
    const params: any = {
      notLoading: true,
      short: true,
    };

    const res = await yardCompaniesService.getAll({
      params,
    });

    const result = res.data.map((obj: any) => ({ label: obj.legalName, id: obj.id }));
    if (!withoutAll) result.unshift({ label: 'All', id: 'All' });

    return result;
  };

  Yard = async (yardCompanyId?: string, withoutAll?: boolean, returnKey?: string) => {
    const params: any = { notLoading: true };

    if (yardCompanyId && yardCompanyId !== 'All') params.yardCompanyId = yardCompanyId;

    const res = await yardsService.getAll({
      params,
    });

    const country = uniqueArray(res.data, (obj) => obj.address.country).map((obj) => ({
      label: obj.address.country,
      id: obj.address.country,
    }));
    const city = uniqueArray(res.data, (obj) => obj.address.city).map((obj) => ({
      label: obj.address.city,
      country: obj.address.country,
      id: obj.address.city,
    }));
    const yard = res.data.map((obj: any) => ({
      label: obj.name,
      country: obj.address.country,
      city: obj.address.city,
      id: obj.id,
    }));

    if (!withoutAll) {
      country.unshift({ label: 'All', id: 'All' });
      city.unshift({ label: 'All', country: 'All', id: 'All' });
      yard.unshift({ label: 'All', id: 'All' });
    }

    const result: any = { country, city, yard };

    if (returnKey) {
      return result[returnKey];
    }

    return result;
  };

  Client = (companyName?: string, withoutAll?: boolean, showClientName?: boolean) => async () => {
    const params: any = { notLoading: true };

    const res = await clientsService.getAll({
      params,
    });

    const result = res.data.map((obj: any) => ({
      label: showClientName ? obj.legalName : obj.code,
      id: obj.id,
    }));
    if (!withoutAll) result.unshift({ label: 'All', id: 'All' });

    return result;
  };
}

// this class for ADMINS
class BelongsToFullOptions {
  Yard = (clientId: string, action: string, withoutAll: boolean, user: User) => async () => {
    const params: any = { notLoading: true };

    let res = await clientYardService.getYardsForClient(
      {
        params,
        id: clientId,
      },
      user,
    );
    let result: any;

    if (action === 'add-yards') res = res.notAssignedYards;
    if (action === 'reactivate-yards') res = res.deactivated;
    if (action === 'deactivate-yards') res = res.active;

    if (action === 'add-yards') {
      result = res.map((obj: any) => ({ label: obj.name, id: obj.id }));
    } else {
      result = res.map((obj: any) => ({ label: obj.yard.name, id: obj.yard.id }));
    }
    if (!withoutAll) result.unshift({ label: 'All', id: 'All' });

    return result;
  };

  Client = (yardId: string, action: string, withoutAll: boolean) => async () => {
    if (!yardId) return [];

    const params: any = { notLoading: true };

    let res = await yardsService.clientsForYard({
      params,
      id: yardId,
    });
    let result: any;

    if (action === 'add-clients') res = res.notAssigned;
    if (action === 'reactivate-clients') res = res.deactived;
    if (action === 'deactivate-clients') res = res.active;

    if (action === 'add-clients') {
      result = res.map((obj: any) => ({ label: obj.code, id: obj.id }));
    } else {
      result = res.map((obj: any) => ({ label: obj.client.code, id: obj.client.id }));
    }
    if (!withoutAll) result.unshift({ label: 'All', id: 'All' });

    return result;
  };
}

export const yardCompaniesService = new YardCompanies();
export const yardsService = new Yards();
export const clientsService = new Clients();
export const belongsToOptions = new BelongsToOptions();
export const belongsToOptionsQueryKeys = {
  YardCompany: 'yard-companies-short',
  Yard: 'yard',
  Client: 'client',
};
export const belongsToFullOptions = new BelongsToFullOptions();
