import { useQuery } from '../../../hooks';
import { employeesService } from '../../../services';

export const useEmployeesQuery = ({ belongsTo, ...keys }: any = {}) =>
  useQuery(
    ['employees', belongsTo, JSON.stringify(keys)],
    () => {
      const params = { ...keys };

      delete params.companyName;
      delete params.companyId;
      delete params.yardCompanyName;
      if (belongsTo === 'Client') params.clientId = keys.companyId;
      if (belongsTo === 'Yard') params.yardId = keys.companyId;
      if (belongsTo === 'YardCompany') params.yardCompanyId = keys.companyId;

      return employeesService
        .getAll({
          belongsTo,
          params,
        })
        .then((res) => res.data);
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );
