import styled, { css } from 'styled-components';
import {
  FileDownload as FileDownloadIcon,
  DeleteForever as DeleteForeverIcon,
  VisibilityRounded as VisibilityRoundedIcon,
} from '@mui/icons-material';
import { Typography } from '../typography';
import { Button } from '../button';
import { containersService } from '../../services';

const FileRowBase = styled.div<{ withoutBorder?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-3);
  align-content: center;
  align-items: center;

  height: 2.4rem;
  box-sizing: content-box;
  padding: var(--spacing-6) 0;

  &:nth-last-child(1) {
    padding-bottom: 0;
  }

  ${(props) =>
    !props.withoutBorder &&
    css`
      border-top: 0.1rem solid var(--gray-40);
    `}
`;

const FileName = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FileActionButton = styled(Button)`
  &[data-square] {
    height: 3rem;
    min-width: 3rem;
    padding: 1.5rem;
  }
`;

interface FileRowProps {
  file: { name: string; url?: string };
  handleDownloadClick?: () => void;
  handleDeleteFileClick: (() => void) | null;
  withoutBorder?: boolean;
}

export const FileRow: React.FC<FileRowProps> = ({
  file,
  handleDownloadClick,
  handleDeleteFileClick,
  withoutBorder,
}: FileRowProps) => {
  return (
    <FileRowBase withoutBorder={withoutBorder}>
      <FileName variant={'textM'} weight={'medium'} color={'--gray-90'}>
        {file.name.slice(file.name.indexOf('_') + 1)}
      </FileName>
      <FileActionButton
        size={'small'}
        isSquare
        onClick={() => containersService.viewDocument(file.url || file)}
      >
        <VisibilityRoundedIcon />
      </FileActionButton>
      {file.url && handleDownloadClick && (
        <FileActionButton size={'small'} isSquare onClick={handleDownloadClick}>
          <FileDownloadIcon />
        </FileActionButton>
      )}
      {handleDeleteFileClick && (
        <FileActionButton
          size={'small'}
          data-color={'destructive'}
          data-focus={true}
          isSquare
          onClick={handleDeleteFileClick}
        >
          <DeleteForeverIcon />
        </FileActionButton>
      )}
    </FileRowBase>
  );
};
