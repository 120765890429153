import { useQuery } from '../../../hooks';
import { showToRoles } from '../../../utils';
import {
  yardCompaniesService,
  yardsService,
  clientsService,
  clientYardService,
  belongsToOptions,
} from '../../../services';

export const useYardCompaniesQuery = (keys: any = {}) =>
  useQuery(
    ['yard-companies', JSON.stringify(keys)],
    () => {
      return yardCompaniesService.getAll({ params: keys });
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );

export const useYardCompanyQuery = ({ id }: any) =>
  useQuery(
    [`yard-companies`, id],
    async () => {
      const params: any = { id };

      const res = await yardCompaniesService
        .get({
          params,
        })
        .catch(() => ({}));

      return res;
    },
    { initialData: {} },
  );

export const useYardsQuery = (keys: any = {}) =>
  useQuery(
    ['yards', JSON.stringify(keys)],
    async () => {
      delete keys.companyLegalName;

      return yardsService.getAll({
        params: keys,
      });
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );

export const useYardOptionsQuery = (
  keys: any = {},
  { user, yardCompanyId, clientId, country, city }: any,
) => {
  let { data } = useQuery<any>(
    ['key', JSON.stringify({ ...keys })],
    () => {
      if (showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) && !yardCompanyId)
        return { country: [], city: [], yard: [] };

      if (showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) && yardCompanyId)
        return yardsService.options({ yardCompanyId: yardCompanyId });

      if (showToRoles(['SUPER_ADMIN'], user)) return belongsToOptions.Yard('All', true);

      if (clientId) return clientYardService.assignedYards({ id: clientId }, user);

      return { country: [], city: [], yard: [] };
    },
    {
      initialData: { country: [], city: [], yard: [] },
    },
  );

  if (country) data = { ...data, city: data.city.filter((obj: any) => country === obj.country) };
  if (country || city)
    data = {
      ...data,
      yard: data.yard.filter(
        (obj: any) => (!country || country === obj.country) && (!city || city === obj.city),
      ),
    };

  return {
    data,
  };
};

export const useClientsQuery = (keys: any = {}) =>
  useQuery(
    ['clients', JSON.stringify(keys)],
    () => {
      return clientsService.getAll({
        params: keys,
      });
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );

export const useClientQuery = ({ id }: any) =>
  useQuery(
    ['clients', id],
    () => {
      return clientsService
        .get({
          params: { id },
        })
        .then((res) => res.data.data)
        .catch(() => ({}));
    },
    { initialData: {} },
  );
