import { useState } from 'react';
import { Typography, Link, Input, Button, useSnackbar } from '../../../design-system';
import {
  AuthHeaderBase,
  AuthHeaderSubTitle,
  AuthFormBase,
  AuthFooterBase,
  AuthFooterBorderLine,
  AuthFooterSocials,
} from '../AuthComponents';
import { useForgotPasswordMutation } from '../../../hooks';
import { INPUT_ERRORS } from '../../../const';

export const ForgotPasswordHeader = () => {
  return (
    <AuthHeaderBase>
      <Typography variant={'h4'} align={'center'} weight={'bold'}>
        Forgot Password?
      </Typography>
      <AuthHeaderSubTitle>
        <Typography variant={'textM'} color={'--gray-80'} align={'center'}>
          We will email you an instruction to reset your password!
        </Typography>
      </AuthHeaderSubTitle>
    </AuthHeaderBase>
  );
};

export const ForgotPasswordForm = () => {
  const { mutate, isLoading } = useForgotPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const validators = {
    email: !email ? INPUT_ERRORS.REQUIRED.EMAIL : '',
  };

  const handleSubmitClick = async () => {
    if (!Object.values(validators).every((isError) => !isError)) {
      setIsSubmitClicked(true);
      return;
    }

    await mutate(
      { email },
      {
        onSuccess: (res) => {
          enqueueSnackbar(res.data.message, 'success');
        },
        onError: (err: any) => {
          enqueueSnackbar(err.response.data.message, 'error');
        },
        onSettled: () => {
          setIsSubmitClicked(false);
        },
      },
    );
  };

  return (
    <AuthFormBase>
      <Input
        defaultValue={email}
        onChange={(e) => setEmail(e.target.value)}
        label={'Email'}
        placeholder={'Enter Email Address'}
        type={'email'}
        data-size={'large'}
        error={isSubmitClicked && validators.email}
        helperText={isSubmitClicked && validators.email}
      />
      <Button
        data-color={'primary'}
        size={'large'}
        onClick={handleSubmitClick}
        disabled={isLoading}
      >
        Send reset link
      </Button>
    </AuthFormBase>
  );
};

export const ForgotPasswordFooter = () => {
  return (
    <AuthFooterBase>
      <AuthFooterBorderLine />
      <AuthFooterSocials>
        <Link to={'/signin'}>
          <Button data-color={'white'} size={'large'}>
            Back to Sign In
          </Button>
        </Link>
      </AuthFooterSocials>
    </AuthFooterBase>
  );
};
