import styled, { css } from 'styled-components';
import { Typography } from '../typography';

interface DrawerBaseProps {
  isOpen: boolean;
}

export const DrawerBase = styled.div<DrawerBaseProps>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6.8rem 1fr;

  width: 25.5rem;
  height: 100vh;
  padding: 0 1.6rem;
  background-color: var(--gray-90);
  position: relative;
  transition: width 0.3s, padding 0.3s;

  & > *:nth-child(2) {
    transition: margin 0.3s, padding 0.3s;
    margin-right: -1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 1.6rem;

    & > *:nth-child(1) {
      margin-top: 1.6rem;
      transition: margin 0.3s;
    }
  }

  ${(props) =>
    !props.isOpen &&
    css`
      width: 6.8rem;
      padding: 0;

      & > *:nth-child(2) {
        margin: 0;
        padding: 0;

        & > *:nth-child(1) {
          margin-top: 0;
        }
      }
    `}
`;

export const DrawerBody = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;

  height: 100%;
  overflow-y: overlay;
`;

interface DrawerCategoryTitleProps {
  isOpen: boolean;
  children: string | React.ReactNode;
}

export const DrawerCategoryTitleWrapper = styled.div<DrawerCategoryTitleProps>`
  display: grid;

  height: var(--line-height-1);
  margin-top: 2.8rem;
  margin-bottom: 1.2rem;
  margin-left: 1.6rem;
  overflow: hidden;
  transition: height 0.3s, margin 0.3s;

  ${(props) =>
    !props.isOpen &&
    css`
      height: 0;
      margin-top: 0rem;
      margin-bottom: 0rem;
    `}
`;

export const DrawerCategoryTitleBase = styled(Typography).attrs({
  variant: 'textXS',
  color: '--gray-60',
})``;

export const DrawerCategoryTitle: React.FC<DrawerCategoryTitleProps> = ({
  isOpen,
  ...props
}: DrawerCategoryTitleProps) => {
  return (
    <DrawerCategoryTitleWrapper isOpen={isOpen}>
      <DrawerCategoryTitleBase {...props} />
    </DrawerCategoryTitleWrapper>
  );
};
