import axios from 'axios';
import { showToRoles, uniqueArray } from '../../utils';
import { User } from '../../types';

interface AssignProps {
  METHOD: 'post' | 'patch';
  path:
    | 'addClients'
    | 'reactivateClients'
    | 'deactivateClients'
    | 'addYards'
    | 'reactivateYards'
    | 'deactivateYards';
  [x: string]: any;
}

class ClientYard {
  assignClients = ({ METHOD, path, ...props }: AssignProps) =>
    axios[METHOD](`/client-yards/${path}`, { yardId: props.id, clientIds: props.assignsId });

  assignYards = ({ METHOD, path, ...props }: AssignProps) =>
    axios[METHOD](`/client-yards/${path}`, { clientId: props.id, yardIds: props.assignsId });

  getClientsForYardShort = (data: any) =>
    axios
      .get(`/client-yards/clients`, {
        params: data.params,
      })
      .then((res) => res.data.data);

  getYardsForClient = (data: any, user: User) => {
    let path = `/clients/${data.id}/yards`;
    if (showToRoles('client', user)) {
      path = '/client-yards/yards';
    }

    return axios
      .get(`${path}`, {
        params: data.params,
      })
      .then((res) => res.data.data);
  };

  assignedClients = (props: any) => async () => {
    if (!props.id) return [];

    const res = await this.getClientsForYardShort({
      params: { yardId: props.id, notLoading: true },
    });

    return res
      .filter((obj: any) => obj.status === 'ACTIVE')
      .map((obj: any) => ({
        label: obj.client.code,
        id: obj.client.id,
        disabled: !!props.disabledClientIds?.includes(obj.client.id),
      }));
  };

  assignedYards = async (props: any, user: User) => {
    if (!props.id) return [];

    let res = await this.getYardsForClient({ id: props.id, params: { notLoading: true } }, user);

    if (showToRoles('client', user)) {
      const notConnectedYards = res
        .filter((obj: any) => obj.status !== 'ACTIVE')
        .map((obj: any) => ({ ...(obj.yard ? obj.yard : obj), isNew: true }));
      res = res.filter((obj: any) => obj.status === 'ACTIVE').map((obj: any) => obj.yard);

      const country = uniqueArray([...res, ...notConnectedYards], (obj) => obj.address.country).map(
        (obj) => ({
          label: obj.address.country,
          id: obj.address.country,
          isNew: obj.isNew,
        }),
      );
      const city = uniqueArray([...res, ...notConnectedYards], (obj) => obj.address.city).map(
        (obj) => ({
          label: obj.address.city,
          country: obj.address.country,
          id: obj.address.city,
          isNew: obj.isNew,
        }),
      );
      const yard = [...res, ...notConnectedYards].map((obj: any) => ({
        label: obj.name,
        country: obj.address.country,
        city: obj.address.city,
        id: obj.id,
        isNew: obj.isNew,
      }));

      return { country, city, yard };
    }

    return { country: [], city: [], yard: [] };
  };

  sendContract = async (props: any) =>
    axios.post(
      '/mailer/client-interest-in-yard',
      {
        yardId: props.yardId,
      },
      { params: { notLoading: true } },
    );
}

export const clientYardService = new ClientYard();
