import { useEffect, useState } from 'react';
import { Typography } from '../typography';
import { Button } from '../button';
import { InputContainer } from '../input';
import { Select } from '../select';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { useStore } from '../../context';
import { containerValidator, showToRoles } from '../../utils';
import { useEditContainerMutation } from '../../hooks';
import { containerTypes, INPUT_ERRORS } from '../../const';
import { enqueueSnackbar } from '../snackbar';

export const containerEditModal: any = {};

export const ContainerEditModal = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const { mutate } = useEditContainerMutation();
  const [open, setOpen] = useState(false);
  const [containerNumber, setContainerNumber] = useState('');
  const [containerType, setContainerType] = useState('');

  const [, setUpdate] = useState<any>();

  const validators = {
    containerNumber: containerNumber
      ? !containerValidator(containerNumber)
        ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER
        : ''
      : true,
  };

  useEffect(() => {
    containerEditModal.open = () => {
      containerEditModal.action = 'Edit';
      containerEditModal.name = `${containerEditModal.action} ${containerEditModal.number}`;

      setContainerNumber(containerEditModal.number);
      setContainerType(containerEditModal.type);

      containerEditModal.isSubmitClicked = false;
      setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    Object.keys(containerEditModal).forEach((key) => {
      if (key !== 'open') delete containerEditModal[key];
    });
    setOpen(!open);
  };

  const handleSubmitClick = async () => {
    containerEditModal.isSubmitClicked = true;
    setUpdate(Math.random());

    if ([...Object.values(validators).map((val) => !val)].some((val) => !val)) {
      return;
    }

    containerEditModal.isSubmitClicked = false;

    const mutateData: any = {
      id: containerEditModal.id,
      number: containerNumber,
      type: containerType,
    };
    if (containerNumber === containerEditModal.number) delete mutateData.number;
    if (containerType === containerEditModal.type) delete mutateData.type;

    mutate(mutateData, {
      onSuccess: () => {
        setOpen(false);
        containerEditModal.submitClick();
      },
      onError: (err: any) => {
        enqueueSnackbar(err.response.data.message, 'error');
      },
    });
  };

  return (
    <ModalBase id={'container-edit-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          {containerEditModal.name}
        </Typography>
        {showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user) && (
          <InputContainer
            id={'container-edit'}
            label={`Container Number*`}
            placeholder={'Container Number'}
            data-size={'medium'}
            value={containerNumber}
            onChange={(e) => setContainerNumber(e.target.value)}
            error={
              (containerEditModal.isSubmitClicked && !containerNumber) ||
              (!!containerNumber && validators.containerNumber)
            }
            helperText={
              containerEditModal.isSubmitClicked && !containerNumber
                ? INPUT_ERRORS.REQUIRED.CONTAINER_NUMBER
                : validators.containerNumber
            }
          />
        )}
        <Select
          label={`Container Type*`}
          placeholder={'Select Container Type'}
          options={containerTypes}
          value={containerType}
          onChange={(e) => {
            setContainerType(e.target.value);
          }}
        />
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            size={'medium'}
            data-color={'primary'}
            onClick={handleSubmitClick}
            disabled={
              containerNumber === containerEditModal.number &&
              containerType === containerEditModal.type
            }
          >
            Edit
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
