import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import { Typography } from '../typography';
import { colors } from '../../const';

const theme = createTheme({
  palette: {
    primary: {
      main: colors['--primary-100'],
    },
    error: {
      main: colors['--red-100'],
    },
    action: {
      disabled: colors['--gray-50'],
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: '-0.9rem',
          '& .MuiSvgIcon-fontSizeMedium': {
            fontSize: '2rem',
          },
        },
      },
    },
  },
});

export const Checkbox: React.FC<CheckboxProps> = ({ ...props }: CheckboxProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiCheckbox {...props} />
    </ThemeProvider>
  );
};

export const CheckboxLabelWrapper = styled.label`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: var(--spacing-3);
  align-items: center;

  width: max-content;
  cursor: pointer;
`;

interface CheckboxLabelProps extends CheckboxProps {
  label: string;
}

export const CheckboxLabel: React.FC<CheckboxLabelProps> = ({
  label,
  ...props
}: CheckboxLabelProps) => {
  return (
    <CheckboxLabelWrapper>
      <ThemeProvider theme={theme}>
        <MuiCheckbox {...props} size={'medium'} />
      </ThemeProvider>
      <Typography variant={'textM'}>{label}</Typography>
    </CheckboxLabelWrapper>
  );
};
