export const uniqueArray = (arr: any[], getKeyFc: (obj: any) => any) => {
  const result = [];
  const hash: any = {};

  for (const obj of arr) {
    const key = getKeyFc(obj);

    if (!hash[key]) {
      hash[key] = true;
      result.push(obj);
    }
  }

  return result;
};
