import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import LOGO_SVG from '../../images/Logo.svg';

interface LogoProps {
  isOpen: boolean;
}

const LogoWrapper = styled.div<LogoProps>`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;

  width: calc(100% + 3.2rem);
  height: 6.8rem;
  margin-left: -1.6rem;
  padding: 0 1.6rem;
  border-bottom: 0.1rem solid var(--gray-80);
  transition: width 0.3s, margin 0.3s, padding 0.3s;

  & > *:nth-child(1) {
    transition: margin 0.3s;
  }
  & > *:nth-child(2) {
    transition: font-size 0.3s;
  }

  ${(props) =>
    !props.isOpen &&
    css`
      width: 100%;
      margin-left: 0;
      padding: 0;

      & > *:nth-child(1) {
        margin: 0 1.4rem;
      }
      & > *:nth-child(2) {
        font-size: 0;
      }
    `}
`;

const LogoBase = styled.div`
  width: 4rem;
  height: 3.4rem;
  margin-right: 0.8rem;
  margin-left: 1.6rem;
  background-image: url(${LOGO_SVG});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Logo: React.FC<LogoProps> = ({ isOpen }: LogoProps) => {
  return (
    <LogoWrapper isOpen={isOpen}>
      <LogoBase />
      <Typography variant={'h6'} weight={'bold'} color={'--white'}>
        TEZPORT
      </Typography>
    </LogoWrapper>
  );
};
