import { useEffect, useState } from 'react';
import validator from 'validator';
import { Typography } from '../typography';
import { Button } from '../button';
import { InputPassword } from '../input';
import { enqueueSnackbar } from '../snackbar';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { useUpdatePasswordMutation } from '../../hooks';
import { INPUT_ERRORS, REGEX } from '../../const';

export const updatePasswordModal: any = {};

export const UpdatePasswordModal = () => {
  const { mutate } = useUpdatePasswordMutation();
  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [, setUpdate] = useState<any>();

  const validators = {
    currentPassword: currentPassword
      ? !validator.isLength(currentPassword, { min: 8, max: 16 })
        ? INPUT_ERRORS.NOT_VALID.CURRENT_PASSWORD_LENGTH
        : !REGEX.PASSWORD.test(currentPassword)
        ? INPUT_ERRORS.NOT_VALID.CURRENT_PASSWORD
        : ''
      : true,
    newPassword: newPassword
      ? !validator.isLength(newPassword, { min: 8, max: 16 })
        ? INPUT_ERRORS.NOT_VALID.NEW_PASSWORD_LENGTH
        : !REGEX.PASSWORD.test(newPassword)
        ? INPUT_ERRORS.NOT_VALID.NEW_PASSWORD
        : validator.equals(currentPassword, newPassword)
        ? INPUT_ERRORS.NOT_VALID.NOT_NEW_PASSWORD
        : ''
      : true,
    newPasswordConfirm: newPasswordConfirm
      ? !validator.equals(newPasswordConfirm, newPassword)
        ? INPUT_ERRORS.NOT_VALID.PASSWORD_CONFIRM
        : ''
      : true,
  };

  useEffect(() => {
    updatePasswordModal.open = () => {
      updatePasswordModal.isSubmitClicked = false;
      setCurrentPassword('');
      setNewPassword('');
      setNewPasswordConfirm('');
      setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    Object.keys(updatePasswordModal).forEach((key) => {
      if (key !== 'open') delete updatePasswordModal[key];
    });
    setOpen(!open);
  };

  const handleUpdatePasswordClick = async () => {
    updatePasswordModal.isSubmitClicked = true;
    setUpdate(Math.random());

    if ([...Object.values(validators).map((val) => !val)].some((val) => !val)) {
      return;
    }

    mutate(
      { currentPassword, password: newPassword, passwordConfirmation: newPasswordConfirm },
      {
        onSuccess: (res) => {
          enqueueSnackbar(res.data.message, 'success');

          updatePasswordModal.isSubmitClicked = false;
          handleOnClose();
        },
        onError: (err: any) => {
          enqueueSnackbar(err.response.data.message, 'error');
        },
      },
    );
  };

  return (
    <ModalBase id={'confirm-universal-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          Do you want to Update Password?
        </Typography>
        <InputPassword
          defaultValue={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          label={'Current Password'}
          placeholder={'Enter Current Password'}
          data-size={'medium'}
          error={
            (updatePasswordModal.isSubmitClicked && !currentPassword) ||
            (!!currentPassword && validators.currentPassword)
          }
          helperText={
            updatePasswordModal.isSubmitClicked && !currentPassword
              ? INPUT_ERRORS.REQUIRED.CURRENT_PASSWORD
              : validators.currentPassword
          }
        />
        <InputPassword
          defaultValue={newPassword}
          onChange={(e: any) => setNewPassword(e.target.value)}
          label={'New Password'}
          placeholder={'Enter New Password'}
          data-size={'medium'}
          error={
            (updatePasswordModal.isSubmitClicked && !newPassword) ||
            (!!newPassword && validators.newPassword)
          }
          helperText={
            updatePasswordModal.isSubmitClicked && !newPassword
              ? INPUT_ERRORS.REQUIRED.NEW_PASSWORD
              : validators.newPassword
          }
        />
        <InputPassword
          defaultValue={newPasswordConfirm}
          onChange={(e: any) => setNewPasswordConfirm(e.target.value)}
          label={'New Password Confirmation'}
          placeholder={'Enter New Password Confirmation'}
          data-size={'large'}
          error={
            (updatePasswordModal.isSubmitClicked && !newPasswordConfirm) ||
            (!!newPasswordConfirm && validators.newPasswordConfirm)
          }
          helperText={
            updatePasswordModal.isSubmitClicked && !newPasswordConfirm
              ? INPUT_ERRORS.REQUIRED.NEW_PASSWORD_CONFIRM
              : validators.newPasswordConfirm
          }
        />
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size={'medium'} data-color={'primary'} onClick={handleUpdatePasswordClick}>
            Update Password
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
