import { Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../dashboard';
import { ContainersPage } from './ContainersComponents';
import { ContainersAddHeader, ContainersAddForm } from './ContainersAddComponents';
import { useStore } from '../../context';
import { showToRoles } from '../../utils';

export const ContainersAdd = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  if (showToRoles('client', user)) return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <ContainersPage>
      <ContainersAddHeader />
      <DashboardFormWrapper>
        <ContainersAddForm />
      </DashboardFormWrapper>
    </ContainersPage>
  );
};
