import { ScrollRestoration, Outlet } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AppProvider } from './context';
import { useSaveFiltersControlEffect } from './hooks';
import { queryClient } from './services';
import { BackdropLoading, Snackbar } from './design-system';

export const App = () => {
  useSaveFiltersControlEffect();

  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Outlet />
          <ScrollRestoration />
          <BackdropLoading />
          <Snackbar />
        </LocalizationProvider>
      </QueryClientProvider>
    </AppProvider>
  );
};
