import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { linkConverter } from '../../utils';

export const InputItemWrapper = styled.div`
  width: 100%;
  padding: var(--spacing-6) 0;
  border-top: 0.1rem solid var(--gray-40);

  & > * {
    width: 100%;
  }
`;

const InfoItemBase = styled.div<{ withoutBorder?: boolean }>`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: var(--spacing-3);
  align-items: center;
  padding: var(--spacing-6) 0;

  &:nth-last-child(1) {
    padding-bottom: var(--spacing-3);
  }

  & > *:nth-last-child(1) {
    word-break: break-word;
    white-space: break-spaces;
  }

  ${(props) =>
    !props.withoutBorder &&
    css`
      border-top: 0.1rem solid var(--gray-40);
    `}
`;

interface InfoItemProps {
  label: any;
  value: any;
  startAdornment?: any;
  withoutBorder?: boolean;
}

export const InfoItem: React.FC<InfoItemProps> = ({
  withoutBorder,
  label,
  value,
  startAdornment,
  ...props
}: InfoItemProps) => {
  if (typeof value === 'object' && !!value?.length) {
    value = value
      .filter((val: string) => !!val)
      .join(' ')
      .trim();
  }
  if (!value) {
    startAdornment = '';
    value = 'N / A';
  }

  return (
    <InfoItemBase withoutBorder={withoutBorder} {...props}>
      <Typography variant={'textM'} weight={'medium'} color={'--gray-90'}>
        {label}
      </Typography>
      <Typography variant={'textS'} color={'--gray-80'} align={'right'}>
        {startAdornment}
        {linkConverter(value)}
      </Typography>
    </InfoItemBase>
  );
};
