import { Navigate, useParams } from 'react-router-dom';
import { ContainersPage } from './ContainersComponents';
import {
  ContainersViewHeader,
  ContainerFormCards,
  ContainersViewTopCards,
  ContainersViewImages,
  ContainersViewDocuments,
} from './ContainersViewComponents';
import { useContainerQuery } from '../../hooks';

export const ContainersView = () => {
  const params: any = useParams();
  const { data: container, isFetching } = useContainerQuery(params.id);

  if (isFetching) return <></>;
  if (!container) return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <ContainersPage>
      <ContainersViewHeader container={container} />
      <ContainerFormCards>
        <ContainersViewTopCards container={container} />
        <ContainersViewImages container={container} />
        <ContainersViewDocuments container={container} />
      </ContainerFormCards>
    </ContainersPage>
  );
};
