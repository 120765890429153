import { useState, useEffect, useRef, useCallback } from 'react';
import {
  getCountries,
  getCountryCallingCode,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  Country,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { InputAdornment } from '@mui/material';
import { RectangleRounded as RectangleRoundedIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { SearchableSelect } from '../select';
import { Input, InputBaseProps } from './Input';
import { getPhoneNumberValue } from '../../utils';
import { IconButton } from '../button';

const allowedKeyPressKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 37, 39];
const countries = getCountries().map((country) => ({
  countryCode: country,
  phoneCode: getCountryCallingCode(country),
  flag: flags[country],
}));

const FlagIconButton = styled(IconButton)`
  margin-left: -0.8rem;
`;

interface FlagIconProps {
  countryCode?: Country;
}

export const FlagIcon: React.FC<FlagIconProps> = ({ countryCode }: FlagIconProps) => {
  let FlagComponent: any = RectangleRoundedIcon;
  const props: any = {};
  if (countryCode) {
    FlagComponent = flags[countryCode];
    props.title = countryCode;
  }

  return <FlagComponent style={{ width: '2.4rem', height: '2.4rem' }} {...props} />;
};

const FlagOptionWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 1rem;
  align-items: center;
`;

interface InputPhoneNumberProps extends InputBaseProps {
  defaultCountry?: Country;
}

export const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({
  onChange,
  value,
  defaultValue = '+86',
  defaultCountry = 'CN',
  ...props
}: InputPhoneNumberProps) => {
  const inputRef = useRef<any>();
  const [countryCode, setCountryCode] = useState<Country | undefined>(defaultCountry);
  const selectedCountry = useRef<any>({});

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) return;

    const isSelected = !!Object.keys(selectedCountry.current).length;
    if (!isSelected) return;

    setCountryCode(selectedCountry.current.countryCode);
    selectedCountry.current = {};
    inputRef.current.focus();
  }, [open]);

  const handleOnKeyPress = useCallback(
    (e: any) => {
      if (open) return;

      const keyCode = e.keyCode || e.which;
      if (!allowedKeyPressKeyCodes.includes(keyCode)) e.preventDefault();
    },
    [open],
  );

  const handleOnChange = (e: any) => {
    const phoneNumber = getPhoneNumberValue(e.target.value);

    if (phoneNumber.length) e.target.value = `+${getPhoneNumberValue(e.target.value)}`;
    else e.target.value = '';

    const parsed = parsePhoneNumberFromString(e.target.value);
    if (parsed?.country) setCountryCode(parsed?.country);

    if (isValidPhoneNumber(e.target.value)) {
      const phoneNumber = formatPhoneNumberIntl(e.target.value);
      const phoneNumberLength = phoneNumber.split(' ').join('').length;

      if (phoneNumberLength === e.target.value.length) e.target.value = phoneNumber;
    }

    onChange && onChange(e);
  };

  const handleFlagToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleFlagSelect = (obj: any) => {
    const newValue: any = { target: { value: `+${obj.phoneCode}` } };

    selectedCountry.current = obj;
    onChange && onChange(newValue);
  };

  return (
    <Input
      ref={inputRef}
      value={typeof value === 'string' ? value : defaultValue}
      onChange={handleOnChange}
      onKeyPress={handleOnKeyPress}
      clickableIcon
      startAdornment={
        <InputAdornment position={'start'}>
          <SearchableSelect
            open={open}
            onToggle={handleFlagToggle}
            onSelect={handleFlagSelect}
            selectedAreasEqaul={{ key: 'countryCode', keys: [countryCode || ''] }}
            options={countries.map((obj, i) => ({
              ...obj,
              id: 'flag:' + i,
              label: `${obj.countryCode} +${obj.phoneCode}`,
              Component: (
                <FlagOptionWrapper>
                  <FlagIcon countryCode={obj.countryCode} /> {obj.countryCode} +{obj.phoneCode}
                </FlagOptionWrapper>
              ),
            }))}
            HeaderComponent={
              <FlagIconButton onClick={handleFlagToggle}>
                <FlagIcon countryCode={countryCode} />
              </FlagIconButton>
            }
            MenuProps={
              inputRef.current
                ? {
                    anchorEl: inputRef.current,
                    PaperProps: {
                      style: {
                        width: inputRef.current.clientWidth,
                      },
                    },
                  }
                : {}
            }
          />
        </InputAdornment>
      }
      {...props}
    />
  );
};
