import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { Typography, Input, Button, enqueueSnackbar } from '../../../design-system';
import { AuthHeaderBase, AuthFormBase, AuthFooterBase, AuthFooterLink } from '../AuthComponents';
import { useCallbackOnKeyDown, useSigninMutation } from '../../../hooks';
import { REGEX, INPUT_ERRORS } from '../../../const';

export const SignInHeader = () => {
  return (
    <AuthHeaderBase>
      <Typography variant={'h4'} align={'center'} weight={'bold'}>
        Sign In
      </Typography>
    </AuthHeaderBase>
  );
};

export const SignInForm = () => {
  const navigate = useNavigate();
  const { mutate } = useSigninMutation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const validators = {
    email: !email
      ? INPUT_ERRORS.REQUIRED.EMAIL
      : !REGEX.EMAIL.test(email)
      ? INPUT_ERRORS.NOT_VALID.EMAIL
      : !validator.isLength(email, { min: 6, max: 50 })
      ? INPUT_ERRORS.NOT_VALID.EMAIL_LENGTH
      : '',
    password: !password ? INPUT_ERRORS.REQUIRED.PASSWORD : '',
  };

  const handleSubmitClick = () => {
    if (!Object.values(validators).every((isError) => !isError)) {
      setIsSubmitClicked(true);
      return;
    }

    mutate(
      { email, password },
      {
        onSuccess: () => navigate('/dashboard/containers'),
        onError: (err: any) => enqueueSnackbar(err.response.data.message, 'error'),
        onSettled: () => setIsSubmitClicked(false),
      },
    );
  };
  useCallbackOnKeyDown(handleSubmitClick, 'Enter');

  return (
    <AuthFormBase>
      <Input
        defaultValue={email}
        onChange={(e) => setEmail(e.target.value)}
        label={'Email'}
        placeholder={'Your Email'}
        type={'email'}
        data-size={'large'}
        withoutSpace
        error={isSubmitClicked && validators.email}
        helperText={isSubmitClicked ? validators.email : ''}
      />
      <Input
        defaultValue={password}
        onChange={(e) => setPassword(e.target.value)}
        label={'Password'}
        placeholder={'Your Password'}
        type={'password'}
        data-size={'large'}
        withoutSpace
        error={isSubmitClicked && validators.password}
        helperText={isSubmitClicked && validators.password ? INPUT_ERRORS.REQUIRED.PASSWORD : ''}
      />
      <Button data-color={'primary'} size={'large'} onClick={handleSubmitClick}>
        Sign In
      </Button>
    </AuthFormBase>
  );
};

export const SignInFooter = () => {
  return (
    <AuthFooterBase>
      <Typography variant={'textS'} color={'--primary-100'}>
        <AuthFooterLink to={'/forgotpassword'} withunderline>
          Forgot your password?
        </AuthFooterLink>
      </Typography>
    </AuthFooterBase>
  );
};
