import { TableCell, TableCellProps } from '@mui/material';
import styled from 'styled-components';

const WrapperCenter = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  grid-column-gap: 1.2rem;
  height: max-content;
`;

export const TableCellWrapperCenter: React.FC<TableCellProps> = ({
  children,
  ...props
}: TableCellProps) => {
  return (
    <TableCell {...props}>
      <WrapperCenter>{children}</WrapperCenter>
    </TableCell>
  );
};
