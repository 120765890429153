import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Typography } from '../typography';
import { Button } from '../button';
import { enqueueSnackbar } from '../snackbar';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { useSendContractMutation } from '../../hooks';

export const sendContractModal: any = {};

export const SendContractModal = () => {
  const { mutate, isLoading } = useSendContractMutation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    sendContractModal.open = () => {
      setOpen(true);

      const mutateData: any = {
        yardId: sendContractModal.yardId,
      };

      mutate(mutateData, {
        onError: (err: any) => {
          enqueueSnackbar(err.response.data.message, 'error');
        },
      });
    };
  }, []);

  const handleOnClose = () => {
    Object.keys(sendContractModal).forEach((key) => {
      if (key !== 'open') delete sendContractModal[key];
    });
    setOpen(!open);
  };

  return (
    <ModalBase id={'send-contract-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textM'}>
          You do not have a contract with this Yard yet. Our team will contact you within 24 hours.
        </Typography>
        <ModalFooterButtons>
          <Button
            size={'medium'}
            data-color={'primary'}
            onClick={handleOnClose}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color={'inherit'} /> : 'Ok'}
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
