import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  YardCompaniesPage,
  YardCompaniesHeader,
  YardCompaniesBody,
} from './YardCompaniesComponents';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';

export const YardCompanies = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [totalCount, setTotalCount] = useState(0);

  if (!showToRoles(['SUPER_ADMIN'], user)) return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <YardCompaniesPage innerScroll>
      <YardCompaniesHeader totalCount={totalCount} />
      <YardCompaniesBody setTotalCount={setTotalCount} />
    </YardCompaniesPage>
  );
};
