import { UpdatePasswordModal } from '../../../design-system';
import { DashboardFormWrapper } from '../../dashboard';
import { AccountPage, AccountHeader, AccountForm } from './AccountComponents';

export const Account = () => {
  return (
    <>
      <AccountPage>
        <AccountHeader />
        <DashboardFormWrapper flow={'row'}>
          <AccountForm company={null} />
        </DashboardFormWrapper>
      </AccountPage>
      <UpdatePasswordModal />
    </>
  );
};
