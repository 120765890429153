import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AutoSignin } from './AutoSignin';
import { PrivateRoute } from './PrivateRoute';

import { App } from '../App';
import { ErrorBoundary } from '../design-system';
import {
  ErrorPage,
  ConstructionPage,
  SignIn,
  ForgotPassword,
  CreateNewPassword,
  Dashboard,
  GateIns,
  GateInsView,
  GateInsAdd,
  GateOuts,
  GateOutsView,
  GateOutsAdd,
  Containers,
  ContainersAdd,
  ContainersView,
  YardCompanies,
  YardCompaniesAdd,
  YardCompaniesView,
  Yards,
  YardsAdd,
  Clients,
  ClientsAdd,
  ClientsView,
  Employees,
  EmployeesAdd,
  Account,
} from '../pages';
import { MESSAGES } from '../const';

const originalRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        }
        errorElement={
          <ErrorPage
            heading={MESSAGES.ERROR_BOUNDARY.HEADING}
            detail={MESSAGES.ERROR_BOUNDARY.DETAIL}
            navigateTo={-1}
          />
        }
      >
        <Route element={<AutoSignin />}>
          <Route index path={'signin'} element={<SignIn />} />
          <Route path={'forgotpassword'} element={<ForgotPassword />} />
          <Route path={'createnewpassword'} element={<CreateNewPassword />} />
          <Route path={'dashboard'} element={<PrivateRoute Component={Dashboard} />}>
            <Route path={'gateins'} element={<GateIns />} />
            <Route path={'gateins/:id'} element={<GateInsView />} />
            <Route path={'gateins/add'} element={<GateInsAdd />} />
            <Route path={'gateouts'} element={<GateOuts />} />
            <Route path={'gateouts/:id'} element={<GateOutsView />} />
            <Route path={'gateouts/add'} element={<GateOutsAdd />} />
            <Route path={'containers'} element={<Containers />} />
            <Route path={'containers/add'} element={<ContainersAdd />} />
            <Route path={'containers/:id'} element={<ContainersView />} />
            <Route path={'yardcompanies'} element={<YardCompanies />} />
            <Route path={'yardcompanies/add'} element={<YardCompaniesAdd />} />
            <Route path={'yardcompanies/:id'} element={<YardCompaniesView />} />
            <Route path={'yards'} element={<Yards />} />
            <Route path={'yards/add'} element={<YardsAdd />} />
            <Route path={'clients'} element={<Clients />} />
            <Route path={'clients/add'} element={<ClientsAdd />} />
            <Route path={'clients/:id'} element={<ClientsView />} />
            <Route path={'employees'} element={<Employees />} />
            <Route path={'employees/add'} element={<EmployeesAdd />} />
            <Route path={'account'} element={<Account />} />
            <Route index path={'*'} element={<Navigate to={'containers'} replace />} />
          </Route>
          <Route path={'*'} element={<Navigate to={'/signin'} replace />} />
        </Route>
      </Route>
      <Route path={'*'} element={<Navigate to={'/signin'} replace />} />
    </>,
  ),
);

const constructionRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<ConstructionPage />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </>,
  ),
);

export const router =
  process.env.REACT_APP_IS_CONSTRUCTION === 'true' ? constructionRouter : originalRouter;
