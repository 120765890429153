import { User } from '../types';
import { Roles } from '../const';

export const showToRoles = (roles: Array<Roles> | 'admin' | 'client', user: User) => {
  const myRole = user.type;

  if (!roles) return null;

  if (roles === 'admin')
    return (
      [
        'SUPER_ADMIN',
        'YARD_COMPANY_ADMIN',
        'YARD_COMPANY_EMPLOYEE',
        'YARD_MANAGER',
        'YARD_DISPATCHER',
      ].some((role) => myRole === role) || null
    );

  if (roles === 'client')
    return ['CLIENT_MANAGER', 'CLIENT_DISPATCHER'].some((role) => myRole === role) || null;

  return roles.some((role) => myRole === role) || null;
};
