import { WorkOutlineOutlined as WorkOutlineOutlinedIcon } from '@mui/icons-material';
import { Badge } from '../badge';
import { Avatar, AvatarProps } from './Avatar';

const ContainerAvatarBase: React.FC<AvatarProps> = ({ ...props }: AvatarProps) => {
  return (
    <Avatar data-size={props['data-size'] || 'small'} button={true} {...props}>
      <WorkOutlineOutlinedIcon />
    </Avatar>
  );
};

interface ContainerAvatarProps {
  images: { url: string }[];
}

export const ContainerAvatar: React.FC<ContainerAvatarProps> = ({
  images,
}: ContainerAvatarProps) => {
  if (images.length > 1)
    return (
      <Badge badgeContent={images.length - 1} data-size={'small'} overlap={'circular'}>
        <ContainerAvatarBase src={images[0].url} />
      </Badge>
    );

  if (images.length === 1) return <ContainerAvatarBase src={images[0].url} />;

  return <ContainerAvatarBase />;
};
