import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableHeadCell,
  TableWrapperFooter,
  Pagination,
  Button,
} from '../../../design-system';
import { DashboardHeader } from '../../dashboard';
import { usePaginationState, useYardCompaniesQuery } from '../../../hooks';
import { formatPhoneNumber } from '../../../utils';
import { TABLE } from '../../../const';

interface YardCompaniesProps {
  innerScroll?: boolean;
}

export const YardCompaniesPage = styled.div<YardCompaniesProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const YardCompaniesHeader = ({ totalCount }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Yard Companies: {totalCount}
      </Typography>
      <Button size={'medium'} to={'/dashboard/yardcompanies/add'}>
        Add Yard Company
      </Button>
    </DashboardHeader>
  );
};

export const YardCompaniesBody = ({ setTotalCount }: any) => {
  const navigate = useNavigate();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const { data, isSuccess, isFetching } = useYardCompaniesQuery({
    take,
    skip,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleRowClick = (to: string) => {
    navigate(to);
  };

  return (
    <TableWrapper>
      <span />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Name</TableHeadCell>
            <TableHeadCell align={'left'}>Email</TableHeadCell>
            <TableHeadCell align={'left'}>Phone Number</TableHeadCell>
            <TableHeadCell align={'left'}>Country</TableHeadCell>
            <TableHeadCell align={'left'}>City</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={
          isSuccess &&
          data.data.map((row: any, i: any) => (
            <TableRow
              key={'yard-companies' + i}
              onClick={() => handleRowClick(`/dashboard/yardcompanies/${row.id}`)}
            >
              <TableCell component={'th'} align={'left'}>
                {row.legalName}
              </TableCell>
              <TableCell align={'left'}>{row.email}</TableCell>
              <TableCell align={'left'}>{formatPhoneNumber(row.phoneNumber)}</TableCell>
              <TableCell align={'left'}>{row.address?.country}</TableCell>
              <TableCell align={'left'}>{row.address?.city}</TableCell>
            </TableRow>
          ))
        }
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
