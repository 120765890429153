import { TableFilters, TableFilterSelect, SearchableSelect } from '../../../design-system';
import { useStore } from '../../../context';
import { belongsToOptions, belongsToOptionsQueryKeys } from '../../../services';
import { showToRoles } from '../../../utils';
import {
  employeesAccountStatus,
  employeesFormCompanyRolesOptions,
  employeesTableCompanyTitle,
} from '../../../const';

interface Props {
  belongsTo: 'YardCompany' | 'Yard' | 'Client';
  companyName?: string;
  companyId?: string;
  firstName?: string;
  lastName?: string;
  accountStatus?: string;
  setStates: any;
  handlePageChange: (e: any, page: number) => void;
}

export const Filters: React.FC<Props> = ({
  belongsTo,
  companyName,
  companyId = '',
  accountStatus,
  setStates,
  handlePageChange,
}: Props) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <TableFilters>
      <span className={'remove-dots-column'} />
      {showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user) && (
        <TableFilterSelect
          label={'Belongs To'}
          options={employeesFormCompanyRolesOptions[user.type]}
          value={belongsTo}
          onChange={(e) => {
            setStates('force', {
              belongsTo: e.target.value,
              companyName: '',
              companyId: '',
            });
            handlePageChange(null, 1);
          }}
          withoutEmptyValue
        />
      )}
      {(showToRoles(['SUPER_ADMIN'], user) ||
        (belongsTo !== 'YardCompany' &&
          showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user))) && (
        <SearchableSelect
          selectedOptionLabel={companyName}
          label={employeesTableCompanyTitle[belongsTo]}
          placeholder={`Select ${employeesTableCompanyTitle[belongsTo]}`}
          onSelect={(obj) => setStates('force', { companyName: obj.label, companyId: obj.id })}
          queryFn={() =>
            belongsTo === 'Client'
              ? belongsToOptions[belongsTo]('', true, true)
              : belongsTo === 'Yard'
              ? () => belongsToOptions[belongsTo]('', true, 'yard')
              : belongsToOptions[belongsTo]('', true)
          }
          queryKey={[belongsToOptionsQueryKeys[belongsTo], belongsTo]}
          selectedAreasEqaul={{ key: 'id', keys: [companyId] }}
          withClearButton
        />
      )}
      <TableFilterSelect
        label={'Account Status'}
        options={employeesAccountStatus}
        value={accountStatus}
        onChange={(e) => setStates('force', { accountStatus: e.target.value })}
        withoutEmptyValue
      />
    </TableFilters>
  );
};
