import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton as MuiIconButton,
  IconButtonProps,
} from '@mui/material';
import {
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
  KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import { Link } from '../link';
import { IconButton } from '../button';
import { colors } from '../../const';

const drawerButtonTheme = (isActive?: boolean) =>
  createTheme({
    palette: {
      primary: isActive
        ? {
            main: colors['--gray-30'],
            contrastText: colors['--gray-90'],
          }
        : {
            main: colors['--transparent'],
            contrastText: colors['--gray-30'],
          },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            display: 'grid',
            gridTemplateColumns: 'max-content max-content 1fr',
            textTransform: 'initial',
            fontFamily: 'Inter',
            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '& > span': {
              transition: 'margin 0.3s',
            },
            '&:not(.dropdown)[data-is-open=false]': {
              fontSize: 0,
              borderRadius: 0,
              '& > span': {
                margin: 0,
              },
            },
            '&.dropdown': {
              '& > svg': {
                width: '2rem',
                height: '2rem',
                marginRight: '-0.8rem',
                justifySelf: 'right',
                transition: 'transform 0.3s',
              },
              '&[data-is-open=true] > svg': {
                transform: 'rotateZ(180deg)',
              },
            },
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          sizeLarge: {
            padding: '1rem 1.6rem',
            minWidth: '4.8rem',
            fontWeight: 500,
            fontSize: '1.4rem',
            lineHeight: 1.43,
            '&:not(.dropdown)[data-is-open=false]': {
              padding: '1rem 2.2rem',
            },
          },
          iconSizeLarge: {
            marginRight: '1.6rem',
            marginLeft: 0,

            '& > *:nth-of-type(1)': {
              fontSize: '2.4rem',
            },
          },
        },
      },
    },
  });

interface ButtonProps extends MuiButtonProps {
  isOpen: boolean;
  to?: string;
  isActive?: boolean;
  children: string | React.ReactNode;
}

export const DrawerButton: React.FC<ButtonProps> = ({
  isOpen,
  to,
  isActive,
  children,
  ...props
}: ButtonProps) => {
  return (
    <Link to={to}>
      <ThemeProvider theme={drawerButtonTheme(isActive)}>
        <MuiButton variant={'contained'} size={'large'} data-is-open={isOpen} {...props}>
          {children}
        </MuiButton>
      </ThemeProvider>
    </Link>
  );
};

const drawerIconButtontheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          color: 'var(--gray-30)',
          '&[data-is-active=true]': {
            color: 'var(--gray-100)',
          },
          '&[data-is-open=false]': {
            display: 'none',
          },
          '& > svg': {
            fontSize: '2rem',
          },
        },
      },
    },
  },
});

interface DrawerIconButtonProps extends IconButtonProps {
  isOpen: boolean;
  to?: string;
  isActive?: boolean;
  children: string | React.ReactNode;
}

export const DrawerIconButton: React.FC<DrawerIconButtonProps> = ({
  isOpen,
  to,
  isActive,
  children,
  ...props
}: DrawerIconButtonProps) => {
  return (
    <Link to={to}>
      <ThemeProvider theme={drawerIconButtontheme}>
        <MuiIconButton data-is-active={isActive} data-is-open={isOpen} {...props}>
          {children}
        </MuiIconButton>
      </ThemeProvider>
    </Link>
  );
};

const DrawerLinkOptionBase = styled.div<{ isActive?: boolean }>`
  padding: 0.8rem 1.6rem;
  margin-left: 4rem;
  line-height: 1.43;
  color: var(--gray-70);
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.4rem;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: var(--white);
  }

  ${(props) =>
    props.isActive &&
    css`
      color: var(--white);
    `}
`;

interface DrawerLinkOptionProps {
  to?: string;
  isActive?: boolean;
  children?: string;
}

export const DrawerLinkOption: React.FC<DrawerLinkOptionProps> = ({
  to,
  isActive,
  children,
}: DrawerLinkOptionProps) => {
  return (
    <Link to={to}>
      <DrawerLinkOptionBase isActive={isActive}>{children}</DrawerLinkOptionBase>
    </Link>
  );
};

const drawerControlButtonTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 'max-content',
          padding: 0,
          color: 'var(--gray-30)',
          backgroundColor: 'var(--gray-90)',
          transition: 'color 0.3s, background-color 0.3s',
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translate(50%, -50%)',

          '&:hover': {
            color: 'var(--gray-30)',
            backgroundColor: 'var(--gray-70)',
          },
          '& .MuiSvgIcon-root': {
            width: '2.4rem',
            height: '2.4rem',
            transform: 'rotateZ(-180deg)',
            transition: 'transform 0.3s',
          },
          '&[data-is-open=false] .MuiSvgIcon-root': {
            transform: 'rotateZ(0deg)',
          },
        },
      },
    },
  },
});

interface DrawerControlButtonProps {
  isOpen: boolean;
  handleChangeDrawer: () => void;
}

export const DrawerControlButton: React.FC<DrawerControlButtonProps> = ({
  isOpen,
  handleChangeDrawer,
}: DrawerControlButtonProps) => {
  return (
    <ThemeProvider theme={drawerControlButtonTheme}>
      <IconButton
        iconColor={'--gray-90'}
        onClick={handleChangeDrawer}
        hasOuterTheme={true}
        data-is-open={isOpen}
      >
        <KeyboardArrowRightRoundedIcon />
      </IconButton>
    </ThemeProvider>
  );
};

interface DrawerButtonWrapperProps {
  isActive?: boolean;
  optionsLength?: number;
}

export const DrawerButtonWrapper = styled.div<DrawerButtonWrapperProps>`
  display: grid;
  grid-auto-rows: max-content;
  height: 4.4rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: height 0.3s;

  ${(props) =>
    props.isActive &&
    props.optionsLength &&
    css`
      height: ${4.4 + props.optionsLength * 3}rem;
    `};
`;

interface DrawerDropdownWrapperProps extends DrawerButtonWrapperProps {
  isOpen: boolean;
}

const DrawerDropdownWrapper = styled(DrawerButtonWrapper)<DrawerDropdownWrapperProps>`
  ${(props) =>
    !props.isOpen &&
    css`
      height: 0;
    `};
`;

const DrawerDropdownOptions = styled.div<{ isActive?: boolean }>`
  display: grid;
  grid-auto-rows: 3rem;
  grid-auto-flow: max-content;
`;

interface DrawerDropdownButtonProps extends ButtonProps {
  isOpen: boolean;
  optionsLength?: number;
}

export const DrawerDropdownButton: React.FC<DrawerDropdownButtonProps> = ({
  isOpen,
  isActive,
  title,
  optionsLength = 0,
  children,
  ...props
}: DrawerDropdownButtonProps) => {
  return (
    <DrawerDropdownWrapper isOpen={isOpen} isActive={isActive} optionsLength={optionsLength}>
      <ThemeProvider theme={drawerButtonTheme(isActive)}>
        <MuiButton
          className={'dropdown'}
          variant={'contained'}
          size={'large'}
          data-is-open={isActive}
          {...props}
        >
          {title}
          <KeyboardArrowDownRoundedIcon />
        </MuiButton>
      </ThemeProvider>
      <DrawerDropdownOptions isActive={isActive}>{children}</DrawerDropdownOptions>
    </DrawerDropdownWrapper>
  );
};
