import { useState } from 'react';
import { useSaveFilters } from './use-save-filters';
import { TABLE } from '../const';

interface Return {
  page: number;
  take: number;
  skip: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const usePaginationState = (): Return => {
  const [oldPage, setOldPage] = useSaveFilters('pagination', true, 1);
  const [page, setPage] = useState(oldPage);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setOldPage(value);
    setPage(value);
  };

  return {
    page,
    take: TABLE.PAGE_MAX_SIZE,
    skip: (page - 1) * TABLE.PAGE_MAX_SIZE,
    handlePageChange,
  };
};
