import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import validator from 'validator';
import {
  Typography,
  Button,
  Input,
  InputContainer,
  BorderLine,
  DatePicker,
  SearchableSelect,
  BelongsToSelects,
  getMultipleValue,
} from '../../design-system';
import {
  DashboardHeader,
  DashboardForm,
  DashboardFormTwoColumn,
  DashboardFormCustomColumn,
} from '../dashboard';
import { useStore } from '../../context';
import { useAddGateOutMutation } from '../../hooks';
import { showToRoles, getNormalFormProps } from '../../utils';
import { containersService } from '../../services';
import { INPUT_ERRORS, REGEX } from '../../const';

export const initialGateOutsAddProps: any = {};
let formProps: any = {};

const getSubmitProps = () => {
  const props: any = {
    yardName: formProps.yardName,
    clientName: formProps.clientName,
    containerNumber: !!formProps.containerNumber,
    licensePlateNumber: !!formProps.licensePlateNumber.length,
  };

  const validate: any = {};

  ['containerNumber', 'licensePlateNumber'].forEach((key) => {
    formProps[key].forEach((val: any, i: any) => {
      if (key === 'licensePlateNumber') {
        validate['licensePlateNumber-' + (i + 1)] = REGEX.LICENSE_PLATE_NUMBER.test(val) && !!val;
        validate['licensePlateNumberLength-' + (i + 1)] =
          validator.isLength(val, {
            min: 4,
            max: 10,
          }) && !!val;
        return;
      }

      props[key + '-' + (i + 1)] = val;
    });
  });
  const errors: any = {
    validFrom: moment(formProps.validFrom).set('hour', 23).isAfter(moment().set('hour', 0)),
    validTo: moment(formProps.validTo)
      .set('hour', 23)
      .isAfter(moment(formProps.validFrom).set('hour', 0)),
    hasValidFrom: !!formProps.validFrom,
    hasValidTo: !!formProps.validTo,
  };
  const { validators }: any = formProps;

  if (
    [
      ...Object.values(props),
      ...Object.values(validate),
      ...Object.values(errors),
      ...Object.values(validators).map((err) => !err),
    ].some((val) => !val)
  ) {
    return;
  }

  delete formProps.isSubmitClicked;

  return getNormalFormProps(formProps);
};

export const GateOutsAddHeader = () => {
  const navigate = useNavigate();
  const { mutate } = useAddGateOutMutation();

  const handleSubmitClick = async () => {
    formProps.onSubmitClick();
    const submitProps = getSubmitProps();
    if (!submitProps) return;

    mutate(submitProps, {
      onSuccess: () => {
        if (initialGateOutsAddProps.isContainerGateOut) {
          delete initialGateOutsAddProps.isContainerGateOut;
          navigate(-1);
          return;
        }

        navigate('/dashboard/gateouts');
      },
    });
  };

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Gate Out
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
      <Button size={'medium'} onClick={handleSubmitClick}>
        Submit
      </Button>
    </DashboardHeader>
  );
};

export const GateOutsAddForm = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [yardName, setYardName] = useState(initialGateOutsAddProps.yardName || '');
  const yardId = useRef(initialGateOutsAddProps.yardId || '');
  const [clientName, setClientName] = useState(initialGateOutsAddProps.clientName || '');
  const clientId = useRef(initialGateOutsAddProps.clientId || '');
  const [containerNumber, setContainerNumber] = useState<any[]>(
    initialGateOutsAddProps.containerNumber || [],
  );
  const [containerId, setContainerId] = useState<any[]>(initialGateOutsAddProps.containerId || []);
  const [validFrom, setValidFrom] = useState();
  const [validTo, setValidTo] = useState();
  const [transport] = useState('ground');
  const [licensePlateNumber, setLicensePlateNumber] = useState<string[]>([]);
  const [releaseNumber, setReleaseNumber] = useState('');
  const [comment, setComment] = useState('');

  const [updateKey, setUpdate] = useState<any>(Math.random());

  const validators: any = {
    releaseNumber: releaseNumber
      ? !validator.isLength(releaseNumber, { min: 1, max: 10 })
        ? INPUT_ERRORS.NOT_VALID.RELEASE_NUMBER_LENGTH
        : !REGEX.RELEASE_NUMBER.test(releaseNumber)
        ? INPUT_ERRORS.NOT_VALID.RELEASE_NUMBER
        : ''
      : '',
    comment: comment
      ? !validator.isLength(comment, { min: 10, max: 200 })
        ? INPUT_ERRORS.NOT_VALID.COMMENT_LENGTH
        : ''
      : '',
  };

  useEffect(() => {
    if (!formProps) return;

    formProps.isSubmitClicked = false;
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    if (!yardName || !clientName) return;

    if (Object.keys(initialGateOutsAddProps).length > 1) {
      Object.keys(initialGateOutsAddProps).forEach((key) => {
        delete initialGateOutsAddProps[key];
      });
      initialGateOutsAddProps.isContainerGateOut = true;
      initialGateOutsAddProps.isDefaultValue = true;
    } else if (initialGateOutsAddProps.isDefaultValue) {
      delete initialGateOutsAddProps.isDefaultValue;
    } else {
      setContainerNumber([]);
      setContainerId([]);
    }
    setLicensePlateNumber([]);
    setUpdate(Math.random());
  }, [yardName, clientName]);

  useEffect(() => {
    formProps = {
      ...formProps,
      yardName,
      yardId: yardId.current,
      clientName,
      clientId: clientId.current,
      containerNumber,
      containerId,
      validFrom,
      validTo,
      transport,
      licensePlateNumber,
      releaseNumber,
      comment,
      validators,
      onSubmitClick: () => {
        formProps.isSubmitClicked = true;
        setUpdate(Math.random());
      },
    };
  }, [
    yardName,
    yardId.current,
    clientName,
    clientId.current,
    containerNumber.join(''),
    containerNumber.length,
    containerId.join(''),
    containerId.length,
    validFrom,
    validTo,
    transport,
    licensePlateNumber.join(''),
    licensePlateNumber.length,
    releaseNumber,
    comment,
  ]);

  const handleContainerSelect = (option: any) => {
    if (containerId.includes(option.id)) {
      const i = containerId.indexOf(option.id);
      setContainerNumber(containerNumber.filter((_, n) => n !== i));
      setContainerId(containerId.filter((_, n) => n !== i));
    } else {
      setContainerNumber([option.value, ...containerNumber]);
      setContainerId([option.id, ...containerId]);
    }

    return;
  };

  return (
    <DashboardForm paperSize={'full-size'}>
      <BelongsToSelects
        country={initialGateOutsAddProps.country}
        city={initialGateOutsAddProps.city}
        yardId={yardId.current}
        yardName={yardName}
        clientId={clientId.current}
        clientName={clientName}
        handleYardChange={(obj) => {
          yardId.current = obj.id;
          setYardName(obj.label);
        }}
        handleClientChange={(obj) => {
          clientId.current = obj.id;
          setClientName(obj.label);
        }}
        isSubmitClicked={formProps.isSubmitClicked}
      />
      <DashboardFormCustomColumn columns={'1fr 1fr'} align={'start'}>
        <SearchableSelect
          InputComponent={InputContainer}
          label={`Container Number*`}
          selectedOptionLabel={containerNumber.join(', ') || 'Select Container Number'}
          onSelect={handleContainerSelect}
          queryKey={[
            'container-short',
            'Container Number' + `${showToRoles('client', user) ? yardName : clientName}`,
          ]}
          queryFn={() =>
            containersService.options(
              {
                yardId: yardId.current,
                clientId: clientId.current,
                status: 'GATED_IN',
                short: true,
              },
              user,
            )
          }
          selectedAreasEqaul={{ key: 'id', keys: containerId }}
          multiple
          error={formProps.isSubmitClicked && !containerNumber.join(', ')}
          helperText={
            formProps.isSubmitClicked &&
            !containerNumber.join(', ') &&
            INPUT_ERRORS.REQUIRED.CONTAINER_NUMBER
          }
        />
        <Input
          key={updateKey}
          label={`License Plate Number*`}
          placeholder={'10Z234EB,20Z234EC,30Z334EC'}
          data-size={'medium'}
          defaultValue={licensePlateNumber.join(',')}
          onChange={(e) => {
            const value = getMultipleValue(e);
            setLicensePlateNumber(value);
          }}
          error={
            (formProps.isSubmitClicked && !licensePlateNumber.length) ||
            (licensePlateNumber.length &&
              (licensePlateNumber.some(
                (val: any) => !validator.isLength(val, { min: 4, max: 10 }),
              ) ||
                licensePlateNumber.some((val: any) => !REGEX.LICENSE_PLATE_NUMBER.test(val))))
          }
          helperText={
            formProps.isSubmitClicked && !licensePlateNumber.length
              ? INPUT_ERRORS.REQUIRED.LICENSE_PLATE_NUMBER
              : licensePlateNumber.length &&
                licensePlateNumber.some((val: any) => !validator.isLength(val, { min: 4, max: 10 }))
              ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER_LENGTH
              : licensePlateNumber.some((val: any) => !REGEX.LICENSE_PLATE_NUMBER.test(val))
              ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER
              : ''
          }
        />
      </DashboardFormCustomColumn>
      <BorderLine space={'dashboard-form-dates'} />
      <Typography variant={'textS'} color={'--gray-80'}>
        Arrival Date
      </Typography>
      <DashboardFormTwoColumn>
        <DatePicker
          label={'From*'}
          data-size={'medium'}
          defaultValue={validFrom}
          onChange={setValidFrom}
          minDate={moment()}
          maxDate={validTo ? moment(validTo) : null}
          error={formProps.isSubmitClicked && !validFrom}
          helperText={formProps.isSubmitClicked && !validFrom && INPUT_ERRORS.REQUIRED.DATE}
        />
        <DatePicker
          label={'To*'}
          data-size={'medium'}
          defaultValue={validTo}
          onChange={setValidTo}
          minDate={validFrom ? moment(validFrom) : moment()}
          disabled={!validFrom}
          error={formProps.isSubmitClicked && !validTo}
          helperText={formProps.isSubmitClicked && !validTo && INPUT_ERRORS.REQUIRED.DATE}
        />
      </DashboardFormTwoColumn>
      <BorderLine space={'dashboard-form'} />
      <Input
        label={'Release Number'}
        placeholder={'Release Number'}
        data-size={'medium'}
        defaultValue={releaseNumber}
        onChange={(e) => setReleaseNumber(e.target.value)}
        error={!!validators.releaseNumber}
        helperText={validators.releaseNumber}
      />
      <Input
        data-size={'medium'}
        label={'Comment'}
        rows={4}
        multiline
        defaultValue={comment}
        onChange={(e) => setComment(e.target.value)}
        error={!!validators.comment}
        helperText={validators.comment}
      />
    </DashboardForm>
  );
};
