export const downloadBlob = (blobObj: any, name: string) => {
  const anchorElement: any = document.createElement('a');

  const url = window.URL.createObjectURL(blobObj);
  anchorElement.href = url;
  anchorElement.download = name;

  document.body.appendChild(anchorElement);
  anchorElement.click();
  window.URL.revokeObjectURL(url);

  anchorElement.remove();
};
