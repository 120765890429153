import { ContainerListModal } from '../../design-system';
import { DashboardFormWrapper } from '../dashboard';
import { GateInsPage } from './GateInsComponents';
import { GateInsAddHeader, GateInsAddForm } from './GateInsAddComponents';

export const GateInsAdd = () => {
  return (
    <>
      <GateInsPage>
        <GateInsAddHeader />
        <DashboardFormWrapper innerOverflow>
          <GateInsAddForm />
        </DashboardFormWrapper>
      </GateInsPage>
      <ContainerListModal />
    </>
  );
};
