import { useMutation } from 'react-query';
import { useStore } from '../../../context';
import { controlBackdropLoading, removeCache } from '../../../services';
import { toIsoDate, showToRoles } from '../../../utils';
import { containersService } from '../../../services';
import { ImageObjType } from '../../../types';

export const useUpdateImagesMutation = () => {
  let images: any;

  return useMutation(
    ({ id, photos = [] }: { id: string; photos: ImageObjType[] }) => {
      controlBackdropLoading(1);

      return containersService.updateImages({ id, photos }).then((res: any) => {
        if (typeof res[0] === 'object') images = res[0].data.data;

        res = res.every((res: any) => !!res);
        if (images && !!res) return images;
        return !!res;
      });
    },
    {
      onSettled: () => {
        removeCache(['container']);
        controlBackdropLoading(-1);
      },
    },
  );
};

export const useUpdateDocumentsMutation = () => {
  let docs: any;

  return useMutation(
    ({ id, documents = [] }: { id: string; documents: ImageObjType[] }) => {
      controlBackdropLoading(1);

      return containersService.updateDocuments({ id, documents }).then((res: any) => {
        if (typeof res[0] === 'object') docs = res[0].data.data;

        res = res.every((res: any) => !!res);
        if (docs && !!res) return docs;
        return !!res;
      });
    },
    {
      onSettled: () => {
        removeCache(['container']);
        controlBackdropLoading(-1);
      },
    },
  );
};

export const useAddContainerMutation = () => {
  return useMutation(
    ({ driverFirstName, driverLastName, licensePlateNumber, ...props }: any) => {
      if (driverFirstName) props.driver = { ...props.driver, firstName: driverFirstName };
      if (driverLastName) props.driver = { ...props.driver, lastName: driverLastName };
      if (licensePlateNumber)
        props.driver = {
          ...props.driver,
          licensePlateNumber: licensePlateNumber,
        };
      if (props.completedAt) props.completedAt = toIsoDate(props.completedAt);

      return containersService.add(props);
    },
    {
      onSettled: () => {
        removeCache(['container', 'gate-in', 'key']);
      },
    },
  );
};

export const useEditContainerMutation = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return useMutation(
    (props: any) => {
      if (!showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user)) delete props.number;

      return containersService.edit(props);
    },
    {
      onSettled: () => {
        removeCache(['container', 'gate-in', 'gate-out', 'key']);
      },
    },
  );
};

export const useTransferContainerMutation = () => {
  return useMutation(
    (props: any) => {
      return containersService.transfer(props);
    },
    {
      onSettled: () => {
        removeCache(['container', 'gate-in', 'gate-out', 'key']);
      },
    },
  );
};
