import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableDots,
  Pagination,
  Chip,
  Button,
  ContainerAvatar,
  enqueueSnackbar,
  containerEditModal,
  containerTransferModal,
  uploadImagesModal,
} from '../../design-system';
import { DashboardHeader } from '../dashboard';
import { Filters } from './Filters';
import { useStore } from '../../context';
import { momentTextFormat, momentDiffDays, showToRoles } from '../../utils';
import {
  useStatesWithFilterCounter,
  useSort,
  usePaginationState,
  useContainersQuery,
} from '../../hooks';
import { initialGateOutsAddProps } from '../gate-outs';
import {
  containersTableDotsAllActionsWithTransfer,
  containersTableDotsAllActions,
  containersTableDotsActions,
  containersTableDotsClientActions,
  containerStatues,
  TABLE,
  MESSAGES,
} from '../../const';

interface ContainersPageProps {
  innerScroll?: boolean;
}

export const ContainersPage = styled.div<ContainersPageProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const ContainersHeader = ({ totalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Containers: {totalCount}
      </Typography>
      {showToRoles('admin', user) && (
        <Button size={'medium'} to={'/dashboard/containers/add'}>
          Gate In Container
        </Button>
      )}
    </DashboardHeader>
  );
};

export const ContainersBody = ({ setTotalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const navigate = useNavigate();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const [openDotsMenuIndex, setDotsMenuIndex] = useState(null);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter({
    country: '',
    city: '',
    yardId: '',
    clientId: '',
    number: '',
    type: 'All',
    status: 'All',
    startDate: null,
    endDate: null,
  });
  const { sortField, sortDirection, handleSort } = useSort({
    sortField: 'gateIn',
    sortDirection: 'desc',
  });

  const { data, isSuccess, isFetching, refetch } = useContainersQuery({
    ...filteredStates,
    take,
    skip,
    sortField,
    sortDirection,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: any) => {
    if (openDotsMenuIndex !== i) return setDotsMenuIndex(i);

    setDotsMenuIndex(null);
  };

  const handleDotsOptionSelect = async (row: any, number: any, val: any, id: any) => {
    if (val === 'edit') {
      Object.keys(containerEditModal).forEach((key) => {
        if (key !== 'open') delete containerEditModal[key];
      });
      containerEditModal.number = number;
      containerEditModal.type = row.type;
      containerEditModal.val = val;
      containerEditModal.id = id;
      containerEditModal.submitClick = () => {
        enqueueSnackbar(MESSAGES.CONTAINERS.SUCCESS.EDITED, 'success');
        refetch();
      };
      containerEditModal.open();
    }
    if (val === 'addphotos' || val === 'adddocuments') {
      let oldFilesLength = 0;
      const key = val === 'addphotos' ? 'images' : val === 'adddocuments' ? 'documents' : '';
      if (key) oldFilesLength = row[key]?.length || 0;

      Object.keys(uploadImagesModal).forEach((key) => {
        if (key !== 'open') delete uploadImagesModal[key];
      });
      uploadImagesModal.number = number;
      uploadImagesModal.val = val;
      uploadImagesModal.id = id;
      uploadImagesModal.oldFilesLength = oldFilesLength;
      uploadImagesModal.submitClick = () => {
        enqueueSnackbar(MESSAGES.CONTAINERS.SUCCESS.FILES_CHANGE, 'success');
        refetch();
      };
      uploadImagesModal.open();
    }
    if (val === 'requestgateout') {
      initialGateOutsAddProps.containerId = [id];
      initialGateOutsAddProps.containerNumber = [number];
      initialGateOutsAddProps.country = row.clientYard.yard.address.country;
      initialGateOutsAddProps.city = row.clientYard.yard.address.city;
      initialGateOutsAddProps.yardId = row.clientYard.yard.id;
      initialGateOutsAddProps.yardName = row.clientYard.yard.name;
      initialGateOutsAddProps.clientId = row.clientYard.client.id;
      initialGateOutsAddProps.clientName = row.clientYard.client.code;

      navigate('/dashboard/gateouts/add');
    }
    if (val === 'transfer') {
      containerTransferModal.containerId = id;
      containerTransferModal.number = number;
      containerTransferModal.yardId = row.clientYard.yard.id;
      containerTransferModal.clientId = row.clientYard.client.id;

      containerTransferModal.submitClick = () => {
        enqueueSnackbar(MESSAGES.CONTAINERS.SUCCESS.TRANSFER, 'success');
        refetch();
      };
      containerTransferModal.open();
    }
  };

  const handleRowClick = (to: string) => {
    if (openDotsMenuIndex !== null) return;
    navigate(to);
  };

  return (
    <TableWrapper
      columns={showToRoles('admin', user) ? [7, 7, 10, 20, 20, 15, 20] : [7, 10, 20, 15, 20]}
    >
      <Filters setStates={setStates} {...states} />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder={true}
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Actions</TableHeadCell>
            <TableHeadCell align={'left'}>Photo</TableHeadCell>
            {showToRoles('admin', user) && <TableHeadCell align={'left'}>Client</TableHeadCell>}
            <TableHeadCell align={'left'}>Container number</TableHeadCell>
            <TableHeadCell align={'left'}>Type</TableHeadCell>
            <TableHeadCell align={'left'}>Status</TableHeadCell>
            <TableHeadCell
              align={'left'}
              columnIdx={'gateIn'}
              sortField={sortField}
              sortDirection={sortDirection}
              handleSort={handleSort}
            >
              Gate In Date
            </TableHeadCell>
            <TableHeadCell
              align={'left'}
              columnIdx={'gateOut'}
              sortField={sortField}
              sortDirection={sortDirection}
              handleSort={handleSort}
            >
              Gate Out Date
            </TableHeadCell>
            <TableHeadCell align={'left'}>Days in Terminal</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={
          isSuccess &&
          data.data.map((row: any, i: any) => (
            <TableRow
              key={row.number + i}
              onClick={() => handleRowClick(`/dashboard/containers/${row.id}`)}
            >
              {showToRoles('admin', user) || row.status === 'GATED_IN' ? (
                <TableIconCell component={'th'} align={'left'}>
                  <TableDots
                    open={openDotsMenuIndex === i}
                    onToggle={() => handleDotsToggle(i)}
                    options={
                      showToRoles('admin', user)
                        ? showToRoles(['SUPER_ADMIN', 'YARD_MANAGER'], user) &&
                          row.status === 'GATED_IN'
                          ? containersTableDotsAllActionsWithTransfer
                          : row.status === 'GATED_IN'
                          ? containersTableDotsAllActions
                          : containersTableDotsActions
                        : row.status === 'GATED_IN'
                        ? containersTableDotsClientActions
                        : []
                    }
                    onSelect={(val) => {
                      handleDotsOptionSelect(row, row.number, val, row.id);
                    }}
                  />
                </TableIconCell>
              ) : (
                <TableIconCell component={'th'} align={'left'} />
              )}
              <TableIconCell component={'th'} align={'left'}>
                <ContainerAvatar images={row.images} />
              </TableIconCell>
              {showToRoles('admin', user) && (
                <TableCell align={'left'}>{row.clientYard?.client?.code}</TableCell>
              )}
              <TableCell align={'left'}>{row.number}</TableCell>
              <TableCell align={'left'}>{row.type}</TableCell>
              <TableCell align={'left'}>
                <Chip
                  label={containerStatues.find((obj) => obj.value === row.status)?.label}
                  size={'small'}
                />
              </TableCell>
              <TableCell align={'left'}>
                {row.gateIn.completedAt && momentTextFormat(row.gateIn.completedAt, user)}
              </TableCell>
              <TableCell align={'left'}>
                {row.gateOut?.completedAt && momentTextFormat(row.gateOut?.completedAt, user)}
              </TableCell>
              <TableCell align={'left'}>
                {momentDiffDays(row.gateIn.completedAt, row.gateOut?.completedAt || new Date())}
                {' Days'}
              </TableCell>
            </TableRow>
          ))
        }
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
