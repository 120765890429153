import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const savedFilters: any = {};

export const useSaveFilters = (key = '', saveFilters: boolean, defaultFilters = {}) => {
  const { pathname } = useLocation();
  let oldFilters = savedFilters[pathname + key]?.filters || defaultFilters;

  if (pathname !== savedFilters[pathname + key]?.pathname) oldFilters = defaultFilters;

  useEffect(() => {
    if (!savedFilters[pathname + key]) savedFilters[pathname + key] = {};

    if (!saveFilters) return;
    if (pathname === savedFilters[pathname + key]?.pathname) return;

    savedFilters[pathname + key].pathname = pathname;
    savedFilters[pathname + key].filters = defaultFilters;
  }, [pathname]);

  const handleChangeFilters = (data: any) => {
    if (!savedFilters[pathname + key]) savedFilters[pathname + key] = {};

    savedFilters[pathname + key].filters = data;
  };

  return [oldFilters, handleChangeFilters];
};

export const useSaveFiltersControlEffect = () => {
  const { pathname } = useLocation();
  const secondPath = pathname.split('/')[2];

  useEffect(() => {
    Object.keys(savedFilters)
      .filter((key) => !key.includes(secondPath))
      .forEach((key) => delete savedFilters[key]);
  }, [pathname]);
};
