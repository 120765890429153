import { useParams, Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../../dashboard';
import { ClientsPage } from './ClientsComponents';
import { CompanyViewHeader, CompanyViewForm } from '../CompanyViewComponents';
import { useStore } from '../../../context';
import { useClientQuery } from '../../../hooks';
import { showToRoles } from '../../../utils';

export const ClientsView = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const params: any = useParams();
  const { data, isFetching } = useClientQuery({ id: params.id });

  if (isFetching) return <></>;
  if (!showToRoles(['SUPER_ADMIN'], user) || !data)
    return <Navigate to={'/dashboard/clients'} replace />;

  return (
    <ClientsPage>
      <CompanyViewHeader data={data} />
      <DashboardFormWrapper flow={'row'}>
        <CompanyViewForm data={data} />
      </DashboardFormWrapper>
    </ClientsPage>
  );
};
