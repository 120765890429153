import { Navigate, useParams } from 'react-router-dom';
import { DashboardFormWrapper } from '../dashboard';
import { GateOutsPage } from './GateOutsComponents';
import { GateOutsViewHeader, GateOutsViewForm } from './GateOutsViewComponents';
import { useGateOutQuery } from '../../hooks';

export const GateOutsView = () => {
  const params: any = useParams();
  const { data: request, isFetching } = useGateOutQuery(params.id);

  if (isFetching) return <></>;
  if (!request) return <Navigate to={'/dashboard/gateouts'} replace />;

  return (
    <GateOutsPage>
      <GateOutsViewHeader request={request} />
      <DashboardFormWrapper flow={'row'}>
        <GateOutsViewForm request={request} />
      </DashboardFormWrapper>
    </GateOutsPage>
  );
};
