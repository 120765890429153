import { useQuery } from '../../../hooks';
import { extendContainerValidator, toIsoDate } from '../../../utils';
import { gateOutsService } from '../../../services';

export const useGateOutsQuery = (keys: any = {}) => {
  if (keys.containerNumber?.length < 3 || !extendContainerValidator(keys.containerNumber))
    delete keys.containerNumber;
  if (keys.startDate) keys.startDate = toIsoDate(keys.startDate, true);
  if (keys.endDate) keys.endDate = toIsoDate(keys.endDate, true);

  return useQuery(
    ['gate-outs', JSON.stringify(keys)],
    () => {
      return gateOutsService
        .getAll({
          params: keys,
        })
        .then((res) => res.data);
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );
};

export const useGateOutQuery = (id = '') =>
  useQuery(['gate-out', id], () => gateOutsService.get(id).then((res) => res.data.data), {
    initialData: { data: {} },
  });
