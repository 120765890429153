import { TableCell as MuiTableCell, TableCellProps } from '@mui/material';
import styled from 'styled-components';

const TableCell = styled(MuiTableCell)`
  position: relative;

  & > span {
    position: absolute;
    left: 1.2rem;
    top: 50%;
    transform: translateY(-50%);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 2.4rem);
  }
`;

export const TableCellWhiteSpace: React.FC<TableCellProps> = ({
  children,
  ...props
}: TableCellProps) => {
  return (
    <TableCell {...props}>
      <span>{children}</span>
    </TableCell>
  );
};
