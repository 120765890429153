import { useMutation } from 'react-query';
import { clientsService, createCompany, removeCache } from '../../../services';

interface CreateCompanyProps {
  city: string;
  companyLegalName: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  country: string;
  companyEmail: string;
  companyPhoneNumber: string;
  email: string;
  phoneNumber: string;
  streetAddress: string;
  zipCode: string;
  name?: string;
  code?: string;
  yardCompanyId?: string;
  belongsTo: 'YARD_COMPANIES' | 'YARD' | 'CLIENT';
}

export const useCreateCompanyMutation = () => {
  return useMutation(
    ({
      city,
      companyLegalName,
      contactPersonFirstName,
      contactPersonLastName,
      country,
      companyEmail,
      companyPhoneNumber,
      email,
      phoneNumber,
      streetAddress,
      zipCode,
      name,
      code,
      yardCompanyId,
      belongsTo,
    }: CreateCompanyProps) => {
      let props: any = {
        legalName: companyLegalName,
        address: {
          streetAddress,
          city,
          country,
        },
        email: companyEmail,
        phoneNumber: companyPhoneNumber,
        contactPerson: {
          firstName: contactPersonFirstName,
          lastName: contactPersonLastName,
          email,
          phoneNumber,
        },
      };
      Object.keys(props.contactPerson).forEach((key) => {
        if (!props.contactPerson[key]) delete props.contactPerson[key];
      });

      if (belongsTo === 'CLIENT') {
        props.code = code;
      }
      if (belongsTo === 'YARD') {
        props = {
          name,
          address: {
            streetAddress,
            city,
            country,
          },
          yardCompanyId,
        };

        if (phoneNumber) props.phoneNumber = phoneNumber;
      }

      if (zipCode) props.address.zipCode = zipCode;

      let path = '/yard-companies';
      if (belongsTo === 'YARD') {
        path = '/yards';
      }
      if (belongsTo === 'CLIENT') {
        path = '/clients';
      }

      return createCompany({ path, props });
    },
    {
      onSuccess: () => {
        removeCache(['yard-companies', 'yard', 'client', 'key']);
      },
    },
  );
};

export const useEditClientMutation = ({ id }: { id: string }) => {
  return useMutation((props: any) => clientsService.edit(props), {
    onSuccess: () => {
      removeCache([id]);
    },
  });
};
