import { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import {} from '../services';
import { useSigninMutation } from '../hooks';

interface PrivateRouteProps {
  Component: any;
  props?: any;
}

export const PrivateRoute = ({ Component, ...props }: PrivateRouteProps) => {
  const { mutate } = useSigninMutation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const jwt = Cookies.get('jwt');
    const user = Cookies.get('user');

    if (!jwt && !user) return setIsLoading(false);

    mutate(undefined, {
      onSuccess: () => {
        setIsAuthenticated(true);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  }, []);

  if (isLoading) return <></>;
  if (isAuthenticated)
    return (
      <Component {...props}>
        <Outlet />
      </Component>
    );
  return <Navigate to={'/signin'} replace />;
};
