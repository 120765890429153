import styled, { css } from 'styled-components';
import { Paper } from '../../design-system';

export const DashboardBase = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content 1fr;

  height: 100%;
`;

export const DashboardMain = styled.div`
  display: grid;
  overflow: overlay;
  padding: var(--spacing-10);
  padding-top: var(--spacing-8);
`;

export const DashboardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  grid-column-gap: var(--spacing-3);
  grid-auto-flow: column;
  align-items: center;
`;

interface DashboardFooterProps {
  columns?: string;
}

export const DashboardFooter = styled(DashboardHeader)<DashboardFooterProps>`
  grid-template-columns: max-content;
  justify-content: end;

  ${(props) =>
    props.columns &&
    css`
      grid-template-columns: ${props.columns};
    `}
`;

export const DashboardFooterWithResize = styled(DashboardFooter)`
  @media (max-width: 1540px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-row-gap: var(--spacing-3);
    & > button.MuiButtonBase-root {
      justify-self: initial;
    }
  }
`;

export const DashboardFormWrapper = styled.div<{ flow?: string; innerOverflow?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 3rem;

  & > *:nth-child(1) {
    height: max-content;
  }

  ${(props) =>
    props.flow === 'row' &&
    css`
      grid-auto-rows: max-content;
      grid-auto-flow: row;
    `}

  ${(props) =>
    props.innerOverflow &&
    css`
      overflow: hidden;

      & > *:nth-child(1) {
        height: 100%;
      }
    `}
`;

export const DashboardForm = styled(Paper)<{ withoutspacing?: string }>`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: var(--spacing-6);
  min-width: 73rem;
  overflow: visible;

  ${(props) =>
    !!props.withoutspacing &&
    css`
      grid-row-gap: 0;
    `}
`;

export const DashboardFormCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-gap: var(--spacing-6);
`;

export const DashboardFormThreeCards = styled(DashboardFormCards)`
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr 1fr;
`;

interface DashboardFormCardProps {
  rows?: string;
}

export const DashboardFormCard = styled(Paper)<DashboardFormCardProps>`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  overflow: visible;

  ${(props) =>
    props.rows &&
    css`
      grid-template-rows: ${props.rows};
    `}
`;

export const DashboardFormCardsFooter = styled.div`
  grid-column: 1 /3;
`;

export const DashboardFormTwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-column-gap: 2.8rem;
  grid-row-gap: 2.4rem;
`;

interface DashboardFormCustomColumnTypes {
  columns: string;
  bottomSpacing?: boolean;
  align?: string;
  alignContent?: string;
  withoutGap?: boolean;
}

export const DashboardFormCustomColumn = styled(
  DashboardFormTwoColumn,
)<DashboardFormCustomColumnTypes>`
  grid-template-columns: ${(props) => props.columns};
  align-items: ${(props) => props.align};
  align-content: ${(props) => props.alignContent};

  ${(props) =>
    props.bottomSpacing &&
    css`
      margin-bottom: var(--spacing-6);
    `}

  ${(props) =>
    props.withoutGap &&
    css`
      grid-gap: initial;
    `}

  & .row-button-square {
    margin-top: var(--spacing-6);
  }
`;
