const colors: any = {
  'in stock': 'green',
  'in service': 'yellow',
  'gated in': 'green',
  'gated out': 'red',
  'Gated In': 'green',
  'Gated Out': 'red',
  Ground: 'grey',
  Rail: 'yellow',
  Port: 'blue',
  ground: 'grey',
  rail: 'yellow',
  port: 'blue',
  'Gate in': 'green',
  'Gate out': 'red',
  ACTIVE: 'green',
  active: 'green',
  completed: 'red',
  DELETED: 'red',
  DEACTIVATED: 'grey',
};

export const chipDefaultColors = (text: any) => colors[text];
