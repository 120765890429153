import { useLocation } from 'react-router-dom';
import { TableFilters, Input, SearchableSelect } from '../../design-system';
import { useStore } from '../../context';
import { belongsToOptions } from '../../services';
import { showToRoles } from '../../utils';
import { viewCompanyLabel } from '../../const';

interface Props {
  companyLegalName?: string;
  yardCompanyId?: string;
  name?: string;
  code?: string;
  streetAddress?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  email?: string;
  phoneNumber?: string;
  setStates: any;
}

export const CompanyFilters: React.FC<Props> = ({
  companyLegalName,
  yardCompanyId = '',
  country,
  setStates,
}: Props) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const pathname = useLocation().pathname.split('/')[2];

  return (
    <TableFilters>
      <span className={'remove-dots-column'} />
      {pathname !== 'yards' && (
        <Input
          label={viewCompanyLabel[pathname]}
          data-size={'medium'}
          defaultValue={companyLegalName}
          onChange={(e) => setStates('companyLegalName', e.target.value)}
        />
      )}
      {pathname === 'yards' && showToRoles(['SUPER_ADMIN'], user) && (
        <SearchableSelect
          selectedOptionLabel={companyLegalName}
          label={'Yard Company'}
          placeholder={`Select Yard Company`}
          onSelect={(obj) =>
            setStates('force', { companyLegalName: obj.label, yardCompanyId: obj.id })
          }
          queryFn={belongsToOptions.YardCompany}
          queryKey={'yard-companies-short'}
          selectedAreasEqaul={{ key: 'id', keys: [yardCompanyId] }}
        />
      )}

      {pathname !== 'yards' && (
        <Input
          label={'Country'}
          data-size={'medium'}
          defaultValue={country}
          onChange={(e) => setStates('country', e.target.value)}
        />
      )}
    </TableFilters>
  );
};
