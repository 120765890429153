import { Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../../dashboard';
import { EmployeesPage } from './EmployeesComponents';
import { EmployeesAddHeader, EmployeesAddForm } from './EmployeesAddComponents';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';

export const EmployeesAdd = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  if (
    showToRoles(
      ['YARD_COMPANY_EMPLOYEE', 'YARD_MANAGER', 'YARD_DISPATCHER', 'CLIENT_DISPATCHER'],
      user,
    )
  )
    return <Navigate to={'/dashboard/employees'} replace />;

  return (
    <EmployeesPage>
      <EmployeesAddHeader />
      <DashboardFormWrapper>
        <EmployeesAddForm />
      </DashboardFormWrapper>
    </EmployeesPage>
  );
};
