import { Link as LinkBase, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledLinkBase = styled(LinkBase)`
  display: grid;
  color: inherit;

  &[data-with-underline='true']:hover {
    text-decoration: underline;
  }

  &[data-max-width='true'] {
    width: max-content;
  }
`;

interface LinkProps {
  to?: string | -1;
  withunderline?: boolean;
  maxwidth?: boolean;
  onClick?: (e: any) => void;
  children?: string | React.ReactNode;
  target?: 'blank';
}

export const Link: React.FC<LinkProps> = ({
  to,
  withunderline,
  maxwidth,
  children,
  ...props
}: LinkProps) => {
  const navigate = useNavigate();

  const handleOnClick = (e: any) => {
    props.onClick && props.onClick(e);

    if (to === -1) navigate(to);
  };

  if (!to || (typeof to !== 'string' && typeof to !== 'number')) return <>{children}</>;

  return (
    <StyledLinkBase
      to={typeof to === 'string' ? to : ''}
      data-with-underline={withunderline}
      data-max-width={maxwidth}
      onClick={handleOnClick}
      {...props}
    >
      {children}
    </StyledLinkBase>
  );
};
