import { forwardRef } from 'react';
import { InputBase as MuiInputBase } from '@mui/material';
import { InputBaseProps } from './Input';
import { readFiles, inputFilesValidators } from './utils';

interface FileInputProps extends Omit<InputBaseProps, 'error'> {
  handleChangeFiles?: (images: any, files: any) => void;
}

export const FileInput: React.FC<FileInputProps> = forwardRef(
  ({ label = '', id = '', handleChangeFiles, ...props }: FileInputProps, ref) => {
    const handleOnChange = (e: any) => {
      readFiles(e, (readedFiles, files) => {
        if (!inputFilesValidators(readedFiles, files)) return;

        handleChangeFiles && handleChangeFiles(readedFiles, files);
      });
    };

    return (
      <MuiInputBase
        id={id || label}
        data-size={'medium'}
        type={'file'}
        onChange={handleOnChange}
        inputProps={{
          value: props.value,
          ref,
        }}
        value={undefined}
        {...props}
      />
    );
  },
);
FileInput.displayName = 'FileInput';
