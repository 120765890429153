import { useMutation } from 'react-query';
import { clientYardService, removeCache } from '../../../services';

export const useAssignClientsMutation = () => {
  return useMutation(
    (props: any) => {
      props.path = props.action;
      props.METHOD = props.action === 'add-clients' ? 'post' : 'patch';

      return clientYardService.assignClients(props);
    },
    {
      onSettled: () => {
        removeCache(['yard', 'client', 'key']);
      },
    },
  );
};

export const useAssignYardsMutation = () => {
  return useMutation(
    (props: any) => {
      props.path = props.action;
      props.METHOD = props.action === 'add-yards' ? 'post' : 'patch';

      return clientYardService.assignYards(props);
    },
    {
      onSettled: () => {
        removeCache(['yard', 'client', 'key']);
      },
    },
  );
};

export const useSendContractMutation = () => {
  return useMutation(clientYardService.sendContract);
};
