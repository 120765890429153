export enum AccountStatus {
  ACTIVE = 'ACTIVATE',
  DEACTIVATED = 'DEACTIVATE',
}

export enum BelongsTo {
  SUPER_ADMIN = 'SUPER_ADMIN',
  YARD_COMPANY = 'YARD_COMPANY',
  YARD = 'YARD',
  CLIENT = 'CLIENT',
}

type UserType =
  | 'YARD_COMPANY_ADMIN'
  | 'YARD_COMPANY_EMPLOYEE'
  | 'YARD_MANAGER'
  | 'YARD_DISPATCHER'
  | 'CLIENT_MANAGER'
  | 'CLIENT_DISPATCHER';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  accountStatus: AccountStatus;
  belongsTo: BelongsTo;
  yardCompanyEmployee: YardCompanyEmployee;
  yardEmployee: YardEmployee;
  clientEmployee: ClientEmployee;
  createdAt: Date;
  updatedAt: Date;
  type: UserType & any;
}

interface YardCompanyEmployee {
  yardCompanyId: string;
  role: string;
  yardCompany: { legalName: string };
}

interface YardEmployee {
  yardId: string;
  role: string;
  yard: { name: string };
}

interface ClientEmployee {
  clientId: string;
  role: string;
  client: { legalName: string };
}

export interface ImageObjType {
  action: 'upload' | 'delete';
  file: any;
  name?: string;
}
