import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AssignYardsAndClientsModal } from '../../../design-system';
import { YardsPage, YardsHeader, YardsBody } from './YardsComponents';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';

export const Yards = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [totalCount, setTotalCount] = useState(0);

  if (!showToRoles(['SUPER_ADMIN'], user)) return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <>
      <YardsPage innerScroll>
        <YardsHeader totalCount={totalCount} />
        <YardsBody setTotalCount={setTotalCount} />
      </YardsPage>
      <AssignYardsAndClientsModal />
    </>
  );
};
