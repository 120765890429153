import { useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import validator from 'validator';
import {
  Typography,
  Button,
  Input,
  InputPassword,
  InputPhoneNumber,
  Select,
  SearchableSelect,
  BorderLine,
  enqueueSnackbar,
} from '../../../design-system';
import { DashboardHeader, DashboardForm, DashboardFormTwoColumn } from '../../dashboard';
import { useStore } from '../../../context';
import { useCreateEmployeeMutation } from '../../../hooks';
import { showToRoles, getPhoneNumberValue, getNormalFormProps } from '../../../utils';
import { belongsToOptions, belongsToOptionsQueryKeys } from '../../../services';
import {
  employeesRoles,
  employeesFormCompanyRolesOptions,
  employeesViewLabel,
  INPUT_ERRORS,
  REGEX,
  MESSAGES,
} from '../../../const';

let formProps: any = {};

const getSubmitProps = () => {
  const { validators }: any = formProps;

  if ([...Object.values(validators).map((val) => !val)].some((val) => !val)) {
    return {};
  }

  formProps.phoneNumber = getPhoneNumberValue(formProps.phoneNumber);
  delete formProps.isSubmitClicked;

  return { submitProps: getNormalFormProps(formProps), resolveFn: formProps.resolveFn };
};

export const EmployeesAddHeader = () => {
  const { mutate } = useCreateEmployeeMutation();

  const handleSubmitClick = async () => {
    formProps.onSubmitClick();
    const { submitProps, resolveFn } = getSubmitProps();
    if (!submitProps) return;

    await mutate(submitProps, {
      onSuccess: () => {
        enqueueSnackbar(MESSAGES.EMPLOYEE.SUCCESS.CREATED, 'success');
        resolveFn();
      },
      onError: (err: any) => {
        enqueueSnackbar(err.response.data.message, 'error');
        formProps.isSubmitClicked = true;
      },
    });
  };

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Add Employee
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
      <Button size={'medium'} data-color={'primary'} onClick={handleSubmitClick}>
        Submit
      </Button>
    </DashboardHeader>
  );
};

export const EmployeesAddForm = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [companyType, setCompanyType] = useState<'YardCompany' | 'Yard' | 'Client'>(
    showToRoles('admin', user) ? 'Yard' : 'Client',
  );
  const [employeeRole, setEmployeeRole] = useState(
    showToRoles(['SUPER_ADMIN'], user) ? 'Manager' : 'Dispatcher',
  );
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState(
    showToRoles(['YARD_MANAGER'], user)
      ? user.yardEmployee.yardId
      : showToRoles(['CLIENT_MANAGER'], user)
      ? user.clientEmployee.clientId
      : '',
  );
  const [updateKey, setUpdate] = useState<any>(Math.random());

  const validators = {
    email: email
      ? !validator.isLength(email, { min: 6, max: 50 })
        ? INPUT_ERRORS.NOT_VALID.EMAIL_LENGTH
        : !REGEX.EMAIL.test(email)
        ? INPUT_ERRORS.NOT_VALID.EMAIL
        : ''
      : true,
    phoneNumber: phoneNumber
      ? !isValidPhoneNumber(`+${getPhoneNumberValue(phoneNumber)}`)
        ? INPUT_ERRORS.NOT_VALID.PHONE_NUMBER
        : ''
      : true,
    firstName: firstName
      ? !validator.isLength(firstName, { min: 2, max: 30 })
        ? INPUT_ERRORS.NOT_VALID.FIRST_NAME_LENGTH
        : !REGEX.USER_NAME.test(firstName)
        ? INPUT_ERRORS.NOT_VALID.FIRST_NAME
        : ''
      : true,
    lastName: lastName
      ? !validator.isLength(lastName, { min: 2, max: 30 })
        ? INPUT_ERRORS.NOT_VALID.LAST_NAME_LENGTH
        : !REGEX.USER_NAME.test(lastName)
        ? INPUT_ERRORS.NOT_VALID.LAST_NAME
        : ''
      : true,
    password: password
      ? !validator.isLength(password, { min: 8, max: 16 })
        ? INPUT_ERRORS.NOT_VALID.PASSWORD_LENGTH
        : !REGEX.PASSWORD.test(password)
        ? INPUT_ERRORS.NOT_VALID.PASSWORD
        : ''
      : true,
    passwordConfirm: passwordConfirm
      ? !validator.equals(passwordConfirm, password)
        ? INPUT_ERRORS.NOT_VALID.PASSWORD_CONFIRM
        : ''
      : true,
    companyId: showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user) && !companyId,
  };

  useEffect(() => {
    if (!formProps) return;

    formProps.isSubmitClicked = false;
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    formProps = {
      ...formProps,
      email,
      phoneNumber,
      firstName,
      lastName,
      password,
      passwordConfirm,
      companyType,
      employeeRole,
      companyId,
      validators,
      onSubmitClick: () => {
        formProps.isSubmitClicked = true;
        setUpdate(Math.random());
      },
      resolveFn: () => {
        setEmail('');
        setPhoneNumber('');
        setFirstName('');
        setLastName('');
        setPassword('');
        setPasswordConfirm('');
        setCompanyType(showToRoles('admin', user) ? 'Yard' : 'Client');
        setEmployeeRole(showToRoles(['SUPER_ADMIN'], user) ? 'Manager' : 'Dispatcher');
        setCompanyId('');
        setCompanyName('');
        setUpdate(Math.random());
      },
    };
  }, [email, phoneNumber, firstName, lastName, password, passwordConfirm, employeeRole, companyId]);

  return (
    <DashboardForm key={updateKey} paperSize={'full-size'}>
      <Typography variant={'textM'} weight={'bold'}>
        Employee
      </Typography>
      <DashboardFormTwoColumn>
        <Select
          data-size={'medium'}
          label={'Belongs To'}
          options={employeesFormCompanyRolesOptions[user.type]}
          value={companyType}
          onChange={(e: any) => {
            let companyId = '';
            let companyName = '';

            if (e.target.value === 'YardCompany' && showToRoles(['YARD_COMPANY_ADMIN'], user)) {
              companyId = user.yardCompanyEmployee.yardCompanyId;
              companyName = user.yardCompanyEmployee.yardCompany.legalName;
            }

            setCompanyId(companyId);
            setCompanyName(companyName);
            setCompanyType(e.target.value);
            setEmployeeRole(
              e.target.value === 'YardCompany'
                ? 'Employee'
                : showToRoles(['SUPER_ADMIN'], user)
                ? 'Manager'
                : 'Dispatcher',
            );
          }}
        />
        <Select
          data-size={'medium'}
          label={'Role'}
          options={employeesRoles[user.type][companyType]}
          value={employeeRole}
          onChange={(e: any) => setEmployeeRole(e.target.value)}
        />
      </DashboardFormTwoColumn>
      {showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user) && (
        <SearchableSelect
          selectedOptionLabel={companyName}
          label={employeesViewLabel[companyType]}
          placeholder={`Select ${employeesViewLabel[companyType]}`}
          onSelect={(obj: any) => {
            setCompanyName(obj.label);
            setCompanyId(obj.id);
          }}
          queryFn={(inputValue: any) =>
            companyType === 'Client'
              ? belongsToOptions[companyType](inputValue, true, true)
              : companyType === 'Yard'
              ? () => belongsToOptions[companyType](inputValue, true, 'yard')
              : belongsToOptions[companyType](inputValue, true)
          }
          queryKey={belongsToOptionsQueryKeys[companyType]}
          error={formProps.isSubmitClicked && !companyId}
          helperText={formProps.isSubmitClicked && !companyId ? INPUT_ERRORS.REQUIRED.COMPANY : ''}
          disabled={!!showToRoles(['YARD_COMPANY_ADMIN'], user) && companyType === 'YardCompany'}
          selectedAreasEqaul={{ key: 'id', keys: [companyId] }}
        />
      )}
      <BorderLine space={'dashboard-form'} />
      <DashboardFormTwoColumn>
        <Input
          defaultValue={email}
          onChange={(e: any) => setEmail(e.target.value)}
          label={'Email'}
          placeholder={'Email'}
          type={'text'}
          data-size={'medium'}
          withoutSpace={true}
          error={(formProps.isSubmitClicked && !email) || (!!email && validators.email)}
          helperText={
            formProps.isSubmitClicked && !email ? INPUT_ERRORS.REQUIRED.EMAIL : validators.email
          }
        />
        <InputPhoneNumber
          value={phoneNumber}
          onChange={(e: any) => setPhoneNumber(e.target.value)}
          label={'Phone Number'}
          placeholder={'Phone Number'}
          data-size={'medium'}
          error={
            (formProps.isSubmitClicked && !phoneNumber) || (!!phoneNumber && validators.phoneNumber)
          }
          helperText={
            formProps.isSubmitClicked && !phoneNumber
              ? INPUT_ERRORS.REQUIRED.PHONE_NUMBER
              : validators.phoneNumber
          }
        />
        <Input
          defaultValue={firstName}
          onChange={(e: any) => setFirstName(e.target.value)}
          label={'First Name'}
          placeholder={'Enter First Name'}
          type={'text'}
          data-size={'medium'}
          error={(formProps.isSubmitClicked && !firstName) || (!!firstName && validators.firstName)}
          helperText={
            formProps.isSubmitClicked && !firstName
              ? INPUT_ERRORS.REQUIRED.FIRST_NAME
              : validators.firstName
          }
        />
        <Input
          defaultValue={lastName}
          onChange={(e: any) => setLastName(e.target.value)}
          label={'Last Name'}
          placeholder={'Enter Last Name'}
          type={'text'}
          data-size={'medium'}
          error={(formProps.isSubmitClicked && !lastName) || (!!lastName && validators.lastName)}
          helperText={
            formProps.isSubmitClicked && !lastName
              ? INPUT_ERRORS.REQUIRED.LAST_NAME
              : validators.lastName
          }
        />
        <InputPassword
          defaultValue={password}
          onChange={(e: any) => setPassword(e.target.value)}
          label={'Password'}
          placeholder={'Create Password'}
          data-size={'medium'}
          error={(formProps.isSubmitClicked && !password) || (!!password && validators.password)}
          helperText={
            formProps.isSubmitClicked && !password
              ? INPUT_ERRORS.REQUIRED.PASSWORD
              : validators.password
          }
        />
        <InputPassword
          defaultValue={passwordConfirm}
          onChange={(e: any) => setPasswordConfirm(e.target.value)}
          label={'Password Confirm'}
          placeholder={'Enter Password Confirm'}
          data-size={'medium'}
          error={
            (formProps.isSubmitClicked && !passwordConfirm) ||
            (!!passwordConfirm && validators.passwordConfirm)
          }
          helperText={
            formProps.isSubmitClicked && !passwordConfirm
              ? INPUT_ERRORS.REQUIRED.PASSWORD_CONFIRM
              : validators.passwordConfirm
          }
        />
      </DashboardFormTwoColumn>
    </DashboardForm>
  );
};
