import styled from 'styled-components';
import {
  Typography,
  InfoItem,
  BorderLine,
  Button,
  updatePasswordModal,
} from '../../../design-system';
import {
  DashboardHeader,
  DashboardFormCards,
  DashboardFormCard,
  DashboardFooter,
} from '../../dashboard';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';
import { rolesLabel } from '../../../const';

export const AccountPage = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;
`;

export const AccountHeader = () => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Account
      </Typography>
    </DashboardHeader>
  );
};

export const AccountForm = ({ company }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  const handleUpdatePasswordClick = () => {
    updatePasswordModal.open();
  };

  return (
    <DashboardFormCards id={'account-view'}>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          General Information
        </Typography>
        <InfoItem label={'First Name'} value={user.firstName} />
        <InfoItem label={'Last Name'} value={user.lastName} />
        <InfoItem label={'Email'} value={user.email} />
        <InfoItem label={'Role'} value={rolesLabel[user.type]} />
        <>
          <BorderLine space={'dashboard-form-card'} />
          <DashboardFooter>
            <Button onClick={handleUpdatePasswordClick}>Update Password</Button>
          </DashboardFooter>
        </>
      </DashboardFormCard>
      {company && (
        <>
          <DashboardFormCard paperSize={'full-size'}>
            <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
              Company Information
            </Typography>
            <InfoItem
              label={
                showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user)
                  ? 'Yard Name'
                  : 'Company Legal Name'
              }
              value={company.companyLegalName}
            />
            <InfoItem label={'Employee Count'} value={company.employeeCount} />
            <InfoItem label={'Code'} value={company.code} />
            <InfoItem
              label={'Contact Name'}
              value={`${company.contactPersonFirstName} ${company.contactPersonLastName}`}
            />
            <InfoItem label={'Email'} value={company.email} />
            <InfoItem label={'Phone Number'} value={company.phoneNumber} />
          </DashboardFormCard>
          <DashboardFormCard paperSize={'full-size'}>
            <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
              Address Information
            </Typography>
            <InfoItem label={'Country'} value={company.country} />
            <InfoItem label={'City'} value={company.city} />
            <InfoItem label={'Street Address'} value={company.streetAddress} />
            <InfoItem label={'Zip Code'} value={company.zipCode} />
          </DashboardFormCard>
        </>
      )}
    </DashboardFormCards>
  );
};
