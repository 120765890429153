import { Outlet } from 'react-router-dom';
import { Appbar, SendContractModal } from '../../design-system';
import { DashboardBase, DashboardMain } from './DashboardComponents';
import { DashboardDrawer } from './DashboardDrawer';

export const Dashboard = () => {
  return (
    <>
      <DashboardBase>
        <DashboardDrawer />
        <Appbar />
        <DashboardMain>
          <Outlet />
        </DashboardMain>
      </DashboardBase>
      <SendContractModal />
    </>
  );
};
