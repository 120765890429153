import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& > .MuiPaper-root': {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridAutoRows: 'max-content',
            gridRowGap: '2.8rem',

            width: '25.5rem',
            padding: '2.8rem',

            border: 0,
            borderRadius: 0,
            boxShadow: 'none',
            overflowY: 'overlay',
          },
        },
      },
    },
  },
});

export const TableDrawer: React.FC<MuiDrawerProps> = ({ children, ...props }: MuiDrawerProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiDrawer {...props}>{children}</MuiDrawer>
    </ThemeProvider>
  );
};
