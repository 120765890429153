import { useState, forwardRef } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  InputBase as MuiInputBase,
  InputBaseProps as MuiInputBaseProps,
  InputAdornment,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& > label.MuiFormLabel-root': {
            position: 'initial',
            transform: 'initial',
            fontFamily: 'Inter',
            fontSize: 'var(--font-size-2)',
            lineHeight: 'var(--line-height-2)',
            color: 'var(--gray-80)',
          },
          '& > label.MuiFormLabel-root.Mui-focused': {
            color: 'var(--primary-100)',
          },
          '& > label.MuiFormLabel-root + div:not(.has-header-component)': {
            marginTop: '0.4rem',
          },
          '& > label.MuiFormLabel-root + div.has-header-component + *': {
            marginTop: '0.4rem',
          },
          '& .MuiInputBase-root': {
            boxSizing: 'border-box',
            borderRadius: '0.4rem',
            position: 'relative',
            padding: 0,
            backgroundColor: 'var(--white)',
            border: '0.1rem solid var(--gray-50)',
            transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

            '&[data-type=file]': {
              borderStyle: 'dashed',
              borderColor: 'var(--gray-60)',
            },

            '&.Mui-error': {
              borderColor: 'var(--red-100)',
            },
            '&.Mui-error.Mui-focused[data-always-show-helper-text=false] + .MuiFormHelperText-root,&.Mui-focused[data-always-show-helper-text=false] + .MuiFormHelperText-root':
              {
                display: 'none',
              },

            '& + .MuiFormHelperText-root': {
              fontFamily: 'Inter',
              fontSize: 'var(--font-size-2)',
              lineHeight: 'var(--line-height-2)',
              color: 'var(--red-100)',
              margin: 0,
              marginTop: 'var(--spacing-1)',
            },
            '&:hover': {
              borderColor: 'var(--gray-60)',
            },
            '&.Mui-focused': {
              borderColor: 'var(--primary-100)',
            },
            '&.MuiInputBase-colorSuccess': {
              borderColor: 'var(--green-100)',
            },
            '&.Mui-disabled': {
              borderColor: 'var(--gray-50)',
              '& > .MuiInputBase-input': {
                color: 'var(--gray-80)',
                WebkitTextFillColor: 'initial',
              },
              '& > .MuiInputBase-input::placeholder': {
                color: 'var(--gray-80)',
                WebkitTextFillColor: 'initial',
                opacity: 1,
              },
            },
            '&.withoutBorder': {
              borderColor: 'var(--white)',
            },
            '&.MuiSelect-root': {
              borderWidth: 0,

              '& > .MuiSelect-select': {
                boxSizing: 'border-box',
                borderRadius: '0.4rem',
                border: '0.1rem solid var(--gray-50)',
                transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
              },

              '&.Mui-error > .MuiSelect-select': {
                borderColor: 'var(--red-100)',
              },
              '&:hover > .MuiSelect-select': {
                borderColor: 'var(--gray-60)',
              },
              '&.Mui-focused > .MuiSelect-select': {
                borderColor: 'var(--primary-100)',
              },
              '&.MuiInputBase-colorSuccess > .MuiSelect-select': {
                borderColor: 'var(--green-100)',
              },
              '&.Mui-disabled > .MuiSelect-select': {
                borderColor: 'var(--gray-50)',
              },
            },
          },
          '& .MuiInputBase-input': {
            fontFamily: 'Inter',
            fontSize: 'var(--font-size-3)',
            lineHeight: 'var(--line-height-3)',
            padding: '0 1.6rem',
            color: 'var(--gray-100)',
            width: '100%',
            height: '100%',
            transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

            '&[type=file]': {
              cursor: 'pointer',
              color: 'var(--primary-100)',
              textAlignLast: 'center',

              '&::-webkit-file-upload-button': {
                display: 'none',
              },
            },

            '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
          },
          '&.MuiDateTimePickers': {
            '& fieldset': {
              border: 'none',
            },
            '& .MuiInputAdornment-root': {
              pointerEvents: 'initial',
            },
            '& label[data-shrink=false] + div input::-webkit-input-placeholder': {
              opacity: '0.42 !important',
            },
          },
          '& div[data-size="large"], &.MuiDateTimePickers.large > div.MuiInputBase-root': {
            '&:not(.MuiInputBase-multiline)': {
              height: '4.8rem',
            },
            '&.MuiInputBase-multiline': {
              padding: '1.2rem 1.6rem',
            },
            '& input[type=file]': {
              lineHeight: '4.6rem',
            },
          },
          '& div[data-size="medium"], &.MuiDateTimePickers.medium > div.MuiInputBase-root': {
            '&:not(.MuiInputBase-multiline)': {
              height: '4rem',
            },
            '&.MuiInputBase-multiline': {
              padding: '0.8rem 1.6rem',
            },
            '& input[type=file]': {
              lineHeight: '3.8rem',
            },
          },
          '& div[data-size="small"], &.MuiDateTimePickers.small > div.MuiInputBase-root': {
            '&:not(.MuiInputBase-multiline)': {
              height: '3.6rem',
            },
            '&.MuiInputBase-multiline': {
              padding: '0.8rem 1.6rem',
            },
            '& .MuiInputBase-input': {
              fontSize: 'var(--font-size-2)',
              lineHeight: 'var(--line-height-2)',
            },
            '& input[type=file]': {
              lineHeight: '3.4rem',
            },
          },
          '& textarea.MuiInputBase-input': {
            padding: '0',
          },
          '&[hidden]': {
            display: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&::before,&::after': {
            display: 'none',
          },
          '& > .MuiInputAdornment-root': {
            position: 'absolute',
            pointerEvents: 'none',

            '& svg': {
              fontSize: '2.4rem',
              color: 'var(--gray-80)',
              zIndex: 100,
              transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            },
          },
          '&[data-clickable-icon=true] > .MuiInputAdornment-root': {
            pointerEvents: 'all',
            cursor: 'pointer',
          },

          '&.Mui-focused svg': {
            color: 'var(--primary-100)',
          },
        },
        adornedStart: {
          '& > .MuiInputBase-input': {
            paddingLeft: '5.6rem',
          },

          '& > .MuiInputAdornment-positionStart': {
            left: '1.6rem',
          },
        },
        adornedEnd: {
          '& > .MuiInputBase-input': {
            paddingRight: '5.6rem',
          },

          '& > .MuiInputAdornment-positionEnd': {
            right: '1.6rem',
          },

          '& >  .MuiInputAdornment-positionEnd[data-date-picker]': {
            right: '0.4rem',
          },
        },
      },
    },
  },
});

export interface InputBaseProps extends Omit<MuiInputBaseProps, 'error'> {
  label?: string;
  'data-size'?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  withoutBorder?: boolean;
  withoutSpace?: boolean;
  clickableIcon?: boolean;
  helperText?: string | boolean;
  alwaysShowHelperText?: boolean;
  error?: string | boolean;
}

export const Input: React.FC<InputBaseProps> = forwardRef(
  (
    {
      label = '',
      id = '',
      'data-size': dataSize = 'large',
      startIcon,
      endIcon,
      placeholder,
      withoutBorder,
      withoutSpace,
      clickableIcon = false,
      className,
      onFocus,
      onChange,
      helperText,
      alwaysShowHelperText = false,
      ...props
    }: InputBaseProps,
    ref,
  ) => {
    const [readOnly, setReadOnly] = useState(true);

    const handleOnChange = (e: any) => {
      if (!props.multiline) {
        if (withoutSpace) {
          if (typeof e.target.value === 'string') e.target.value = e.target.value.trim();

          return onChange && onChange(e);
        }

        const event: any = { target: { value: e.target.value } };
        if (typeof event.target.value === 'string') event.target.value = event.target.value.trim();

        return onChange && onChange(event);
      }

      return onChange && onChange(e);
    };

    const handleKeyPress = (e: any) => {
      if (e.target.type === 'email' && e.code === 'Space') {
        e.preventDefault();
      }

      return props.onKeyPress && props.onKeyPress(e);
    };

    return (
      <ThemeProvider theme={theme}>
        <FormControl variant={'standard'}>
          {label && (
            <InputLabel shrink htmlFor={id || label}>
              {label}
            </InputLabel>
          )}
          <MuiInputBase
            id={id || label}
            className={`${className} ${withoutBorder ? 'withoutBorder' : ''}`}
            data-size={dataSize}
            placeholder={placeholder || label || 'Input text'}
            data-clickable-icon={clickableIcon}
            startAdornment={
              startIcon && <InputAdornment position={'start'}>{startIcon}</InputAdornment>
            }
            endAdornment={endIcon && <InputAdornment position={'end'}>{endIcon}</InputAdornment>}
            data-type={props.type}
            readOnly={readOnly}
            onFocus={(e) => {
              if (readOnly) setReadOnly(false);
              if (onFocus) onFocus(e);
            }}
            onChange={handleOnChange}
            inputProps={{
              value: props.value,
              ref,
            }}
            onKeyPress={handleKeyPress}
            data-always-show-helper-text={alwaysShowHelperText}
            {...props}
            error={!!props.error}
          />
          {typeof helperText === 'string' && !!helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      </ThemeProvider>
    );
  },
);
Input.displayName = 'Input';
