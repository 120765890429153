import { useQuery } from '../../../hooks';
import { extendContainerValidator, toIsoDate } from '../../../utils';
import { gateInsService } from '../../../services';

export const useGateInsQuery = (keys: any = {}) => {
  if (keys.containerNumber?.length < 3 || !extendContainerValidator(keys.containerNumber))
    delete keys.containerNumber;
  if (keys.startDate) keys.startDate = toIsoDate(keys.startDate, true);
  if (keys.endDate) keys.endDate = toIsoDate(keys.endDate, true);

  return useQuery(
    ['gate-ins', JSON.stringify(keys)],
    async () => {
      return gateInsService
        .getAll({
          params: keys,
        })
        .then((res) => res.data);
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );
};

export const useGateInQuery = (id = '') =>
  useQuery(
    ['gate-in', id],
    () => {
      return gateInsService.get(id).then((res) => res.data.data);
    },
    { initialData: { data: {} } },
  );
