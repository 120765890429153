import moment from 'moment';
import { showToRoles } from '../../utils';
import { User } from '../../types';

export const isShowDots = (row: any, user: User) => {
  if (showToRoles('admin', user) && (row.status === 'EXPIRED' || row.status === 'ACTIVE'))
    return true;

  return (
    showToRoles('client', user) &&
    row.status === 'ACTIVE' &&
    moment(row.validTo).isAfter(moment().add(12, 'hours'))
  );
};
