import { useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import styled from 'styled-components';
import validator from 'validator';
import {
  Typography,
  Button,
  Input,
  InputPhoneNumber,
  enqueueSnackbar,
  SearchableSelect,
  BorderLine,
} from '../../design-system';
import { DashboardHeader, DashboardForm, DashboardFormTwoColumn } from '../dashboard';
import { useCreateCompanyMutation } from '../../hooks';
import { getPhoneNumberValue, getNormalFormProps } from '../../utils';
import { belongsToOptions } from '../../services';
import { INPUT_ERRORS, REGEX, MESSAGES, countries, cities } from '../../const';

let formProps: any = {};

export const CompanyPage = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;
`;

const getSubmitProps = (belongsTo: string) => {
  const { validators }: any = formProps;
  if ([...Object.values(validators).map((val) => !val)].some((val) => !val)) {
    return {};
  }

  formProps.phoneNumber = getPhoneNumberValue(formProps.phoneNumber);
  formProps.companyPhoneNumber = getPhoneNumberValue(formProps.companyPhoneNumber);
  delete formProps.isSubmitClicked;

  return {
    submitProps: { ...getNormalFormProps(formProps), belongsTo },
    resolveFn: formProps.resolveFn,
  };
};

export const CompanyHeader = ({ data = {}, belongsTo }: any) => {
  const { mutate } = useCreateCompanyMutation();

  const handleSubmitClick = async () => {
    formProps.onSubmitClick();
    const { submitProps, resolveFn } = getSubmitProps(belongsTo);
    if (!submitProps) return;

    mutate(submitProps, {
      onSuccess: () => {
        enqueueSnackbar(MESSAGES.BELONGS_TO.SUCCESS.CREATED, 'success');
        resolveFn();
      },
      onError: () => enqueueSnackbar(MESSAGES.ERROR_BOUNDARY.DEFAULT, 'error'),
    });
  };

  let title = data.id ? `Edit Yard Company: ${data.companyLegalName}` : 'Add Yard Company';
  if (belongsTo === 'YARD') title = data.id ? `Edit Yard: ${data.companyLegalName}` : 'Add Yard';
  if (belongsTo === 'CLIENT')
    title = data.id ? `Edit Client: ${data.companyLegalName}` : 'Add Client';

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        {title}
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
      <Button size={'medium'} onClick={handleSubmitClick}>
        Submit
      </Button>
    </DashboardHeader>
  );
};

export const CompanyForm = ({ data = {}, belongsTo }: any) => {
  const [companyLegalName, setCompanyLegalName] = useState(data.companyLegalName || '');
  const [code, setCode] = useState(data.code || '');
  const [streetAddress, setStreetAddress] = useState(data.streetAddress || '');
  const [city, setCity] = useState(data.city || '');
  const [zipCode, setZipCode] = useState(data.zipCode || '');
  const [country, setCountry] = useState(data.country || '');
  const [contactPersonFirstName, setContactPersonFirstName] = useState(
    data.contactPersonFirstName || '',
  );
  const [contactPersonLastName, setContactPersonLastName] = useState(
    data.contactPersonLastName || '',
  );
  const [email, setEmail] = useState(data.email || '');
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber ? data.phoneNumber : undefined);
  const [name, setName] = useState(data.name || '');
  const [companyEmail, setCompanyEmail] = useState(data.companyEmail || '');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(
    data.companyPhoneNumber ? data.phoneNumber : undefined,
  );

  const [yardCompanyId, setYardCompanyId] = useState('');
  const [yardCompanyName, setYardCompanyName] = useState('');

  const [updateKey, setUpdate] = useState<any>(Math.random());

  const validators = {
    companyLegalName:
      belongsTo !== 'YARD'
        ? companyLegalName
          ? !validator.isLength(companyLegalName, { min: 4, max: 100 })
            ? INPUT_ERRORS.NOT_VALID.COMPANY_LEGAL_NAME_LENGTH
            : !REGEX.COMPANY_NAME.test(companyLegalName)
            ? INPUT_ERRORS.NOT_VALID.COMPANY_LEGAL_NAME
            : ''
          : true
        : '',
    code:
      belongsTo === 'CLIENT'
        ? code
          ? !validator.isLength(code, { min: 4, max: 8 })
            ? INPUT_ERRORS.NOT_VALID.CODE_LENGTH
            : !REGEX.CODE.test(code)
            ? INPUT_ERRORS.NOT_VALID.CODE
            : ''
          : true
        : '',
    streetAddress: streetAddress
      ? !validator.isLength(streetAddress, { min: 5, max: 100 })
        ? INPUT_ERRORS.NOT_VALID.STREET_ADDRESS_LENGTH
        : !REGEX.STREET_ADDRESS.test(streetAddress)
        ? INPUT_ERRORS.NOT_VALID.STREET_ADDRESS
        : ''
      : true,
    city: city
      ? !validator.isLength(city, { min: 1, max: 85 })
        ? INPUT_ERRORS.NOT_VALID.CITY_LENGTH
        : !REGEX.CITY.test(city)
        ? INPUT_ERRORS.NOT_VALID.CITY
        : ''
      : true,
    zipCode: zipCode
      ? !validator.isLength(zipCode, { min: 2, max: 12 })
        ? INPUT_ERRORS.NOT_VALID.ZIP_CODE_LENGTH
        : !REGEX.ZIP_CODE.test(zipCode)
        ? INPUT_ERRORS.NOT_VALID.ZIP_CODE
        : ''
      : '',
    country: country
      ? !validator.isLength(country, { min: 1, max: 85 })
        ? INPUT_ERRORS.NOT_VALID.COUNTRY_LENGTH
        : !REGEX.COUNTRY.test(country)
        ? INPUT_ERRORS.NOT_VALID.COUNTRY
        : ''
      : true,
    contactPersonFirstName:
      belongsTo !== 'YARD'
        ? contactPersonFirstName
          ? !validator.isLength(contactPersonFirstName, { min: 3 })
            ? INPUT_ERRORS.NOT_VALID.CONTACT_PERSON_FIRST_NAME_LENGTH
            : !REGEX.USER_NAME.test(contactPersonFirstName)
            ? INPUT_ERRORS.NOT_VALID.CONTACT_PERSON_FIRST_NAME
            : ''
          : ''
        : '',
    contactPersonLastName:
      belongsTo !== 'YARD'
        ? contactPersonLastName
          ? !validator.isLength(contactPersonLastName, { min: 3 })
            ? INPUT_ERRORS.NOT_VALID.CONTACT_PERSON_LAST_NAME_LENGTH
            : !REGEX.USER_NAME.test(contactPersonLastName)
            ? INPUT_ERRORS.NOT_VALID.CONTACT_PERSON_LAST_NAME
            : ''
          : ''
        : '',
    email:
      belongsTo !== 'YARD'
        ? email
          ? !validator.isLength(email, { min: 6, max: 50 })
            ? INPUT_ERRORS.NOT_VALID.EMAIL_LENGTH
            : !REGEX.EMAIL.test(email)
            ? INPUT_ERRORS.NOT_VALID.EMAIL
            : ''
          : ''
        : '',
    phoneNumber: phoneNumber
      ? !isValidPhoneNumber(`+${getPhoneNumberValue(phoneNumber)}`)
        ? belongsTo !== 'YARD'
          ? INPUT_ERRORS.NOT_VALID.CONTACT_PERSON_PHONE_NUMBER
          : INPUT_ERRORS.NOT_VALID.PHONE_NUMBER
        : ''
      : '',
    name:
      belongsTo === 'YARD'
        ? name
          ? !validator.isLength(name, { min: 6, max: 50 })
            ? INPUT_ERRORS.NOT_VALID.YARD_NAME_LENGTH
            : !REGEX.COMPANY_NAME.test(name)
            ? INPUT_ERRORS.NOT_VALID.YARD_NAME
            : ''
          : true
        : '',
    companyEmail:
      belongsTo !== 'YARD'
        ? companyEmail
          ? !validator.isLength(companyEmail, { min: 6, max: 50 })
            ? INPUT_ERRORS.NOT_VALID.COMPANY_EMAIL_LENGTH
            : !REGEX.EMAIL.test(companyEmail)
            ? INPUT_ERRORS.NOT_VALID.COMPANY_EMAIL
            : ''
          : true
        : '',
    companyPhoneNumber:
      belongsTo !== 'YARD'
        ? companyPhoneNumber
          ? !isValidPhoneNumber(`+${getPhoneNumberValue(companyPhoneNumber)}`)
            ? INPUT_ERRORS.NOT_VALID.COMPANY_PHONE_NUMBER
            : ''
          : true
        : '',
    yardCompanyId: belongsTo === 'YARD' ? (!yardCompanyId ? true : '') : '',
  };

  useEffect(() => {
    if (!formProps) return;

    formProps = { isSubmitClicked: false };
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    formProps = {
      ...formProps,
      companyLegalName,
      streetAddress,
      city,
      zipCode,
      country,
      contactPersonFirstName,
      contactPersonLastName,
      email,
      phoneNumber,
      name,
      companyEmail,
      companyPhoneNumber,
      yardCompanyId,
      validators,
      onSubmitClick: () => {
        formProps.isSubmitClicked = true;
        setUpdate(Math.random());
      },
      resolveFn: () => {
        setCompanyLegalName('');
        if (belongsTo === 'CLIENT') setCode('');
        setStreetAddress('');
        setCity('');
        setZipCode('');
        setCountry('');
        setContactPersonFirstName('');
        setContactPersonLastName('');
        setEmail('');
        setPhoneNumber('');
        setName('');
        setCompanyEmail('');
        setCompanyPhoneNumber('');
        setYardCompanyId('');
        setYardCompanyName('');
        setUpdate(Math.random());
      },
    };
    if (belongsTo === 'CLIENT') formProps.code = code;
  }, [
    companyLegalName,
    code,
    streetAddress,
    city,
    zipCode,
    country,
    contactPersonFirstName,
    contactPersonLastName,
    email,
    phoneNumber,
    name,
    companyEmail,
    companyPhoneNumber,
    yardCompanyId,
  ]);

  const handleSelectCountry = (country: any) => {
    setCity('');
    setCountry(country);
  };

  return (
    <DashboardForm key={updateKey} paperSize={'full-size'}>
      {belongsTo === 'YARD' && (
        <SearchableSelect
          selectedOptionLabel={yardCompanyName}
          label={'Yard Company*'}
          placeholder={`Select Yard Company`}
          onSelect={(obj: any) => {
            setYardCompanyName(obj.label);
            setYardCompanyId(obj.id);
          }}
          queryFn={(inputValue: any) => belongsToOptions.YardCompany(inputValue, true)}
          queryKey={'yard-companies-short'}
          error={
            (formProps.isSubmitClicked && !yardCompanyId) ||
            (!!yardCompanyId && !!validators.yardCompanyId)
          }
          helperText={
            formProps.isSubmitClicked && !yardCompanyId ? INPUT_ERRORS.REQUIRED.YARD_COMPANY : ''
          }
          selectedAreasEqaul={{ key: 'id', keys: [yardCompanyId] }}
        />
      )}
      {belongsTo === 'YARD_COMPANIES' && (
        <Input
          defaultValue={companyLegalName}
          onChange={(e: any) => setCompanyLegalName(e.target.value)}
          label={belongsTo !== 'YARD' ? 'Company Legal Name*' : 'Yard Name*'}
          placeholder={belongsTo !== 'YARD' ? 'Company Legal Name' : 'Yard Name'}
          type={'text'}
          data-size={'medium'}
          error={
            (formProps.isSubmitClicked && !companyLegalName) ||
            (!!companyLegalName && validators.companyLegalName)
          }
          helperText={
            formProps.isSubmitClicked && !companyLegalName
              ? INPUT_ERRORS.REQUIRED.COMPANY_LEGAL_NAME
              : validators.companyLegalName
          }
        />
      )}
      <DashboardFormTwoColumn>
        {belongsTo === 'YARD' && (
          <Input
            defaultValue={name}
            onChange={(e: any) => setName(e.target.value)}
            label={'Yard Name*'}
            placeholder={'Yard Name'}
            type={'text'}
            data-size={'medium'}
            error={(formProps.isSubmitClicked && !name) || (!!name && validators.name)}
            helperText={
              formProps.isSubmitClicked && !name ? INPUT_ERRORS.REQUIRED.YARD_NAME : validators.name
            }
          />
        )}
        {belongsTo === 'CLIENT' && (
          <Input
            defaultValue={companyLegalName}
            onChange={(e: any) => setCompanyLegalName(e.target.value)}
            label={belongsTo !== 'YARD' ? 'Company Legal Name*' : 'Yard Name*'}
            placeholder={belongsTo !== 'YARD' ? 'Company Legal Name' : 'Yard Name'}
            type={'text'}
            data-size={'medium'}
            error={
              (formProps.isSubmitClicked && !companyLegalName) ||
              (!!companyLegalName && validators.companyLegalName)
            }
            helperText={
              formProps.isSubmitClicked && !companyLegalName
                ? INPUT_ERRORS.REQUIRED.COMPANY_LEGAL_NAME
                : validators.companyLegalName
            }
          />
        )}
        {belongsTo === 'CLIENT' && (
          <Input
            defaultValue={code}
            onChange={(e: any) => setCode(e.target.value)}
            label={'Code*'}
            placeholder={'Code'}
            type={'text'}
            data-size={'medium'}
            error={(formProps.isSubmitClicked && !code) || (!!code && validators.code)}
            helperText={
              formProps.isSubmitClicked && !code ? INPUT_ERRORS.REQUIRED.CODE : validators.code
            }
          />
        )}
        {belongsTo !== 'YARD' && (
          <>
            <Input
              defaultValue={country}
              onChange={(e: any) => setCountry(e.target.value)}
              label={'Country*'}
              placeholder={'Country'}
              type={'text'}
              data-size={'medium'}
              error={(formProps.isSubmitClicked && !country) || (!!country && validators.country)}
              helperText={
                formProps.isSubmitClicked && !country
                  ? INPUT_ERRORS.REQUIRED.COUNTRY
                  : validators.country
              }
            />
            <Input
              defaultValue={city}
              onChange={(e: any) => setCity(e.target.value)}
              label={'City*'}
              placeholder={'City'}
              type={'text'}
              data-size={'medium'}
              error={(formProps.isSubmitClicked && !city) || (!!city && validators.city)}
              helperText={
                formProps.isSubmitClicked && !city ? INPUT_ERRORS.REQUIRED.CITY : validators.city
              }
            />
          </>
        )}
        {belongsTo === 'YARD' && (
          <>
            <SearchableSelect
              selectedOptionLabel={country}
              label={'Country*'}
              placeholder={'Select Country'}
              onSelect={handleSelectCountry}
              options={countries}
              error={(formProps.isSubmitClicked && !country) || (!!country && !!validators.country)}
              helperText={
                formProps.isSubmitClicked && !country
                  ? INPUT_ERRORS.REQUIRED.COUNTRY
                  : typeof validators.country === 'string'
                  ? validators.country
                  : ''
              }
            />
            <SearchableSelect
              selectedOptionLabel={city}
              label={'City*'}
              placeholder={'Select City'}
              onSelect={setCity}
              options={cities[country]}
              disabled={!country}
              error={(formProps.isSubmitClicked && !city) || (!!city && !!validators.city)}
              helperText={
                formProps.isSubmitClicked && !city
                  ? INPUT_ERRORS.REQUIRED.CITY
                  : typeof validators.city === 'string'
                  ? validators.city
                  : ''
              }
            />
          </>
        )}
        <Input
          defaultValue={streetAddress}
          onChange={(e: any) => setStreetAddress(e.target.value)}
          label={'Street Address*'}
          placeholder={'Street Address'}
          type={'text'}
          data-size={'medium'}
          error={
            (formProps.isSubmitClicked && !streetAddress) ||
            (!!streetAddress && validators.streetAddress)
          }
          helperText={
            formProps.isSubmitClicked && !streetAddress
              ? INPUT_ERRORS.REQUIRED.STREET_ADDRESS
              : validators.streetAddress
          }
        />
        <Input
          defaultValue={zipCode}
          onChange={(e: any) => setZipCode(e.target.value)}
          label={'Zip Code'}
          placeholder={'Zip Code'}
          type={'text'}
          data-size={'medium'}
          error={!!zipCode && !!validators.zipCode}
          helperText={validators.zipCode}
        />
        {belongsTo !== 'YARD' && (
          <Input
            defaultValue={companyEmail}
            onChange={(e: any) => setCompanyEmail(e.target.value)}
            label={'Company Email*'}
            placeholder={'Company Email'}
            type={'text'}
            data-size={'medium'}
            withoutSpace={true}
            error={
              (formProps.isSubmitClicked && !companyEmail) ||
              (!!companyEmail && validators.companyEmail)
            }
            helperText={
              formProps.isSubmitClicked && !companyEmail
                ? INPUT_ERRORS.REQUIRED.COMPANY_EMAIL
                : validators.companyEmail
            }
          />
        )}
        {belongsTo !== 'YARD' && (
          <InputPhoneNumber
            value={companyPhoneNumber}
            onChange={(e: any) => setCompanyPhoneNumber(e.target.value)}
            label={'Company Phone Number*'}
            placeholder={'Company Phone Number'}
            data-size={'medium'}
            error={
              (formProps.isSubmitClicked && !companyPhoneNumber) ||
              (!!companyPhoneNumber && validators.companyPhoneNumber)
            }
            helperText={
              formProps.isSubmitClicked && !companyPhoneNumber
                ? INPUT_ERRORS.REQUIRED.COMPANY_PHONE_NUMBER
                : validators.companyPhoneNumber
            }
          />
        )}
        {belongsTo !== 'YARD' && <BorderLine space={'dashboard-form'} gridColumn={'1 / 3'} />}
        {belongsTo !== 'YARD' && (
          <Input
            defaultValue={contactPersonFirstName}
            onChange={(e: any) => setContactPersonFirstName(e.target.value)}
            label={'Contact Person First Name'}
            placeholder={'Contact Person First Name'}
            type={'text'}
            data-size={'medium'}
            error={!!contactPersonFirstName && !!validators.contactPersonFirstName}
            helperText={validators.contactPersonFirstName}
          />
        )}
        {belongsTo !== 'YARD' && (
          <Input
            defaultValue={contactPersonLastName}
            onChange={(e: any) => setContactPersonLastName(e.target.value)}
            label={'Contact Person Last Name'}
            placeholder={'Contact Person Last Name'}
            type={'text'}
            data-size={'medium'}
            error={!!contactPersonLastName && !!validators.contactPersonLastName}
            helperText={validators.contactPersonLastName}
          />
        )}
        {belongsTo !== 'YARD' && (
          <Input
            defaultValue={email}
            onChange={(e: any) => setEmail(e.target.value)}
            label={'Contact Person Email'}
            placeholder={'Contact Person Email'}
            type={'text'}
            data-size={'medium'}
            withoutSpace={true}
            error={!!email && !!validators.email}
            helperText={validators.email}
          />
        )}
        <InputPhoneNumber
          value={phoneNumber}
          onChange={(e: any) => setPhoneNumber(e.target.value)}
          label={`${belongsTo !== 'YARD' ? 'Contact Person ' : ''}Phone Number`}
          placeholder={`${belongsTo !== 'YARD' ? 'Contact Person ' : ''}Phone Number`}
          data-size={'medium'}
          error={!!phoneNumber && !!validators.phoneNumber}
          helperText={validators.phoneNumber}
        />
      </DashboardFormTwoColumn>
    </DashboardForm>
  );
};
