import styled, { css } from 'styled-components';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import { Typography } from '../typography';

interface TableHeadCellBaseProps {
  isActiveColumnIdx?: boolean;
  sortDirection: 'asc' | 'desc';
}

const TableHeadCellBase = styled.div<TableHeadCellBaseProps>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: var(--spacing-2);
  align-items: center;

  cursor: pointer;
  user-select: none;

  &:hover svg {
    opacity: 1;
  }

  svg {
    font-size: 2rem;
    transition: 0.3s;
    opacity: 0;
  }

  ${(props) =>
    props.isActiveColumnIdx &&
    css`
      svg {
        opacity: 1;
      }
    `}

  ${(props) =>
    props.isActiveColumnIdx &&
    props.sortDirection === 'asc' &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

interface TableHeadCellProps extends Partial<Omit<TableHeadCellBaseProps, 'isActiveColumnIdx'>> {
  sortField?: string;
  columnIdx?: string;
  handleSort?: (columnIdx: string) => void;
  children: any;
  [key: string]: any;
}

export const TableHeadCell: React.FC<TableHeadCellProps> = ({
  sortField,
  columnIdx,
  sortDirection,
  handleSort,
  children,
  ...props
}: TableHeadCellProps) => {
  if (!columnIdx || !sortDirection || !handleSort)
    return <TableCell {...props}>{children}</TableCell>;

  return (
    <TableCell {...props}>
      <TableHeadCellBase
        isActiveColumnIdx={sortField === columnIdx}
        sortDirection={sortDirection}
        onClick={() => handleSort(columnIdx)}
      >
        <Typography variant={'textS'} color={'--gray-80'}>
          {children}
        </Typography>
        <ExpandMoreIcon />
      </TableHeadCellBase>
    </TableCell>
  );
};
