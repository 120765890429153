import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableDots,
  Pagination,
  Button,
  enqueueSnackbar,
  assignYardsAndClientsModal,
} from '../../../design-system';
import { DashboardHeader } from '../../dashboard';
import { CompanyFilters } from '../CompanyFilters';
import { useStore } from '../../../context';
import {
  useStatesWithFilterCounter,
  usePaginationState,
  useYardsQuery,
  useAssignClientsMutation,
} from '../../../hooks';
import { showToRoles, formatPhoneNumber } from '../../../utils';
import { TABLE, MESSAGES } from '../../../const';

interface YardsProps {
  innerScroll?: boolean;
}

export const YardsPage = styled.div<YardsProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const YardsHeader = ({ totalCount }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Yards: {totalCount}
      </Typography>
      <Button size={'medium'} to={'/dashboard/yards/add'}>
        Add Yard
      </Button>
    </DashboardHeader>
  );
};

export const YardsBody = ({ setTotalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const { mutate } = useAssignClientsMutation();
  const [openDotsMenuIndex, setOpenMenuDotsSelect] = useState(null);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter({
    companyLegalName: 'All',
    yardCompanyId: 'All',
    name: '',
    streetAddress: '',
    city: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
  });

  const { data, isSuccess, isFetching } = useYardsQuery({
    ...filteredStates,
    take,
    skip,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: any) => {
    if (openDotsMenuIndex !== i) return setOpenMenuDotsSelect(i);

    setOpenMenuDotsSelect(null);
  };

  const handleDotsOptionSelect = async (action: any, id: any, yardName: string) => {
    if (
      action !== 'add-clients' &&
      action !== 'reactivate-clients' &&
      action !== 'deactivate-clients'
    )
      return;

    Object.keys(assignYardsAndClientsModal).forEach((key) => {
      if (key !== 'open') delete assignYardsAndClientsModal[key];
    });
    assignYardsAndClientsModal.id = id;
    assignYardsAndClientsModal.assignType = 'clients';
    assignYardsAndClientsModal.action = action;
    assignYardsAndClientsModal.fc = (props: any) =>
      mutate(
        { ...props, yardName, action },
        {
          onSuccess: () => {
            if (action === 'add-clients')
              enqueueSnackbar(MESSAGES.YARD.SUCCESS.ASSIGN_CLIENTS, 'success');
            if (action === 'reactivate-clients')
              enqueueSnackbar(MESSAGES.YARD.SUCCESS.REACTIVATED_CLIENTS, 'success');
            if (action === 'deactivate-clients')
              enqueueSnackbar(MESSAGES.YARD.SUCCESS.DEACTIVATED_CLIENTS, 'success');
          },
          onError: () => {
            if (action === 'add-clients')
              enqueueSnackbar(MESSAGES.YARD.ERROR.CLIENTS_NOT_ASSIGNED, 'error');
            if (action === 'reactivate-clients')
              enqueueSnackbar(MESSAGES.YARD.ERROR.CLIENTS_NOT_REACTIVATED, 'error');
            if (action === 'deactivate-clients')
              enqueueSnackbar(MESSAGES.YARD.ERROR.CLIENTS_NOT_DEACTIVATED, 'error');
          },
        },
      );
    assignYardsAndClientsModal.open();
  };

  return (
    <TableWrapper columns={[7]}>
      <CompanyFilters setStates={setStates} {...states} />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Actions</TableHeadCell>
            {showToRoles(['SUPER_ADMIN'], user) && (
              <TableHeadCell align={'left'}>Yard Company</TableHeadCell>
            )}
            <TableHeadCell align={'left'}>Yard Name</TableHeadCell>
            <TableHeadCell align={'left'}>Country</TableHeadCell>
            <TableHeadCell align={'left'}>City</TableHeadCell>
            <TableHeadCell align={'left'}>Street Address</TableHeadCell>
            <TableHeadCell align={'left'}>Zip Code</TableHeadCell>
            <TableHeadCell align={'left'}>Phone Number</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={
          isSuccess &&
          data.data.map((row: any, i: any) => (
            <TableRow key={row.id + i}>
              <TableIconCell component={'th'} align={'left'}>
                <TableDots
                  open={openDotsMenuIndex === i}
                  onToggle={() => handleDotsToggle(i)}
                  options={[
                    { label: 'Add Clients', value: 'add-clients' },
                    { label: 'Reactivate Clients', value: 'reactivate-clients' },
                    { label: 'Deactivate Clients', value: 'deactivate-clients' },
                  ]}
                  onSelect={(val: any) => {
                    handleDotsOptionSelect(val, row.id, row.name);
                  }}
                />
              </TableIconCell>
              <TableCell align={'left'}>{row.yardCompany.legalName}</TableCell>
              <TableCell align={'left'}>{row.name}</TableCell>
              <TableCell align={'left'}>{row.address.country}</TableCell>
              <TableCell align={'left'}>{row.address.city}</TableCell>
              <TableCell align={'left'}>{row.address.streetAddress}</TableCell>
              <TableCell align={'left'}>{row.address.zipCode}</TableCell>
              <TableCell align={'left'}>{formatPhoneNumber(row.phoneNumber)}</TableCell>
            </TableRow>
          ))
        }
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
