import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import { useStore } from '../../../context';
import { Actions } from '../../../store';
import { authService, removeCache } from '../../../services';
import { signinRolesLabel } from '../../../const';

const getNormalUser = (user: any) => {
  if (user.yardCompanyEmployee) {
    user.type = signinRolesLabel[user.yardCompanyEmployee.role];
  }
  if (user.yardEmployee) {
    user.type = signinRolesLabel[user.yardEmployee.role];
  }
  if (user.clientEmployee) {
    user.type = signinRolesLabel[user.clientEmployee.role];
  }

  return user;
};

export const useSigninMutation = () => {
  const { dispatch } = useStore();
  const jwt = Cookies.get('jwt');
  let userBase: any = Cookies.get('user');
  userBase = !userBase ? null : JSON.parse(userBase);
  let email: any;

  return useMutation(
    (props: any) => {
      if (jwt && userBase) return Promise.resolve(null);

      email = props.email;
      return authService.signin(props);
    },
    {
      onSuccess: (res: any) => {
        if (res && (!jwt || !userBase)) {
          userBase = getNormalUser({
            ...res.data.data,
            email,
            type: res.data.data.belongsTo,
          });

          Cookies.set('jwt', res.data.authToken, { expires: 3 });
          Cookies.set('user', JSON.stringify(userBase), { expires: 3 });
        }

        dispatch({ type: Actions.SET_USER_DATA, payload: userBase });

        return res;
      },
      onError: () => {
        Cookies.remove('jwt');
        Cookies.remove('user');
      },
    },
  );
};

export const useForgotPasswordMutation = () => {
  return useMutation(authService.forgotPassword);
};

export const useCreateNewPasswordMutation = () => {
  return useMutation(authService.createNewPassword);
};

export const useUpdatePasswordMutation = () => {
  return useMutation(authService.updatePassword);
};

export const useLogoutMutation = () => {
  return useMutation(authService.logout, {
    onSuccess: () => {
      Cookies.remove('jwt');
      Cookies.remove('user');
      removeCache('*');
    },
  });
};
