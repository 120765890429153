import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '../typography';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 'var(--font-size-2)',
          fontWeight: 'var(--font-weight-400)',
          fontFamily: 'Inter',
          lineHeight: 'var(--line-height-2)',
          whiteSpaceCollapse: 'break-spaces',
          backgroundColor: 'var(--gray-90)',

          padding: 'var(--spacing-4)',
        },
        arrow: {
          color: 'var(--gray-90)',
        },
      },
    },
  },
});

export const Tooltip: React.FC<TooltipProps> = ({ children, ...props }: TooltipProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiTooltip {...props}>{children}</MuiTooltip>
    </ThemeProvider>
  );
};

interface TooltipWithRulesProps extends Omit<TooltipProps, 'title'> {
  rules: { text: string; isValid?: boolean }[];
  title?: string;
}

export const TooltipWithRules: React.FC<TooltipWithRulesProps> = ({
  rules,
  title,
  children,
  ...props
}: TooltipWithRulesProps) => {
  return (
    <Tooltip
      title={
        title || (
          <>
            {rules.map((rule, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <br />}
                <Typography variant={'textS'} color={rule.isValid ? '--green-100' : '--white'}>
                  {rule.text}
                </Typography>
              </React.Fragment>
            ))}
          </>
        )
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
