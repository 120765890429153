import { AuthWrapper } from '../AuthComponents';
import {
  ForgotPasswordHeader,
  ForgotPasswordForm,
  ForgotPasswordFooter,
} from './ForgotPasswordComponents';

export const ForgotPassword = () => {
  return (
    <AuthWrapper>
      <ForgotPasswordHeader />
      <ForgotPasswordForm />
      <ForgotPasswordFooter />
    </AuthWrapper>
  );
};
