import moment, { Moment } from 'moment';
import { User } from '../types';
import { showToRoles } from './show-to-roles';

const formats = {
  default: 'MMM D, YYYY, h:mm A',
  client: 'MMM D, YYYY',
};

export const momentTextFormat = (timeNumber: number | string | Date, user: User) => {
  let timeFormat = formats.default;
  if (showToRoles('client', user)) timeFormat = formats.client;

  return moment(timeNumber).format(timeFormat);
};

export const momentDiffDays = (aTime: any, bTime: any) => {
  let start = aTime;
  let end = bTime;

  if (!aTime) return 0;

  if (new Date(start).getTime() > new Date(end).getTime()) {
    start = bTime;
    end = aTime;
  }

  return moment(end).diff(moment(start), 'days') + 1;
};

export const isValidDate = (value?: Moment, minDate?: Moment, maxDate?: Moment) => {
  if (
    value &&
    minDate &&
    moment(value)
      .set({ hour: 12 })
      ?.isBefore(moment(minDate).set({ hour: 1 }))
  )
    return false;
  if (
    value &&
    maxDate &&
    moment(value)
      .set({ hour: 12 })
      ?.isAfter(moment(maxDate).set({ hour: 23 }))
  )
    return false;

  return true;
};

export const toIsoDate = (time: Date, withTimeZone?: boolean) => {
  if (!withTimeZone) return moment(time).toISOString();
  
  return moment(time).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
};
