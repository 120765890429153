import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator';
import { Typography, Link, InputPassword, Button, enqueueSnackbar } from '../../../design-system';
import {
  AuthHeaderBase,
  AuthHeaderSubTitle,
  AuthFormBase,
  AuthFooterBase,
  AuthFooterBorderLine,
  AuthFooterSocials,
} from '../AuthComponents';
import { useCreateNewPasswordMutation } from '../../../hooks';
import { INPUT_ERRORS, REGEX } from '../../../const';

export const CreateNewPasswordHeader = () => {
  return (
    <AuthHeaderBase>
      <Typography variant={'h4'} align={'center'} weight={'bold'}>
        Create New Password
      </Typography>
      <AuthHeaderSubTitle>
        <Typography variant={'textM'} color={'--gray-80'}>
          Write a New password for your Account
        </Typography>
      </AuthHeaderSubTitle>
    </AuthHeaderBase>
  );
};

export const CreateNewPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { mutate } = useCreateNewPasswordMutation();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const validators = {
    password: password
      ? !validator.isLength(password, { min: 8, max: 16 })
        ? INPUT_ERRORS.NOT_VALID.NEW_PASSWORD_LENGTH
        : !REGEX.PASSWORD.test(password)
        ? INPUT_ERRORS.NOT_VALID.NEW_PASSWORD
        : ''
      : isSubmitClicked
      ? INPUT_ERRORS.REQUIRED.NEW_PASSWORD
      : true,
    passwordConfirm: passwordConfirm
      ? !validator.equals(passwordConfirm, password)
        ? INPUT_ERRORS.NOT_VALID.NEW_PASSWORD_CONFIRM
        : ''
      : isSubmitClicked
      ? INPUT_ERRORS.REQUIRED.NEW_PASSWORD_CONFIRM
      : true,
  };

  const handleSubmitClick = async () => {
    if (!Object.values(validators).every((isError) => !isError)) {
      if (!isSubmitClicked) setIsSubmitClicked(true);
      return;
    }

    await mutate(
      {
        password,
        passwordConfirmation: passwordConfirm,
        token: searchParams.get('passwordResetToken') || '',
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar(res.data.message, 'success');
          navigate('/signin');
        },
        onError: (err: any) => {
          enqueueSnackbar(err.response.data.message, 'error');
        },
      },
    );
  };

  return (
    <AuthFormBase>
      <InputPassword
        defaultValue={password}
        onChange={(e) => setPassword(e.target.value)}
        label={'Password'}
        placeholder={'New Password'}
        data-size={'large'}
        error={(isSubmitClicked && !password) || (!!password && validators.password)}
        helperText={validators.password}
      />
      <InputPassword
        defaultValue={passwordConfirm}
        onChange={(e: any) => setPasswordConfirm(e.target.value)}
        label={'Password Confirmation'}
        placeholder={'Enter Password Confirmation'}
        data-size={'large'}
        error={
          (isSubmitClicked && !passwordConfirm) || (!!passwordConfirm && validators.passwordConfirm)
        }
        helperText={validators.passwordConfirm}
      />
      <Button data-color={'primary'} size={'large'} onClick={handleSubmitClick}>
        Reset Password
      </Button>
    </AuthFormBase>
  );
};

export const CreateNewPasswordFooter = () => {
  return (
    <AuthFooterBase>
      <AuthFooterBorderLine />
      <AuthFooterSocials>
        <Link to={'/signin'}>
          <Button data-color={'white'} size={'large'}>
            Back to Sign In
          </Button>
        </Link>
      </AuthFooterSocials>
    </AuthFooterBase>
  );
};
