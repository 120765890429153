import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const avatarTheme = createTheme({
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          '&.MuiAvatar-root': {
            border: '0.2rem solid var(--white)',
            boxSizing: 'border-box',
            fontFamily: 'Inter',
          },

          '&[data-size=smallest]': {
            width: '2.4rem',
            height: '2.4rem',
          },
          '&[data-size=small]': {
            width: '3.6rem',
            height: '3.6rem',
            fontSize: '1.8rem',
          },
          '&[data-size=medium]': {
            width: '4.8rem',
            height: '4.8rem',
          },
          '&[data-size=large]': {
            width: '7.2rem',
            height: '7.2rem',
          },
          '&[data-size=big]': {
            width: '9.6rem',
            height: '9.6rem',
          },
          '&[data-size=biggest]': {
            width: '12rem',
            height: '12rem',
          },

          '&[data-button=true]': {
            cursor: 'pointer',
            transition: 'opacity 0.3s',

            '&:hover': {
              opacity: '0.7',
            },
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '&[data-size=smallest] .MuiAvatar-root': {
            width: '2.4rem',
            height: '2.4rem',
          },
          '&[data-size=small] .MuiAvatar-root': {
            width: '3.6rem',
            height: '3.6rem',
            fontSize: '1.8rem',
          },
          '&[data-size=medium] .MuiAvatar-root': {
            width: '4.8rem',
            height: '4.8rem',
          },
          '&[data-size=large] .MuiAvatar-root': {
            width: '7.2rem',
            height: '7.2rem',
          },
          '&[data-size=big] .MuiAvatar-root': {
            width: '9.6rem',
            height: '9.6rem',
          },
          '&[data-size=biggest] .MuiAvatar-root': {
            width: '12rem',
            height: '12rem',
          },
        },
      },
    },
  },
});

export interface AvatarProps extends MuiAvatarProps {
  'data-size'?: 'smallest' | 'small' | 'medium' | 'large' | 'big' | 'biggest';
  button?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({ button, ...props }: AvatarProps) => {
  return (
    <ThemeProvider theme={avatarTheme}>
      <MuiAvatar data-size={props['data-size'] || 'small'} data-button={button} {...props} />
    </ThemeProvider>
  );
};
