import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '../../const';

type ColorTypes = 'blue' | 'grey' | 'green' | 'red' | 'yellow';
const themeColors = {
  blue: { main: colors['--primary-100'], contrastText: colors['--white'] },
  grey: { main: colors['--gray-80'], contrastText: colors['--white'] },
  green: { main: colors['--green-90'], contrastText: colors['--white'] },
  red: { main: colors['--red-80'], contrastText: colors['--white'] },
  yellow: { main: colors['--yellow-100'], contrastText: colors['--white'] },
};

const theme = (color: ColorTypes = 'blue') =>
  createTheme({
    palette: {
      primary: themeColors[color],
    },
    components: {
      MuiBadge: {
        styleOverrides: {
          root: {
            '&[data-size=small] > .MuiBadge-badge': {
              minWidth: '1.6rem',
              height: '1.6rem',
              lineHeight: 'var(--line-height-1)',
              fontSize: 'var(--font-size-1)',
              fontWeight: 'var(--font-weight-500)',
              borderRadius: '0.8rem',
            },
            '&[data-size=medium] > .MuiBadge-badge': {
              minWidth: '2rem',
              height: '2rem',
              lineHeight: 'var(--line-height-2)',
              fontSize: 'var(--font-size-2)',
              fontWeight: 'var(--font-weight-500)',
              borderRadius: '1rem',
            },
            '&[data-size=large] > .MuiBadge-badge': {
              minWidth: '2.4rem',
              height: '2.4rem',
              lineHeight: 'var(--line-height-2)',
              fontSize: 'var(--font-size-2)',
              fontWeight: 'var(--font-weight-500)',
              borderRadius: '1.2rem',
            },
          },
          badge: {
            fontFamily: 'Inter',
            padding: '0 0.4rem',
            minWidth: 0,
          },
        },
      },
    },
  });

interface BadgeProps extends MuiBadgeProps {
  'data-color'?: ColorTypes;
  'data-size'?: 'small' | 'medium' | 'large';
}

export const Badge: React.FC<BadgeProps> = ({ ...props }: BadgeProps) => {
  return (
    <ThemeProvider theme={theme(props['data-color'])}>
      <MuiBadge color={'primary'} data-size={props['data-size'] || 'medium'} {...props} />
    </ThemeProvider>
  );
};
