import { useMutation } from 'react-query';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';
import { employeesService, removeCache, UpdateStatusProps } from '../../../services';

export const useCreateEmployeeMutation = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return useMutation(({ companyType, companyId, ...props }: any) => {
    removeCache(['yard-companies', 'yard', 'client', 'employee', 'key']);

    if (companyType === 'YardCompany' && showToRoles(['SUPER_ADMIN'], user)) {
      props.yardCompanyId = companyId;
      props.employeeRequestLink = '/yard-company-employees';
    }
    if (companyType === 'Yard' && showToRoles(['SUPER_ADMIN'], user)) {
      props.yardId = companyId;
      props.employeeRequestLink = '/yard-employees';
    }
    if (companyType === 'Client' && showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user)) {
      props.clientId = companyId;
      props.employeeRequestLink = '/client-employees';
    }

    return employeesService.create({ ...props, companyType });
  });
};

const useUpdateStatusEmployeeMutation = (status: 'activate' | 'deactivate') => {
  return useMutation((props: UpdateStatusProps) => {
    removeCache(['employee', 'key']);

    props.status = status;
    let METHOD: 'patch' | 'post' = 'patch';
    if (status === 'deactivate') METHOD = 'post';

    return employeesService.updateStatus({ ...props, METHOD });
  });
};

export const useDeactivateEmployeeMutation = () => {
  return useUpdateStatusEmployeeMutation('deactivate');
};

export const useActivateEmployeeMutation = () => {
  return useUpdateStatusEmployeeMutation('activate');
};
