const sortAlphabetically = (names: string[]) => {
  return names.sort((a, b) => a.localeCompare(b));
};

export const countries = sortAlphabetically(['Russia', 'Kazakhstan', 'Uzbekistan', 'Turkey']);

export const cities: any = {
  Russia: sortAlphabetically([
    'Barnaul',
    'Biysk',
    'Yekaterinburg',
    'Izhevsk',
    'Kazan',
    'Kemerovo',
    'Krasnoyarsk',
    'Kurgan',
    'Moscow',
    'Naberezhnye Chelny',
    'Nizhny Novgorod',
    'Novokuznetsk',
    'Novosibirsk',
    'Perm',
    'Tomsk',
    'Tyumen',
    'Ulyanovsk',
    'Ufa',
    'Chelyabinsk',
    'Cheboksary',
  ]),
  Kazakhstan: sortAlphabetically(['Almaty', 'Astana', 'Shymkent']),
  Uzbekistan: sortAlphabetically([
    'Tashkent',
    'Namangan',
    'Samarkand',
    'Andijan',
    'Nukus',
    'Fergana',
    'Bukhara',
    'Qarshi',
    'Kokand',
    'Termez',
    'Navoiy',
    'Urgench',
  ]),
  Turkey: sortAlphabetically(['Istanbul', 'Gebze', 'Mersin', 'Izmir', 'Izmit']),
};
