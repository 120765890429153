import { Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../../dashboard';
import { CompanyPage, CompanyHeader, CompanyForm } from '../CompanyFormComponents';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';

export const YardCompaniesAdd = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  if (!showToRoles(['SUPER_ADMIN'], user))
    return <Navigate to={'/dashboard/yardcompanies'} replace />;

  return (
    <CompanyPage>
      <CompanyHeader belongsTo={'YARD_COMPANIES'} />
      <DashboardFormWrapper>
        <CompanyForm belongsTo={'YARD_COMPANIES'} />
      </DashboardFormWrapper>
    </CompanyPage>
  );
};
