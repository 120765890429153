import styled, { css } from 'styled-components';

interface BorderLineProps {
  space?:
    | 'small'
    | 'medium'
    | 'large'
    | 'dashboard-form-out'
    | 'dashboard-form-card'
    | 'dashboard-form-card-around'
    | 'dashboard-form'
    | 'dashboard-form-dates';
  gridColumn?: string;
}

const BorderLineBase = styled.div<BorderLineProps>`
  width: 100%;
  height: 0.1rem;

  background-color: var(--gray-50);

  ${(props) =>
    props.gridColumn &&
    css`
      grid-column: ${props.gridColumn};
    `}

  ${(props) =>
    props.space === 'medium' &&
    css`
      margin-top: 4rem;
      margin-bottom: var(--spacing-6);
    `}

  ${(props) =>
    props.space === 'dashboard-form-out' &&
    css`
      margin-top: 0.2rem;
      margin-bottom: var(--spacing-6);
    `}

  ${(props) =>
    props.space === 'dashboard-form-card' &&
    css`
      margin-bottom: var(--spacing-6);
    `}

  ${(props) =>
    props.space === 'dashboard-form-card-around' &&
    css`
      margin-top: var(--spacing-6);
      margin-bottom: var(--spacing-6);
    `}

  ${(props) =>
    props.space === 'dashboard-form' &&
    css`
      margin-top: 1.6rem;
      margin-bottom: 0.4rem;
    `}

  ${(props) =>
    props.space === 'dashboard-form-dates' &&
    css`
      & + * {
        margin-top: calc(-1 * var(--spacing-4));
      }
    `}
`;

export const BorderLine: React.FC<BorderLineProps> = ({
  space = 'medium',
  ...props
}: BorderLineProps) => {
  return <BorderLineBase space={space} {...props} />;
};
