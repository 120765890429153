import { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  InputItemWrapper,
  InfoItem,
  Input,
  Button,
  BorderLine,
} from '../../design-system';
import {
  DashboardHeader,
  DashboardFormCards,
  DashboardFormCard,
  DashboardFooterWithResize,
} from '../dashboard';
import { useEditClientMutation } from '../../hooks';
import { formatPhoneNumber } from '../../utils';
import { viewCompanyLabel } from '../../const';

export const CompanyViewHeader = ({ data }: any) => {
  const pathname = useLocation().pathname.split('/')[2];

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        {data.legalName}
      </Typography>
      <Button size={'medium'} data-color={'white'} to={`/dashboard/${pathname}`}>
        Go back
      </Button>
    </DashboardHeader>
  );
};

export const CompanyViewForm = ({ data }: any) => {
  const { mutate } = useEditClientMutation({ id: data.id });
  const pathname = useLocation().pathname.split('/')[2];
  const [isEditComment, setIsEditComment] = useState(false);
  const oldComment = useRef(data.comment);

  const handleEditComment = () => {
    if (isEditComment && oldComment.current !== data.comment) {
      mutate(
        { id: data.id, comment: data.comment },
        {
          onSuccess: () => {
            oldComment.current = data.comment;
            setIsEditComment(!isEditComment);
          },
        },
      );
      return;
    }

    setIsEditComment(!isEditComment);
  };

  return (
    <DashboardFormCards id={'company-view'}>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          General Information
        </Typography>
        <InfoItem label={viewCompanyLabel[pathname]} value={data.legalName} />
        {pathname === 'yards' && <InfoItem label={viewCompanyLabel[pathname]} value={data.name} />}
        {pathname === 'clients' && <InfoItem label={'Code'} value={data.code} />}
        {pathname !== 'yards' && <InfoItem label={'Company Email'} value={data.email} />}
        <InfoItem
          label={pathname !== 'yards' ? 'Company Phone Number' : 'Phone Number'}
          value={formatPhoneNumber(data.phoneNumber)}
        />
      </DashboardFormCard>
      {pathname !== 'yards' && (
        <DashboardFormCard paperSize={'full-size'}>
          <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
            Contact Information
          </Typography>
          <InfoItem
            label={'Contact Name'}
            value={[data.contactPerson?.firstName, data.contactPerson?.lastName]}
          />
          <InfoItem label={'Contact Email'} value={data.contactPerson?.email} />
          <InfoItem
            label={'Contact Phone Number'}
            value={formatPhoneNumber(data.contactPerson?.phoneNumber)}
          />
          {pathname === 'clients' && !isEditComment && (
            <InfoItem label={'Comment'} value={data.comment} />
          )}
          {pathname === 'clients' && isEditComment && (
            <InputItemWrapper>
              <Input
                multiline
                label={`Comment`}
                placeholder={'Comment'}
                data-size={'medium'}
                defaultValue={data.comment}
                onChange={(e) => (data.comment = e.target.value.trim())}
              />
            </InputItemWrapper>
          )}
          {pathname === 'clients' && (
            <>
              <BorderLine space={'dashboard-form-card'} />
              <DashboardFooterWithResize columns={'max-content'}>
                <Button size={'medium'} data-color={'primary'} onClick={handleEditComment}>
                  {isEditComment ? 'Save Comment' : 'Edit Comment'}
                </Button>
              </DashboardFooterWithResize>
            </>
          )}
        </DashboardFormCard>
      )}
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          Address Information
        </Typography>
        <InfoItem label={'Country'} value={data.address?.country} />
        <InfoItem label={'City'} value={data.address?.city} />
        <InfoItem label={'Street Address'} value={data.address?.streetAddress} />
        <InfoItem label={'Zip Code'} value={data.address?.zipCode} />
      </DashboardFormCard>
    </DashboardFormCards>
  );
};
