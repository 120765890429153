import { useRef } from 'react';
import equal from 'deep-equal';
import { useMultipleStateDebounce, useSaveFilters } from '../hooks';

type PropsType = { [key: string]: any };

export const useStatesWithFilterCounter = (
  defaultStates: PropsType,
  importantStates: any = {},
  delay?: number,
  saveFilters = true,
): [PropsType, (key: string, value?: any, isEmptyFilter?: boolean) => void, PropsType] => {
  const [oldFilters, setOldFilters] = useSaveFilters('table', saveFilters, importantStates);
  const [states, setStates] = useMultipleStateDebounce(
    {
      ...defaultStates,
      ...oldFilters,
    },
    delay,
  );
  const filteredStates = useRef(oldFilters);

  const handleSetStates = (key: string, value?: any, isEmptyFilter?: boolean) => {
    let newStates: any = null;
    if (key === 'cancelall') {
      newStates = defaultStates;
      setStates(newStates);
    } else if (key === 'all') {
      newStates = { ...value };
      setStates(newStates, true);
    } else if (key === 'force') {
      newStates = { ...states, ...value };
      setStates(newStates, true);
    } else {
      newStates = { ...states, [key]: value };
      setStates(newStates);
    }

    const newfilteredStates: any = {};

    if (!isEmptyFilter) {
      Object.keys(newStates).forEach((key) => {
        if (equal(defaultStates[key], newStates[key])) return;

        newfilteredStates[key] = newStates[key];
      });
    }

    filteredStates.current = newfilteredStates;
    setOldFilters(newfilteredStates);
  };

  return [states, handleSetStates, filteredStates.current];
};
