import styled from 'styled-components';
import { TableCell } from '@mui/material';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { CustomHeaderSelect, CustomHeaderSelectProps } from '../select';
import { IconButton } from '../button';

export const TableIconCell = styled(TableCell)`
  & > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const TableDots: React.FC<CustomHeaderSelectProps> = (props: CustomHeaderSelectProps) => {
  return (
    <CustomHeaderSelect
      HeaderComponent={
        <IconButton
          onClick={(e: any) => {
            e.stopPropagation();
            props.onToggle && props.onToggle();
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      }
      {...props}
    />
  );
};
