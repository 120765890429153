import { Modal as MuiModal, ModalProps as BaseModalProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { Paper, PaperSizeProps } from '../paper';
import { IconButton } from '../button';

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiCloseIcon': {
            position: 'absolute',
            top: '0.4rem',
            right: '0.4rem',
          },
        },
      },
    },
  },
});

interface ModalProps extends Omit<BaseModalProps, 'slotProps'> {
  paperSize?: PaperSizeProps;
  hideCloseIcon?: boolean;
  id?: string;
}

export const Modal: React.FC<ModalProps> = ({
  paperSize,
  hideCloseIcon,
  children,
  ...props
}: ModalProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiModal {...props}>
        <>
          <Paper variant={'outlined'} paperSize={paperSize} position={'absolute'}>
            {children}
            {!hideCloseIcon && (
              <IconButton
                className={'MuiCloseIcon'}
                onClick={() => {
                  props.onClose && props.onClose({}, 'escapeKeyDown');
                }}
                iconColor={'--gray-70'}
              >
                <CloseRoundedIcon />
              </IconButton>
            )}
          </Paper>
        </>
      </MuiModal>
    </ThemeProvider>
  );
};
