import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router';
import Cookies from 'js-cookie';

export const AutoSignin = ({ disabled = false }: { disabled?: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const jwt = Cookies.get('jwt');
    const user = Cookies.get('user');

    if (!jwt || !user) return setIsLoading(false);

    if (!location.pathname.includes('/dashboard')) navigate('/dashboard/containers');
    setIsLoading(false);
  }, []);

  if (!isLoading || disabled)
    return (
      <>
        <Outlet />
      </>
    );

  return <></>;
};
