import {
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  Tab as MuiTab,
  TabProps as MuiTabProps,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '../../const';

const theme = createTheme({
  palette: {
    primary: { main: colors['--primary-100'] },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '&[data-border=true]': {
            borderBottom: '0.1rem solid var(--gray-40)',
          },
          '&.small-size': {
            height: '4rem',
            minHeight: '4rem',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 'max-content',
          height: '5.6rem',
          minHeight: '5.6rem',
          padding: '0 1.6rem',
          fontSize: '1.6rem',
          fontFamily: 'Inter',
          fontWeight: 'var(--font-weight-400)',
          textTransform: 'initial',
          '& > svg': {
            fontSize: '2.4rem',
          },
          '&.small-size': {
            height: '4rem',
            minHeight: '4rem',
          },
        },
      },
    },
  },
});

interface TabsProps extends MuiTabsProps {
  withborder?: boolean;
}

export const Tabs: React.FC<TabsProps> = ({ withborder, children, ...props }: TabsProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiTabs data-border={withborder} {...props}>
        {children}
      </MuiTabs>
    </ThemeProvider>
  );
};

export const Tab: React.FC<MuiTabProps> = ({ ...props }: MuiTabProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiTab {...props} />
    </ThemeProvider>
  );
};

export const SmallTabs: React.FC<MuiTabsProps> = ({ children, ...props }: MuiTabsProps) => {
  return (
    <Tabs className={'small-size'} {...props}>
      {children}
    </Tabs>
  );
};

export const SmallTab: React.FC<MuiTabProps> = ({ ...props }: MuiTabProps) => {
  return <Tab className={'small-size'} {...props} />;
};
