import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ConfirmEmployeeStatusModal } from '../../../design-system';
import { EmployeesPage, EmployeesHeader, EmployeesBody } from './EmployeesComponents';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';

export const Employees = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [totalCount, setTotalCount] = useState(0);

  if (showToRoles(['YARD_COMPANY_EMPLOYEE', 'YARD_DISPATCHER', 'CLIENT_DISPATCHER'], user))
    return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <>
      <EmployeesPage innerScroll>
        <EmployeesHeader totalCount={totalCount} />
        <EmployeesBody setTotalCount={setTotalCount} />
      </EmployeesPage>
      <ConfirmEmployeeStatusModal />
    </>
  );
};
