import { DashboardFormWrapper } from '../dashboard';
import { GateOutsPage } from './GateOutsComponents';
import { GateOutsAddHeader, GateOutsAddForm } from './GateOutsAddComponents';

export const GateOutsAdd = () => {
  return (
    <GateOutsPage>
      <GateOutsAddHeader />
      <DashboardFormWrapper innerOverflow>
        <GateOutsAddForm />
      </DashboardFormWrapper>
    </GateOutsPage>
  );
};
