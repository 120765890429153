import styled, { css } from 'styled-components';
import { Paper, PaperProps } from '../paper';

interface TableWrapperInnerProps {
  autoColumn?: number;
  columns?: Array<number>;
}

const TableWrapperBase = styled(Paper)`
  display: grid;
  grid-template-rows: 100%;
  align-content: flex-start;
  justify-content: stretch;
  justify-items: center;

  &.MuiPaper-root {
    padding: 0;
  }
`;

export const TableFilters = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  margin-bottom: 2rem;
`;

export const TableWrapperInner = styled.div<TableWrapperInnerProps & { autoColumn: number }>`
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  width: 100%;
  height: 100%;
  padding: 2.8rem;
  overflow: overlay;

  & > table {
    min-width: max-content;
  }

  & ${TableFilters} {
    grid-auto-columns: ${(props) => props.autoColumn}rem;
    grid-column-gap: 2.4rem;
    margin-left: 2.4rem;

    grid-template-columns: ${(props) =>
      props.columns
        ? props.columns.map((col, i) => (i == 0 ? `${col - 1.2}rem` : `${col}rem`)).join(' ')
        : `${props.autoColumn - 1.2}rem`};
  }

  & thead {
    & > tr > * {
      width: ${(props) => props.autoColumn + 2.4}rem;
    }

    ${(props) =>
      props.columns &&
      props.columns.map(
        (col, i) => css`
          & > tr > *:nth-of-type(${i + 1}) {
            width: ${col + 2.4}rem;
          }
        `,
      )}

    & > tr > *:nth-last-of-type(1) {
      min-width: ${(props) => props.autoColumn + 2.4}rem;
      width: fit-content;
    }
  }

  & .MuiTableCell-root {
    position: relative;
  }

  & .remove-dots-column {
    display: none;

    & + * {
      grid-column: 1 / 3;
    }
  }

  & .remove-two-column {
    display: none;

    & + * {
      grid-column: 1 / 4;
    }
  }
`;

export const TableWrapperFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-content: flex-end;
  align-items: center;

  margin-top: 2.8rem;
`;

export const TableWrapper: React.FC<PaperProps & TableWrapperInnerProps> = ({
  autoColumn = 20,
  columns,
  children,
  ...props
}: PaperProps & TableWrapperInnerProps) => {
  return (
    <TableWrapperBase paperSize={'full-size'} {...props}>
      <TableWrapperInner autoColumn={autoColumn} columns={columns}>
        {children}
      </TableWrapperInner>
    </TableWrapperBase>
  );
};
