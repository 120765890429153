import { Navigate, useParams } from 'react-router-dom';
import { DashboardFormWrapper } from '../dashboard';
import { GateInsPage } from './GateInsComponents';
import { GateInsViewHeader, GateInsViewForm } from './GateInsViewComponents';
import { useGateInQuery } from '../../hooks';

export const GateInsView = () => {
  const params: any = useParams();
  const { data: request, isFetching } = useGateInQuery(params.id);

  if (isFetching) return <></>;
  if (!request) return <Navigate to={'/dashboard/gateins'} replace />;

  return (
    <GateInsPage>
      <GateInsViewHeader request={request} />
      <DashboardFormWrapper flow={'row'}>
        <GateInsViewForm request={request} />
      </DashboardFormWrapper>
    </GateInsPage>
  );
};
