import { AuthWrapper } from '../AuthComponents';
import {
  CreateNewPasswordHeader,
  CreateNewPasswordForm,
  CreateNewPasswordFooter,
} from './CreateNewPasswordComponents';

export const CreateNewPassword = () => {
  return (
    <AuthWrapper>
      <CreateNewPasswordHeader />
      <CreateNewPasswordForm />
      <CreateNewPasswordFooter />
    </AuthWrapper>
  );
};
