import styled from 'styled-components';
import { Typography } from '../typography';
import { useStore } from '../../context';
import { rolesLabel } from '../../const';

const AppbarBase = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 0.4rem;
  align-items: center;

  width: 100%;
  height: 6.8rem;

  background-color: var(--white);
  border-bottom: 0.1rem solid var(--gray-50);
  padding: 0 2.4rem;

  & svg.MuiSvgIcon-root {
    color: var(--gray-70);
  }
`;

export const Appbar = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <AppbarBase>
      <span />
      <Typography variant={'textM'} color={'--gray-80'} capitalized>
        {rolesLabel[user.type]}
        {(user.firstName || user.lastName) && ':'}
        {user.firstName && ` ${user.firstName}`}
        {user.lastName && ` ${user.lastName}`}
      </Typography>
    </AppbarBase>
  );
};
