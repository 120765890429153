import { Select, Button, optionsToComponents } from '..';

interface SelectProps {
  label: string;
  options: Array<any>;
  value?: any;
  onChange?: (e: any) => void;
  withoutEmptyValue?: boolean;
  selectedAreasEqaul?: { key?: string; keys: Array<string> };
}

export const TableFilterSelect: React.FC<SelectProps> = ({
  label,
  options,
  value = 'All',
  onChange,
  withoutEmptyValue,
  ...props
}: SelectProps) => {
  return (
    <Select
      options={!withoutEmptyValue ? ['All', ...options] : options}
      label={label}
      value={value}
      onChange={onChange}
    >
      {!withoutEmptyValue && (
        <Button
          size={'medium'}
          data-color={'dropdown-option'}
          data-aria-selected={value === 'All'}
          value={'All'}
        >
          All
        </Button>
      )}
      {optionsToComponents(options, { ...props, value })}
    </Select>
  );
};
