import { formatPhoneNumberIntl } from 'react-phone-number-input';

export const formatPhoneNumber = (phone = '') => {
  if (!phone) return '';

  if (!phone.includes('+')) phone = `+${phone}`;

  const phoneNumber = formatPhoneNumberIntl(phone);
  const phoneNumberLength = phoneNumber.split(' ').join('').length;

  if (phoneNumberLength === phone.length) phone = phoneNumber;

  return phone;
};
