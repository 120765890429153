import React from 'react';
import moment from 'moment';
import {
  TableFilters,
  TableFilterSelect,
  InputContainer,
  DatePicker,
  BelongsToFilterSelects,
} from '../../design-system';
import { useStore } from '../../context';
import { extendContainerValidator, showToRoles } from '../../utils';
import { containerTypes, containerStatues, INPUT_ERRORS } from '../../const';

interface Props {
  number?: string;
  type?: string;
  startDate?: any;
  endDate?: any;
  status?: any;
  setStates: any;
}

export const Filters: React.FC<Props> = ({
  number = '',
  type,
  startDate,
  endDate,
  status,
  setStates,
}: Props) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <TableFilters>
      <span className={showToRoles('admin', user) ? 'remove-two-column' : 'remove-dots-column'} />
      <BelongsToFilterSelects
        hideYardCompany
        handleChange={(obj) => {
          setStates('force', obj);
        }}
      />
      <InputContainer
        label={'Container number'}
        data-size={'medium'}
        defaultValue={number}
        onChange={(e) => {
          const value = e.target.value.toUpperCase();
          setStates('number', value);
        }}
        helperText={
          number.length !== 0 && number.length < 3
            ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER_MIN
            : !extendContainerValidator(number)
            ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER_SHORT
            : ''
        }
        alwaysShowHelperText
      />
      <TableFilterSelect
        label={'Type'}
        options={containerTypes}
        value={type}
        onChange={(e) => setStates('force', { type: e.target.value })}
      />
      <TableFilterSelect
        label={'Status'}
        options={containerStatues}
        value={status}
        onChange={(e) => {
          setStates('force', {
            status: e.target.value,
            startDate: null,
            endDate: null,
          });
        }}
      />
      {(status === 'GATED_IN' || status === 'GATED_OUT') &&
        [status].map(() => (
          <React.Fragment key={Math.random()}>
            <DatePicker
              label={'From'}
              data-size={'medium'}
              defaultValue={startDate}
              onChange={(e) => setStates('force', { startDate: e })}
              maxDate={endDate ? moment(endDate) : moment()}
            />
            <DatePicker
              label={'To'}
              data-size={'medium'}
              defaultValue={endDate}
              onChange={(e) => setStates('force', { endDate: e })}
              minDate={startDate ? moment(startDate) : null}
              maxDate={moment()}
            />
          </React.Fragment>
        ))}
    </TableFilters>
  );
};
