import { useState, useRef, useEffect } from 'react';
import { Typography } from '../typography';
import { Button } from '../button';
import { SearchableSelect } from '../select';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { useStore } from '../../context';
import { belongsToFullOptions } from '../../services';
import { clientYardModalLabel, clientYardModalPlaceholder } from '../../const';

export const assignYardsAndClientsModal: any = {};

export const AssignYardsAndClientsModal = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [open, setOpen] = useState(false);
  const [assigns, setAssigns] = useState<any[]>([]);
  const [assignsId, setAssignsId] = useState<any[]>([]);
  const id = useRef<string>('');
  const action = useRef<string>('');
  const assignType = useRef<string>('');

  useEffect(() => {
    assignYardsAndClientsModal.open = () => {
      id.current = assignYardsAndClientsModal.id;
      action.current = assignYardsAndClientsModal.action;
      assignType.current = assignYardsAndClientsModal.assignType;
      setAssigns([]);
      setAssignsId([]);

      setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSelect = (obj: any) => {
    if (assignsId.includes(obj.id)) {
      const i = assignsId.indexOf(obj.id);
      setAssigns(assigns.filter((_, n) => n !== i));
      setAssignsId(assignsId.filter((_, n) => n !== i));
    } else {
      setAssigns([obj.label, ...assigns]);
      setAssignsId([obj.id, ...assignsId]);
    }
  };

  const handleSubmitClick = async () => {
    if (assigns.length)
      assignYardsAndClientsModal?.fc({
        id: assignYardsAndClientsModal.id,
        assigns,
        assignsId,
        assignType: assignType.current,
      });

    setOpen(false);
  };

  return (
    <ModalBase id={'container-list-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          {clientYardModalLabel[action.current]}
        </Typography>
        <SearchableSelect
          selectedOptionLabel={assigns.join(', ') || clientYardModalPlaceholder[action.current]}
          label={clientYardModalLabel[action.current]}
          onSelect={handleOnSelect}
          queryKey={[
            assignType.current,
            assignType.current === 'yards' ? 'yard-full-options' : 'client-full-options',
            id.current,
            action.current,
          ]}
          queryFn={() =>
            belongsToFullOptions[assignType.current === 'yards' ? 'Yard' : 'Client'](
              id.current,
              action.current,
              true,
              user,
            )
          }
          selectedAreasEqaul={{ key: 'id', keys: assignsId }}
          multiple
        />
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size={'medium'} data-color={'primary'} onClick={handleSubmitClick}>
            Submit
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
