import { useState, useEffect } from 'react';
import { Typography } from '../typography';
import { Button } from '../button';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { employeetableDotsActions } from '../../const';

export const confirmEmployeeStatusModal: any = {};

export const ConfirmEmployeeStatusModal = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    confirmEmployeeStatusModal.open = () => {
      confirmEmployeeStatusModal.name = [...employeetableDotsActions].find(
        (obj) => obj.value === confirmEmployeeStatusModal.val,
      )?.label;

      if (confirmEmployeeStatusModal.name !== '') setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    confirmEmployeeStatusModal.type = '';
    setOpen(false);
  };

  const handleSubmitClick = async () => {
    await confirmEmployeeStatusModal.fc();

    confirmEmployeeStatusModal.type = '';
    setOpen(false);
  };

  return (
    <ModalBase id={'confirm-employee-status-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          Do you want to {confirmEmployeeStatusModal.name}?
        </Typography>
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size={'medium'} data-color={'primary'} onClick={handleSubmitClick}>
            Confirm
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
