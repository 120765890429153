import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import moment from 'moment';
import validator from 'validator';
import {
  Typography,
  Button,
  Input,
  InputContainer,
  Select,
  BorderLine,
  DatePicker,
  BelongsToSelects,
  containerListModal,
  enqueueSnackbar,
} from '../../design-system';
import {
  DashboardHeader,
  DashboardForm,
  DashboardFormTwoColumn,
  DashboardFormCustomColumn,
} from '../dashboard';
import { useAddGateInMutation } from '../../hooks';
import { containerValidator, getNormalFormProps } from '../../utils';
import { REGEX, MESSAGES, INPUT_ERRORS, containerTypes } from '../../const';

let formProps: any = {};

const getSubmitProps = () => {
  const props: any = {
    yardName: formProps.yardName,
    clientName: formProps.clientName,
  };

  const validate: any = {};
  formProps.containerNumber.forEach((val: any, i: any) => {
    props['containerNumber-' + (i + 1)] = val;
    validate['containerNumber-' + (i + 1)] = containerValidator(val);
  });
  const errors: any = {
    validFrom: moment(formProps.validFrom).set('hour', 23).isAfter(moment().set('hour', 0)),
    validTo: moment(formProps.validTo)
      .set('hour', 23)
      .isAfter(moment(formProps.validFrom).set('hour', 0)),
    hasValidFrom: !!formProps.validFrom,
    hasValidTo: !!formProps.validTo,
  };
  const { validators }: any = formProps;

  const hash: any = {};
  if (
    formProps.containerNumber.some((container: any) => {
      if (hash[container]) return true;

      hash[container] = container;
      return false;
    })
  ) {
    enqueueSnackbar(MESSAGES.GATE_IN.ERROR.CONTAINER_DUPLICATED, 'error');
    return;
  }

  if (
    [
      ...Object.values(props),
      ...Object.values(validate),
      ...Object.values(errors),
      ...Object.values(validators).map((val) => !val),
    ].some((val) => !val)
  ) {
    return;
  }

  delete formProps.isSubmitClicked;
  return getNormalFormProps(formProps);
};

export const GateInsAddHeader = () => {
  const navigate = useNavigate();
  const { mutate } = useAddGateInMutation();

  const handleSubmitClick = () => {
    formProps.onSubmitClick();
    const submitProps = getSubmitProps();
    if (!submitProps) return;
    const { containerNumber, containerType, ...otherProps } = submitProps;

    mutate(
      containerNumber.map((_: any, i: any) => ({
        ...otherProps,
        containerNumber: containerNumber[i],
        containerType: containerType[i],
      })),
      {
        onSuccess: () => {
          navigate('/dashboard/gateins');
        },
        onError: (err: any) => {
          if (err.statusCode === 409)
            enqueueSnackbar(MESSAGES.CONTAINERS.ERROR.DUPLICATED, 'error');
        },
      },
    );
  };

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Gate In
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
      <Button size={'medium'} onClick={handleSubmitClick}>
        Submit
      </Button>
    </DashboardHeader>
  );
};

export const GateInsAddForm = () => {
  const [yardCompanyName, setYardCompanyName] = useState();
  const yardCompanyId = useRef();
  const [yardName, setYardName] = useState();
  const yardId = useRef();
  const [clientName, setClientName] = useState();
  const clientId = useRef();
  const [containerType, setContainerType] = useState(['']);
  const [validFrom, setValidFrom] = useState<any>();
  const [validTo, setValidTo] = useState<any>();
  const [transport] = useState('ground');
  const [containerNumber, setContainerNumber] = useState(['']);
  const [releaseNumber, setReleaseNumber] = useState('');
  const [comment, setComment] = useState('');

  const [updateKey, setUpdate] = useState<any>(Math.random());

  const validators = {
    releaseNumber: releaseNumber
      ? !validator.isLength(releaseNumber, { min: 1, max: 10 })
        ? INPUT_ERRORS.NOT_VALID.RELEASE_NUMBER_LENGTH
        : !REGEX.RELEASE_NUMBER.test(releaseNumber)
        ? INPUT_ERRORS.NOT_VALID.RELEASE_NUMBER
        : ''
      : '',
    comment: comment
      ? !validator.isLength(comment, { min: 10, max: 200 })
        ? INPUT_ERRORS.NOT_VALID.COMMENT_LENGTH
        : ''
      : '',
  };

  useEffect(() => {
    if (!formProps) return;

    formProps.isSubmitClicked = false;
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    formProps = {
      ...formProps,
      yardCompanyName,
      yardCompanyId: yardCompanyId.current,
      yardName,
      yardId: yardId.current,
      clientName,
      clientId: clientId.current,
      containerNumber,
      containerType,
      validFrom,
      validTo,
      transport,
      releaseNumber,
      comment,
      validators,
      onSubmitClick: () => {
        formProps.isSubmitClicked = true;
        setUpdate(Math.random());
      },
    };
  }, [
    yardCompanyName,
    yardCompanyId.current,
    yardName,
    yardId.current,
    clientName,
    clientId.current,
    containerNumber.join(''),
    containerNumber.length,
    containerType.join(''),
    containerType.length,
    validFrom,
    validTo,
    transport,
    releaseNumber,
    comment,
  ]);

  const handleContainerListClick = () => {
    if (!containerNumber.every((number: any) => containerValidator(number) || !number.length))
      return;

    const fc = (data: any) => {
      let newContainerNumber: any = [];
      let newContainerType: any = [];
      containerNumber.forEach((number: any, i: any) => {
        if (!number) return;
        newContainerNumber.push(number);
        newContainerType.push(containerType[i]);
      });
      newContainerNumber = [...newContainerNumber, ...data.containerNumber];
      newContainerType = [...newContainerType, ...data.containerType];

      setContainerNumber(newContainerNumber);
      setContainerType(newContainerType);
      setUpdate(Math.random());
    };
    containerListModal.fc = fc;
    containerListModal.open();
  };

  const handlePlusClick = () => {
    setContainerNumber([...containerNumber, '']);
    setContainerType([...containerType, '']);
  };

  const handleDeleteClick = (i: number) => {
    setUpdate(Math.random());

    const filteredContainerNumber = containerNumber.filter((_: any, index: any) => i !== index);
    const filteredContainerType = containerType.filter((_: any, index: any) => i !== index);

    setContainerNumber(filteredContainerNumber);
    setContainerType(filteredContainerType);
  };

  return (
    <DashboardForm paperSize={'full-size'}>
      <Button
        size={'medium'}
        data-color={'primary'}
        justify={'left'}
        onClick={handleContainerListClick}
        disabled={
          !containerNumber.every((number: any) => containerValidator(number) || !number.length)
        }
      >
        Container List
      </Button>
      <BelongsToSelects
        handleYardCompanyChange={(obj) => {
          yardCompanyId.current = obj.id;
          setYardCompanyName(obj.label);
        }}
        handleYardChange={(obj) => {
          yardId.current = obj.id;
          setYardName(obj.label);
        }}
        handleClientChange={(obj) => {
          clientId.current = obj.id;
          setClientName(obj.label);
        }}
        isSubmitClicked={formProps.isSubmitClicked}
      />
      {containerNumber.map((val: any, i: any) => (
        <DashboardFormCustomColumn
          key={updateKey + i}
          columns={
            containerNumber.length === 1 || i !== 0
              ? 'calc(50% - 1.4rem) 1fr max-content'
              : '1fr 1fr'
          }
          align={'start'}
        >
          <InputContainer
            id={`container-${i}`}
            label={`Container Number*`}
            placeholder={'Container Number'}
            data-size={'medium'}
            defaultValue={val}
            onChange={(e) => {
              const newContainerNumber = containerNumber;
              newContainerNumber[i] = e.target.value;
              setContainerNumber([...newContainerNumber]);
            }}
            error={!!val && !containerValidator(val) ? true : formProps.isSubmitClicked && !val}
            helperText={
              !!val && !containerValidator(val)
                ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER
                : formProps.isSubmitClicked && !val
                ? INPUT_ERRORS.REQUIRED.CONTAINER_NUMBER
                : ''
            }
          />
          <Select
            id={`container-type-${i}`}
            label={'Container Type'}
            placeholder={'Select Container Type'}
            options={containerTypes}
            value={containerType[i]}
            onSelect={(value: any) => {
              const newContainerType = containerType;
              newContainerType[i] = value;
              setContainerType([...newContainerType]);
            }}
            withClearButton
          />
          {i !== 0 && (
            <Button
              className={'row-button-square'}
              size={'medium'}
              data-color={'destructiveSecondary'}
              isSquare={true}
              onClick={() => handleDeleteClick(i)}
            >
              <DeleteIcon />
            </Button>
          )}
          {containerNumber.length === 1 && (
            <Button
              className={'row-button-square'}
              size={'medium'}
              data-color={'secondary'}
              isSquare={true}
              onClick={handlePlusClick}
            >
              <AddIcon />
            </Button>
          )}
        </DashboardFormCustomColumn>
      ))}
      {containerNumber.length > 1 && (
        <Button size={'medium'} data-color={'secondary'} onClick={handlePlusClick} justify={'left'}>
          Add More
        </Button>
      )}
      <BorderLine space={'dashboard-form-dates'} />
      <Typography variant={'textS'} color={'--gray-80'}>
        Arrival Date
      </Typography>
      <DashboardFormTwoColumn>
        <DatePicker
          label={'From*'}
          data-size={'medium'}
          defaultValue={validFrom}
          onChange={setValidFrom}
          minDate={moment()}
          maxDate={validTo ? moment(validTo) : null}
          error={formProps.isSubmitClicked && !validFrom}
          helperText={formProps.isSubmitClicked && !validFrom && INPUT_ERRORS.REQUIRED.DATE}
        />
        <DatePicker
          label={'To*'}
          data-size={'medium'}
          defaultValue={validTo}
          onChange={setValidTo}
          minDate={validFrom ? moment(validFrom) : moment()}
          disabled={!validFrom}
          error={formProps.isSubmitClicked && !validTo}
          helperText={formProps.isSubmitClicked && !validTo && INPUT_ERRORS.REQUIRED.DATE}
        />
      </DashboardFormTwoColumn>
      <BorderLine space={'dashboard-form'} />
      <Input
        label={'Release Number'}
        placeholder={'Release Number'}
        data-size={'medium'}
        defaultValue={releaseNumber}
        onChange={(e) => setReleaseNumber(e.target.value)}
        error={!!validators.releaseNumber}
        helperText={validators.releaseNumber}
      />
      <Input
        label={'Comment'}
        data-size={'medium'}
        rows={4}
        multiline
        defaultValue={comment}
        onChange={(e) => setComment(e.target.value)}
        error={!!validators.comment}
        helperText={validators.comment}
      />
    </DashboardForm>
  );
};
