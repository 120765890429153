import { useState } from 'react';
import { ConfirmModal } from '../../design-system';
import { GateInsPage, GateInsHeader, GateInsBody } from './GateInsComponents';

export const GateIns = () => {
  const [totalCount, setTotalCount] = useState(0);

  return (
    <>
      <GateInsPage innerScroll>
        <GateInsHeader totalCount={totalCount} />
        <GateInsBody setTotalCount={setTotalCount} />
      </GateInsPage>
      <ConfirmModal />
    </>
  );
};
