import { AuthWrapper } from '../AuthComponents';
import { SignInHeader, SignInForm, SignInFooter } from './SignInComponents';

export const SignIn = () => {
  return (
    <AuthWrapper>
      <SignInHeader />
      <SignInForm />
      <SignInFooter />
    </AuthWrapper>
  );
};
