import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  PaginationItem,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  EastRounded as RightRoundedIcon,
  WestRounded as LeftRoundedIcon,
} from '@mui/icons-material';

const theme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            display: 'grid',
            alignContent: 'center',

            minWidth: '3.6rem',
            height: '3.6rem',
            margin: 0,
            padding: 0,

            fontFamily: 'Inter',
            fontSize: 'var(--font-size-3)',
            lineHeight: 'var(--line-height-3)',
            color: 'var(--gray-80)',

            '&.Mui-selected': {
              color: 'var(--primary-100)',
              backgroundColor: 'var(--primary-30)',
            },
          },
          '& .MuiPaginationItem-previousNext': {
            justifyItems: 'center',
            color: 'var(--gray-70)',

            '& svg': {
              fontSize: '2.4rem',
            },
          },
        },
        ul: {
          '& > *:first-of-type': {
            marginRight: '1.2rem',
          },
          '& > *:last-of-type': {
            marginLeft: '1.2rem',
          },
        },
      },
    },
  },
});

export const Pagination: React.FC<MuiPaginationProps> = ({
  siblingCount = 2,
  shape = 'rounded',
  ...props
}: MuiPaginationProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPagination
        siblingCount={siblingCount}
        shape={shape}
        renderItem={(item) => (
          <PaginationItem slots={{ previous: LeftRoundedIcon, next: RightRoundedIcon }} {...item} />
        )}
        {...props}
      />
    </ThemeProvider>
  );
};
