import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AssignYardsAndClientsModal } from '../../../design-system';
import { ClientsPage, ClientsHeader, ClientsBody } from './ClientsComponents';
import { useStore } from '../../../context';
import { showToRoles } from '../../../utils';

export const Clients = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [totalCount, setTotalCount] = useState(0);

  if (!showToRoles(['SUPER_ADMIN'], user)) return <Navigate to={'/dashboard/containers'} replace />;

  return (
    <>
      <ClientsPage innerScroll>
        <ClientsHeader totalCount={totalCount} />
        <ClientsBody setTotalCount={setTotalCount} />
      </ClientsPage>
      <AssignYardsAndClientsModal />
    </>
  );
};
