import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { Button } from '../button';
import { FileInput, FileRow } from '../input';
import { ImageGallery, ImageGalleryCard, fullScreenButtonClick, HideInput } from '../image-gallery';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { enqueueSnackbar } from '../snackbar';
import { filesFormatValidator } from '../../utils';
import { useUpdateImagesMutation, useUpdateDocumentsMutation } from '../../hooks';
import { tableDotsActions } from '../../const';

export const uploadImagesModal: any = {};

interface ModalImagesProps {
  isVisible?: boolean;
  isFlowRow?: boolean;
}

const ModalImages = styled.div<ModalImagesProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;
  grid-gap: 2.4rem;
  overflow: overlay;

  ${(props) =>
    !props.isVisible &&
    css`
      display: none;
    `}

  ${(props) =>
    props.isFlowRow &&
    css`
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-row-gap: initial;
    `}
`;

export const UploadImagesModal = () => {
  const updateImagesMutation = useUpdateImagesMutation();
  const updateDocumentsMutation = useUpdateDocumentsMutation();
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [zoomImageIndex, setZoomImageIndex] = useState(0);
  const inputRef = useRef<any>();
  const obj = useRef<any>({});

  useEffect(() => {
    if (open) return;
    setImages([]);
    setFiles([]);
  }, [open]);

  useEffect(() => {
    uploadImagesModal.open = () => {
      if (uploadImagesModal.val === 'torepair') return;

      obj.current.name = tableDotsActions.find((obj) => obj.value === uploadImagesModal.val)?.label;
      obj.current.number = uploadImagesModal.number;
      if (uploadImagesModal.oldFilesLength) {
        const fileTypeName = obj.current.name === 'Add Images' ? 'images' : 'documents';
        obj.current.title = `${obj.current.number} has ${uploadImagesModal.oldFilesLength} ${fileTypeName}`;
      } else {
        obj.current.title = obj.current.number;
      }

      if (obj.current.name !== '') setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    setOpen(!open);
  };

  const handleChangeFiles = (newImages: any, newFiles: any) => {
    const newImagesValues = [...images, ...newImages];

    if (obj.current.name === 'Add Images' && filesFormatValidator(newFiles, 'Image')) {
      enqueueSnackbar(filesFormatValidator(newFiles, 'Image'), 'warning');
      return;
    }

    setImages(newImagesValues);
    setFiles([...files, ...newFiles]);
    inputRef.current.type = 'text';
    inputRef.current.type = 'file';
  };

  const handleDeleteImageClick = (i: number) => {
    setImages(images.filter((_: any, index: number) => index !== i));
    setFiles(files.filter((_: any, index: number) => index !== i));
  };

  const handleZoomClick = (i: number) => {
    setZoomImageIndex(i);
    fullScreenButtonClick();
  };

  const handleSubmitClick = async () => {
    if (images.length > 10) {
      enqueueSnackbar('Max Upload Files is 10', 'warning');
      return;
    }

    let mutate: any = updateImagesMutation.mutate;
    if (obj.current.name === 'Add Documents') mutate = updateDocumentsMutation.mutate;

    const data = images.map((img: any, i: any) => ({
      action: 'upload',
      file: files[i],
    }));

    mutate(
      {
        id: uploadImagesModal.id,
        ...(obj.current.name === 'Add Images' ? { photos: data } : { documents: data }),
      },
      {
        onSuccess: (res: any) => {
          if (res && !!uploadImagesModal.submitClick) {
            uploadImagesModal.submitClick();
          }
        },
        onSettled: () => {
          setOpen(false);
        },
      },
    );
  };

  return (
    <ModalBase id={'upload-image-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          {obj.current.title}
        </Typography>
        <ModalImages isFlowRow={obj.current.name === 'Add Documents'} isVisible>
          {obj.current.name === 'Add Images' &&
            images.map((img: any, i: any) => (
              <ImageGalleryCard
                key={i}
                img={img}
                handleZoomClick={() => handleZoomClick(i)}
                handleDeleteImageClick={() => handleDeleteImageClick(i)}
              />
            ))}
          {obj.current.name === 'Add Documents' &&
            files.map((file: any, i: any) => {
              return (
                <FileRow
                  key={i}
                  file={file}
                  handleDeleteFileClick={() => handleDeleteImageClick(i)}
                  withoutBorder={i === 0}
                />
              );
            })}
          <HideInput>
            <FileInput
              id={'file-input'}
              label={obj.current.name}
              inputRef={inputRef}
              inputProps={{
                accept: obj.current.name === 'Add Images' ? '.jpeg, .jpg, .png, .webp' : '*',
                multiple: true,
              }}
              handleChangeFiles={handleChangeFiles}
            />
          </HideInput>
        </ModalImages>
        <ModalFooterButtons columns={'1fr'}>
          <Button
            size={'medium'}
            data-color={'primary'}
            onClick={() => inputRef.current.click()}
            justify={'left'}
          >
            {obj.current.name}
          </Button>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            size={'medium'}
            data-color={'primary'}
            onClick={handleSubmitClick}
            disabled={!files.length}
          >
            {obj.current.name === 'Add Images' ? 'Save Images' : 'Save Documents'}
          </Button>
        </ModalFooterButtons>
        <ImageGallery photos={images} startIndex={zoomImageIndex} />
      </ModalWrapper>
    </ModalBase>
  );
};
