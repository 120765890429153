import { useState } from 'react';
import { ConfirmModal } from '../../design-system';
import { GateOutsPage, GateOutsHeader, GateOutsBody } from './GateOutsComponents';

export const GateOuts = () => {
  const [totalCount, setTotalCount] = useState(0);

  return (
    <>
      <GateOutsPage innerScroll>
        <GateOutsHeader totalCount={totalCount} />
        <GateOutsBody setTotalCount={setTotalCount} />
      </GateOutsPage>
      <ConfirmModal />
    </>
  );
};
