import { useState } from 'react';
import { ContainerEditModal, ContainerTransferModal, UploadImagesModal } from '../../design-system';
import { ContainersPage, ContainersHeader, ContainersBody } from './ContainersComponents';

export const Containers = () => {
  const [totalCount, setTotalCount] = useState(0);

  return (
    <>
      <ContainersPage innerScroll>
        <ContainersHeader totalCount={totalCount} />
        <ContainersBody setTotalCount={setTotalCount} />
      </ContainersPage>
      <ContainerEditModal />
      <ContainerTransferModal />
      <UploadImagesModal />
    </>
  );
};
