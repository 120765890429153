import axios from 'axios';
import Cookies from 'js-cookie';
import { removeCache } from '../../services';

interface SignInProps {
  email: string;
  password: string;
}

interface ForgotPasswordProps {
  email: string;
}

interface CreateNewPasswordProps {
  password: string;
  passwordConfirmation: string;
  token?: string;
}

interface UpdatePasswordProps {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

class AuthService {
  signin = (props: SignInProps) =>
    axios.post('/auth/login', {
      email: props.email,
      password: props.password,
    });

  forgotPassword = (props: ForgotPasswordProps) =>
    axios.patch('/auth/get-password-reset-instruction', {
      email: props.email,
    });

  createNewPassword = (props: CreateNewPasswordProps) =>
    axios.patch(
      '/auth/create-new-password',
      {
        password: props.password,
        passwordConfirmation: props.passwordConfirmation,
      },
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      },
    );

  updatePassword = (props: UpdatePasswordProps) =>
    axios.patch('/auth/update-password', {
      currentPassword: props.currentPassword,
      password: props.password,
      passwordConfirmation: props.passwordConfirmation,
    });

  logout = () => Promise.resolve(true);

  logoutOnError = () => {
    Cookies.remove('jwt');
    Cookies.remove('user');
    removeCache('*');
    window.location.href = '/signin';
  };
}

export const authService = new AuthService();
