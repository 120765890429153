import { useEffect, useState, useRef } from 'react';
import { Typography } from '../typography';
import { Button } from '../button';
import { SearchableSelect } from '../select';
import { enqueueSnackbar } from '../snackbar';
import { ModalBase, ModalWrapper, ModalFooterButtons } from './ModalComponents';
import { useTransferContainerMutation } from '../../hooks';
import { clientYardService } from '../../services';

export const containerTransferModal: any = {};

export const ContainerTransferModal = () => {
  const { mutate } = useTransferContainerMutation();
  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const clientId = useRef('');

  useEffect(() => {
    containerTransferModal.open = () => {
      containerTransferModal.action = 'Transfer';
      containerTransferModal.name = `${containerTransferModal.action} ${containerTransferModal.number}`;
      clientId.current = '';
      setClientName('');

      setOpen(true);
    };
  }, []);

  const handleClientSelect = (obj: any) => {
    clientId.current = obj.id;
    setClientName(obj.label);
  };

  const handleOnClose = () => {
    Object.keys(containerTransferModal).forEach((key) => {
      if (key !== 'open') delete containerTransferModal[key];
    });
    setOpen(!open);
  };

  const handleSubmitClick = async () => {
    containerTransferModal.isSubmitClicked = false;

    const mutateData: any = {
      id: containerTransferModal.containerId,
      clientId: clientId.current,
    };

    mutate(mutateData, {
      onSuccess: () => {
        setOpen(false);
        containerTransferModal.submitClick();
      },
      onError: (err: any) => {
        enqueueSnackbar(err.response.data.message, 'error');
      },
    });
  };

  return (
    <ModalBase id={'container-transfer-modal'} open={open} onClose={handleOnClose}>
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          {containerTransferModal.name}
        </Typography>
        <SearchableSelect
          selectedOptionLabel={clientName}
          label={'Client*'}
          placeholder={'Select Client'}
          onSelect={handleClientSelect}
          queryFn={() =>
            clientYardService.assignedClients({
              id: containerTransferModal.yardId,
              disabledClientIds: [containerTransferModal.clientId],
            })
          }
          queryKey={['clients', 'assigned-clients' + containerTransferModal.yardId]}
          selectedAreasEqaul={{ key: 'id', keys: [clientId.current] }}
        />
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            size={'medium'}
            data-color={'primary'}
            onClick={handleSubmitClick}
            disabled={!clientId.current}
          >
            Transfer
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
