import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableHead,
  TableBody,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          '& tr > *': {
            padding: '1.2rem',

            '& > *': {
              width: 'max-content',
            },
          },
          '& tr > th:first-of-type, & tr > tr:last-child': {
            paddingLeft: '2.4rem',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: '4.4rem',

          '& > tr': {
            cursor: 'initial',
          },

          '& > tr > *': {
            fontFamily: 'Inter',
            fontSize: 'var(--font-size-2)',
            lineHeight: 'var(--line-height-2)',
            fontWeight: 400,
            color: 'var(--gray-80)',
          },

          '&[data-head-type=light]': {
            '& > tr > *': {
              backgroundColor: 'var(--white)',
              borderBottom: '0.2rem solid var(--gray-40)',
            },
          },
          '&[data-head-type=solid]': {
            '& > tr > *': {
              backgroundColor: 'var(--gray-30)',
              borderBottom: 0,
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& > tr > *': {
            fontFamily: 'Inter',
            fontSize: 'var(--font-size-3)',
            lineHeight: 'var(--line-height-3)',
            fontWeight: 'var(--font-weight-400)',
            color: 'var(--gray-100)',
            borderBottom: '0.1rem solid var(--gray-40)',
          },

          '& > tr > th': {
            fontWeight: 500,
          },

          '& > tr svg': {
            display: 'grid',
            fontSize: 'var(--font-size-5)',
          },

          '& > tr:last-child > *': {
            border: 0,
          },

          '&[data-show-last-row-border=true] > tr:last-child > *': {
            borderBottom: '0.1rem solid var(--gray-40)',
          },
          '&[data-body-row-size=small]': {
            '& > tr > *': {
              height: '5.6rem',
              fontSize: 'var(--font-size-2)',
              lineHeight: 'var(--line-height-2)',
            },
          },
          '&[data-body-row-size=medium]': {
            '& > tr > *': {
              height: '6.4rem',
            },
          },
          '&[data-body-row-size=large]': {
            '& > tr > *': {
              height: '7.2rem',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          transition: 'background 0.3s',

          '&:hover': {
            backgroundColor: 'var(--gray-40)',
          },
        },
      },
    },
  },
});

interface TableProps extends MuiTableProps {
  headType?: 'light' | 'solid';
  bodyRowSize?: 'small' | 'medium' | 'large';
  showLastRowBorder?: boolean;
  renderTableHeadRow: React.ReactNode;
  renderTableBodyRows: Array<React.ReactNode>;
}

export const Table: React.FC<TableProps> = ({
  headType = 'light',
  bodyRowSize = 'medium',
  showLastRowBorder = false,
  renderTableHeadRow,
  renderTableBodyRows,
  ...props
}: TableProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiTable sx={{ minWidth: 730 }} aria-label={'simple table'} {...props}>
        <TableHead data-head-type={headType}>{renderTableHeadRow}</TableHead>
        <TableBody data-body-row-size={bodyRowSize} data-show-last-row-border={showLastRowBorder}>
          {renderTableBodyRows}
        </TableBody>
      </MuiTable>
    </ThemeProvider>
  );
};
