import styled from 'styled-components';
import { Paper, Link, CheckboxLabelWrapper } from '../../design-system';

const AuthWrapperBase = styled.div`
  display: grid;
  padding: 10rem 0;
  min-height: 100%;
`;

const AuthPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 4.8rem 6rem;
    margin: auto;
  }
`;

interface AuthWrapperProps {
  children: React.ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }: AuthWrapperProps) => {
  return (
    <AuthWrapperBase>
      <AuthPaper>{children}</AuthPaper>
    </AuthWrapperBase>
  );
};

export const AuthHeaderBase = styled.div`
  display: grid;
  grid-row-gap: 0.8rem;
`;

export const AuthHeaderSubTitle = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.6rem;
  justify-content: center;
`;

export const AuthCheckboxLabelWrapper = styled(CheckboxLabelWrapper)`
  margin: -0.8rem 0;
`;

export const AuthFormBase = styled.div`
  display: grid;
  grid-row-gap: 2.4rem;

  margin-top: 4rem;
`;

export const AuthFooterBase = styled.div`
  display: grid;
  margin-top: 2.4rem;
`;

export const AuthFooterTexts = styled.div`
  display: grid;
  grid-row-gap: 0.4rem;
`;

export const AuthFooterLink = styled(Link)`
  width: max-content;
  margin: auto;
`;

export const AuthFooterBorderLine = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: var(--gray-50);
  margin-top: 2.4rem;
`;

export const AuthFooterSocials = styled.div`
  display: grid;
  grid-row-gap: 1.2rem;

  margin-top: 2.4rem;
`;
