import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableCellWhiteSpace,
  TableDots,
  Pagination,
  Chip,
  Button,
  gateModal,
  enqueueSnackbar,
} from '../../design-system';
import { DashboardHeader } from '../dashboard';
import { Filters } from './Filters';
import { useStore } from '../../context';
import { isShowDots } from './utils';
import { momentTextFormat, showToRoles } from '../../utils';
import {
  useStatesWithFilterCounter,
  useSort,
  usePaginationState,
  useGateInsQuery,
  useCompleteGateInMutation,
  useDeleteGateInMutation,
} from '../../hooks';
import { gateTableDotsActions, requestStatuesLabel, TABLE, MESSAGES } from '../../const';

interface GateInsPageProps {
  innerScroll?: boolean;
}

export const GateInsPage = styled.div<GateInsPageProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const GateInsHeader = ({ totalCount }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Gate Ins: {totalCount}
      </Typography>
      <Button size={'medium'} to={'/dashboard/gateins/add'}>
        Create Request
      </Button>
    </DashboardHeader>
  );
};

export const GateInsBody = ({ setTotalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const navigate = useNavigate();
  const completeMutation = useCompleteGateInMutation();
  const deleteMutation = useDeleteGateInMutation();

  const { page, take, skip, handlePageChange } = usePaginationState();
  const [openDotsMenuIndex, setDotsMenuIndex] = useState(null);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter(
    {
      containerNumber: '',
      containerType: 'All',
      yardCompanyId: '',
      country: '',
      city: '',
      yardId: '',
      clientId: '',
      createdAt: null,
      startDate: null,
      endDate: null,
      completedAt: null,
      licensePlateNumber: '',
      status: 'All',
    },
    { status: 'ACTIVE' },
  );
  const { sortField, sortDirection, handleSort } = useSort({
    sortField: filteredStates.status === 'COMPLETED' ? 'completedAt' : 'createdAt',
    sortDirection: 'desc',
  });

  const { data, isSuccess, isFetching, refetch } = useGateInsQuery({
    ...filteredStates,
    take,
    skip,
    sortField,
    sortDirection,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: any) => {
    if (openDotsMenuIndex !== i) return setDotsMenuIndex(i);

    setDotsMenuIndex(null);
  };

  const handleDotsOptionSelect = async (id: any, val: any, i: any) => {
    Object.keys(gateModal).forEach((key) => {
      if (key !== 'open') delete gateModal[key];
    });

    gateModal.val = val;
    gateModal.containerNumber = data.data[i].number;

    if (val === 'complete') {
      gateModal.type = 'GATE_IN';
      gateModal.containerType = data.data[i].type;
      gateModal.driverFirstName = data.data[i].gateIn.driver?.firstName;
      gateModal.driverLastName = data.data[i].gateIn.driver?.lastName;
      gateModal.licensePlateNumber = data.data[i].gateIn.driver?.licensePlateNumber;
      gateModal.fc = () =>
        completeMutation.mutate(id, {
          onSuccess: () => {
            enqueueSnackbar(MESSAGES.GATE_IN.SUCCESS.COMPLETED, 'success');
            refetch();
          },
          onError: () => enqueueSnackbar(MESSAGES.GATE_IN.ERROR.NOT_COMPLETED, 'error'),
        });
    }
    if (val === 'delete') {
      gateModal.fc = () =>
        deleteMutation.mutate(id, {
          onSuccess: () => {
            enqueueSnackbar(MESSAGES.GATE_IN.SUCCESS.DELETED, 'success');
            refetch();
          },
          onError: () => enqueueSnackbar(MESSAGES.GATE_IN.ERROR.NOT_DELETED, 'error'),
        });
    }

    gateModal.open();
  };

  const handleRowClick = (to: string) => {
    if (openDotsMenuIndex !== null) return;
    navigate(to);
  };

  return (
    <TableWrapper columns={showToRoles('admin', user) ? [7, 10] : [20]}>
      <Filters
        handleSort={handleSort}
        setStates={setStates}
        withoutActions={!!showToRoles('client', user)}
        {...states}
      />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder={true}
        stickyHeader
        renderTableHeadRow={
          <TableRow>
            {showToRoles('admin', user) && <TableHeadCell align={'left'}>Actions</TableHeadCell>}
            {showToRoles('admin', user) && <TableHeadCell align={'left'}>Client</TableHeadCell>}
            <TableHeadCell align={'left'}>Container number</TableHeadCell>
            <TableHeadCell align={'left'}>Container type</TableHeadCell>
            <TableHeadCell align={'left'}>Status</TableHeadCell>
            <TableHeadCell
              align={'left'}
              columnIdx={'createdAt'}
              sortField={sortField}
              sortDirection={sortDirection}
              handleSort={handleSort}
            >
              Created at
            </TableHeadCell>
            {(states.status === 'All' || states.status === 'COMPLETED') && (
              <TableHeadCell
                align={'left'}
                columnIdx={'completedAt'}
                sortField={sortField}
                sortDirection={sortDirection}
                handleSort={handleSort}
              >
                Completed at
              </TableHeadCell>
            )}
            <TableHeadCell align={'left'}>Gate-In Date From</TableHeadCell>
            <TableHeadCell align={'left'}>Gate-In Date To</TableHeadCell>
            {states.status !== 'ACTIVE' && (
              <TableHeadCell align={'left'}>License Plate Number</TableHeadCell>
            )}
          </TableRow>
        }
        renderTableBodyRows={
          isSuccess &&
          data.data.map((row: any, i: any) => (
            <TableRow
              key={row.number + i}
              onClick={() => handleRowClick(`/dashboard/gateins/${row.id}`)}
            >
              {showToRoles('admin', user) ? (
                isShowDots(row.gateIn, user) ? (
                  <TableIconCell component={'th'} align={'left'}>
                    <TableDots
                      open={openDotsMenuIndex === i}
                      onToggle={() => handleDotsToggle(i)}
                      options={
                        showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user) &&
                        row.gateIn.status === 'ACTIVE'
                          ? [...gateTableDotsActions, { label: 'Delete', value: 'delete' }]
                          : gateTableDotsActions
                      }
                      onSelect={(val) => {
                        handleDotsOptionSelect(row.id, val, i);
                      }}
                    />
                  </TableIconCell>
                ) : (
                  <TableCell component={'th'} align={'left'} />
                )
              ) : null}
              {showToRoles('admin', user) && (
                <TableCell align={'left'}>{row.clientYard?.client.code}</TableCell>
              )}
              {showToRoles('admin', user) && <TableCell align={'left'}>{row.number}</TableCell>}
              {showToRoles('client', user) && (
                <TableCell component={'th'} align={'left'}>
                  {row.number}
                </TableCell>
              )}
              <TableCellWhiteSpace align={'left'}>{row.type}</TableCellWhiteSpace>
              <TableCell align={'left'}>
                <Chip label={requestStatuesLabel[row.gateIn.status]} size={'small'} />
              </TableCell>
              <TableCell align={'left'}>
                {row.gateIn.createdAt && momentTextFormat(row.gateIn.createdAt, user)}
              </TableCell>
              {(states.status === 'All' || states.status === 'COMPLETED') && (
                <TableCell align={'left'}>
                  {row.gateIn.completedAt && momentTextFormat(row.gateIn.completedAt, user)}
                </TableCell>
              )}
              <TableCell align={'left'}>
                {row.gateIn.validFrom && momentTextFormat(row.gateIn.validFrom, user)}
                {(states.status === 'All' || states.status === 'COMPLETED') &&
                  !row.gateIn.validFrom &&
                  momentTextFormat(row.gateIn.createdAt, user)}
              </TableCell>
              <TableCell align={'left'}>
                {row.gateIn.validTo && momentTextFormat(row.gateIn.validTo, user)}
                {(states.status === 'All' || states.status === 'COMPLETED') &&
                  !row.gateIn.validTo &&
                  momentTextFormat(row.gateIn.completedAt, user)}
              </TableCell>
              {states.status !== 'ACTIVE' && (
                <TableCell align={'left'}>{row.gateIn.driver?.licensePlateNumber}</TableCell>
              )}
            </TableRow>
          ))
        }
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
