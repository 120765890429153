export const MESSAGES = {
  AUTH: {
    SUCCESS: {
      LOG_OUT: 'Successfully logged out',
      RESET_PASSWORD: 'Please confirm your email for Reset Password',
      CHANGE_PASSWORD: 'Password successfully updated!',
    },
    ERROR: {
      ACCOUNT_NOT_FOUND: 'Account does not exist',
      INVALID_TOKEN: 'Access denied: Invalid Token',
    },
  },
  CONTAINERS: {
    SUCCESS: {
      DELETED: 'Container successfully deleted!',
      EDITED: 'Container successfully edited!',
      FILES_CHANGE: 'Changes successfully made!',
      TRANSFER: 'Container successfully transfered!',
    },
    ERROR: {
      NOT_DELETED: 'Container not deleted!',
      NOT_EDITED: 'Container not edited!',
      DUPLICATED:
        'Unable to create Gate In requests: Active Gate In requests with the same container numbers in the Yard',
    },
  },
  GATE_IN: {
    SUCCESS: {
      COMPLETED: 'Gate in successfully completed!',
      EDIT: 'Gate in successfully edited!',
      DELETED: 'Gate in successfully deleted!',
    },
    ERROR: {
      NOT_COMPLETED: 'Gate in not completed!',
      NOT_EDITED: 'Gate in not edited!',
      NOT_DELETED: 'Gate in not deleted!',
      CONTAINER_DUPLICATED: 'Container is Duplicated!',
    },
  },
  GATE_OUT: {
    SUCCESS: {
      COMPLETED: 'Gate out successfully completed!',
      EDIT: 'Gate out successfully edited!',
      DELETED: 'Gate out successfully deleted!',
    },
    ERROR: {
      NOT_COMPLETED: 'Gate out not completed!',
      NOT_EDITED: 'Gate out not edited!',
      NOT_DELETED: 'Gate out not deleted!',
    },
  },
  BELONGS_TO: {
    SUCCESS: {
      CREATED: 'Company successfully created',
    },
  },
  CLIENT: {
    SUCCESS: {
      ASSIGN_YARDS: 'Yards were activated for the Client',
      DEACTIVATED_YARDS: 'Yards were de-activated for the Client',
      REACTIVATED_YARDS: 'Yards were re-activated for the Client',
    },
    ERROR: {
      YARDS_NOT_ASSIGNED: 'Yards were not activated for the Client',
      YARDS_NOT_DEACTIVATED: 'Yards were not de-activated for the Client',
      YARDS_NOT_REACTIVATED: 'Yards were not re-activated for the Client',
    },
  },
  YARD: {
    SUCCESS: {
      ASSIGN_CLIENTS: 'Client were activated for the Yards',
      DEACTIVATED_CLIENTS: 'Client were de-activated for the Yards',
      REACTIVATED_CLIENTS: 'Client were re-activated for the Yards',
      SEND_CONTRACT: 'Your contract successfully sended',
    },
    ERROR: {
      CLIENTS_NOT_ASSIGNED: 'Clients were not activated for the Yard',
      CLIENTS_NOT_DEACTIVATED: 'Clients were not de-activated for the Yard',
      CLIENTS_NOT_REACTIVATED: 'Clients were not re-activated for the Yard',
    },
  },
  EMPLOYEE: {
    SUCCESS: {
      CREATED: 'Employee successfully created',
      ACTIVATED: 'Employee successfully activated',
      DEACTIVATED: 'Employee successfully deactivated',
    },
  },
  CONSTRUCTION: {
    HEADING: 'We will be back',
  },
  ERROR_BOUNDARY: {
    HEADING: 'Something went wrong',
    DEFAULT: 'Something went wrong',
    DETAIL: 'Probably, it is not your fault. Please come back later!',
  },
  NETWORK: {
    ERROR: 'Network error',
    WARNING: {
      TRY_AGAIN: 'Please try again (PRISMA DISCONNECTED)',
    },
  },
};

export const INPUT_ERRORS = {
  REQUIRED: {
    YARD_COMPANY_NAME: 'Yard Company Name is required',
    YARD_COMPANY: 'Yard Company is required',
    YARD_NAME: 'Yard Name is required',
    CLIENT_NAME: 'Client Name is required',
    CONTAINER_NUMBER: 'Container Number is required',
    CONTAINER_TYPE: 'Container Type is required',
    DRIVER_NAME: 'Driver Name is required',
    LICENSE_PLATE_NUMBER: 'License Plate Number is required',
    FREE_DAYS: 'Free Days is required',
    STORAGE_FEE: 'Storage Fee is required',
    GATE_IN_CRANE_FEE: 'Gate In Crane Fee is required',
    GATE_OUT_CRANE_FEE: 'Gate Out Crane Fee is required',
    NON_STANDART_TRANSPORT_FEE: 'Non Standard Transport Fee is required',
    DATE: 'Date is required',
    RELEASE_NUMBER: 'Release Number is required',
    PRICE: 'Price is required',
    COMMENT: 'Comment is required',
    COMPANY_LEGAL_NAME: 'Company Legal Name is required',
    CODE: 'Code is required',
    STREET_ADDRESS: 'Street Address is required',
    CITY: 'City is required',
    ZIP_CODE: 'Zip Code is required',
    COUNTRY: 'Country is required',
    CONTACT_PERSON_FIRST_NAME: 'Contact Person First Name is required',
    CONTACT_PERSON_LAST_NAME: 'Contact Person Last Name is required',
    CONTACT_PERSON_EMAIL: 'Contact Person Email is required',
    COMPANY_EMAIL: 'Company Email is required',
    COMPANY_PHONE_NUMBER: 'Company Phone Number is required',
    EMAIL: 'Email is required',
    CONTACT_PERSON_PHONE_NUMBER: 'Contact Person Phone Number is required',
    PHONE_NUMBER: 'Phone Number is required',
    FIRST_NAME: 'First Name is required',
    LAST_NAME: 'Last Name is required',
    CURRENT_PASSWORD: 'Current Password is required',
    NEW_PASSWORD: 'New Password is required',
    NEW_PASSWORD_CONFIRM: 'New Password Confirmation is required',
    PASSWORD: 'Password is required',
    PASSWORD_CONFIRM: 'Password Confirmation is required',
    COMPANY: 'Company is required',
  },
  NOT_VALID: {
    YARD_COMPANY: 'Yard Company is not valid',
    YARD_NAME: 'Yard Name is not valid',
    YARD_NAME_LENGTH: 'Yard Name must have between 6 and 50 characters',
    CONTAINERS: 'Containers is not valid',
    CONTAINER_NUMBER: 'Container Number is not valid',
    CONTAINER_NUMBER_SHORT: 'Not valid number',
    CONTAINER_NUMBER_MIN: 'Min 3 characters',
    DRIVER_NAME: 'Driver Name is not valid',
    LICENSE_PLATE_NUMBER: 'License Plate Number is not valid',
    LICENSE_PLATE_NUMBER_LENGTH: 'License Plate must have between 4 and 10 characters',
    FREE_DAYS: 'Free Days is not valid',
    STORAGE_FEE: 'Storage Fee is not valid',
    GATE_IN_CRANE_FEE: 'Gate In Crane Fee is not valid',
    GATE_OUT_CRANE_FEE: 'Gate Out Crane Fee is not valid',
    NON_STADART_TRANSPORT_FEE: 'Non Standard Transport Fee is not valid',
    DATE: 'Date is not valid',
    PRICE: 'Price is not valid',
    COMPANY_LEGAL_NAME: 'Company Legal Name is not valid',
    COMPANY_LEGAL_NAME_LENGTH: 'Company Legal Name must have between 4 and 100 characters',
    CODE: 'Code is not valid',
    CODE_LENGTH: 'Code must have between 4 and 8 characters',
    STREET_ADDRESS: 'Street Address is not valid',
    STREET_ADDRESS_LENGTH: 'Street Address must have between 5 and 100 characters',
    CITY: 'City is not valid',
    CITY_LENGTH: 'City must have between 1 and 85 characters',
    ZIP_CODE: 'Zip Code is not valid',
    ZIP_CODE_LENGTH: 'Zip Code must have between 2 and 12 characters',
    COUNTRY: 'Country is not valid',
    COUNTRY_LENGTH: 'Country must have between 1 and 85 characters',
    CONTACT_PERSON_FIRST_NAME: 'Contact Person First Name is not valid',
    CONTACT_PERSON_FIRST_NAME_LENGTH: 'Contact Person First Name must have min 3 characters',
    CONTACT_PERSON_LAST_NAME: 'Contact Person Last Name is not valid',
    CONTACT_PERSON_LAST_NAME_LENGTH: 'Contact Person Last Name must have min 3 characters',
    CONTACT_PERSON_EMAIL: 'Contact Person Email is not valid',
    COMPANY_EMAIL: 'Company Email is not valid',
    COMPANY_EMAIL_LENGTH: 'Company Email must have between 6 and 50 characters',
    COMPANY_PHONE_NUMBER: 'Company Phone Number is not valid',
    COMPANY_PHONE_NUMBER_LENGTH: 'Company Phone Number must have between 7 and 15 characters',
    EMAIL: 'Email is not valid',
    EMAIL_LENGTH: 'Email must have between 6 and 50 characters',
    CONTACT_PERSON_PHONE_NUMBER: 'Contact Person Phone Number is not valid',
    CONTACT_PERSON_PHONE_NUMBER_LENGTH:
      'Contact Person Phone Number must have between 7 and 15 characters',
    PHONE_NUMBER: 'Phone Number is not valid',
    PHONE_NUMBER_LENGTH: 'Phone Number must have between 7 and 15 characters',
    FIRST_NAME: 'First Name is not valid',
    FIRST_NAME_LENGTH: 'First Name must have between 2 and 30 characters',
    LAST_NAME: 'Last Name is not valid',
    LAST_NAME_LENGTH: 'Last Name must have between 2 and 30 characters',
    NEW_PASSWORD: 'New Password is not valid',
    NEW_PASSWORD_LENGTH: 'New Password length must have between 8 and 16 characters',
    NOT_NEW_PASSWORD: 'Current and New Password are the same',
    CURRENT_PASSWORD: 'Current Password is not valid',
    CURRENT_PASSWORD_LENGTH: 'Current Password length must have between 8 and 16 characters',
    PASSWORD: 'Password is not valid',
    PASSWORD_LENGTH: 'Password length must have between 8 and 16 characters',
    NEW_PASSWORD_CONFIRM: 'New Password Confirmation is not valid',
    PASSWORD_CONFIRM: 'Password Confirmation is not valid',
    COMPANY: 'Company is not valid',
    RELEASE_NUMBER: 'Release Number is not valid',
    RELEASE_NUMBER_LENGTH: 'Release Number must have between 1 and 10 characters',
    COMMENT: 'Comment is not valid',
    COMMENT_LENGTH: 'Comment must have between 10 and 200 characters',
  },
};

export const TOOLTIP = {
  PASSWORD_SYMBOLS:
    'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (!?/)',
  PASSWORD_MIN: 'Password must have at least 8 characters',
  PASSWORD_MAX: 'Password can have at most 16 characters',
};
