import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import validator from 'validator';
import { TooltipWithRules } from '../tooltip';
import { Input, InputBaseProps } from './Input';
import { useCloseWithOutsideClick } from '../../hooks';
import { TOOLTIP, REGEX } from '../../const';

export const InputPassword: React.FC<InputBaseProps> = ({ ...props }: InputBaseProps) => {
  const [openTooltip, setOpenTooltip, tooltipRef] = useCloseWithOutsideClick();

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  return (
    <Input
      type={'password'}
      withoutSpace
      clickableIcon
      endIcon={
        <TooltipWithRules
          onClose={handleTooltipClose}
          open={openTooltip}
          placement={'top'}
          arrow
          disableFocusListener
          disableHoverListener
          disableTouchListener
          rules={[
            {
              text: TOOLTIP.PASSWORD_SYMBOLS,
              isValid: REGEX.PASSWORD.test(`${props.defaultValue}`),
            },
            {
              text: TOOLTIP.PASSWORD_MIN,
              isValid: validator.isLength(`${props.defaultValue}`, { min: 8 }),
            },
            {
              text: TOOLTIP.PASSWORD_MAX,
              isValid: validator.isLength(`${props.defaultValue}`, { max: 16 }),
            },
          ]}
        >
          <ErrorOutlineIcon ref={tooltipRef} onClick={handleTooltipOpen} />
        </TooltipWithRules>
      }
      {...props}
    />
  );
};
