import { Radio as MuiRadio, RadioProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { colors } from '../../const';

const theme = createTheme({
  palette: {
    primary: {
      main: colors['--primary-100'],
    },
    error: {
      main: colors['--red-100'],
    },
    action: {
      disabled: colors['--gray-50'],
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          margin: '-0.9rem',
          '& .MuiSvgIcon-fontSizeMedium': {
            fontSize: '2rem',
          },
        },
      },
    },
  },
});

export const Radio: React.FC<RadioProps> = ({ ...props }: RadioProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiRadio {...props} />
    </ThemeProvider>
  );
};

const RadioLabelWrapper = styled.label<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: var(--spacing-3);
  align-items: center;
  width: max-content;

  user-select: none;
  cursor: pointer;

  & svg {
    font-size: 2rem;
  }
  & > *:nth-child(2) {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: var(--spacing-1);
    align-items: center;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
`;

interface RadioLabelProps extends RadioProps {
  label: string;
  IconComponent?: any;
}

export const RadioLabel: React.FC<RadioLabelProps> = ({
  label,
  IconComponent,
  ...props
}: RadioLabelProps) => {
  return (
    <RadioLabelWrapper disabled={props.disabled}>
      <ThemeProvider theme={theme}>
        <MuiRadio {...props} size={'medium'} />
      </ThemeProvider>
      <Typography variant={'textM'}>
        {IconComponent && <IconComponent />}
        {label}
      </Typography>
    </RadioLabelWrapper>
  );
};
