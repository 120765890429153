import axios from 'axios';
import JSZip from 'jszip';
import { showToRoles, downloadBlob } from '../../utils';
import { ImageObjType, User } from '../../types';

interface ContainerProps {
  number?: string;
  yardId?: string;
  clientId?: string;
  status?: string;
  short?: boolean;
  types?: string | string[] | any;
}

class ContainersService {
  getAll = (props: any) => axios.get(`/containers`, props);

  get = (id: any) => axios.get(`/containers/${id}`);

  options =
    ({ ...props }: ContainerProps, user: User) =>
    async () => {
      if (props.types) props.types = props.types.split(', ');
      const params: any = {
        ...props,
        notLoading: true,
      };
      if (showToRoles('client', user)) params.clientId = user.clientEmployee.clientId;
      if (showToRoles('client', user) && !props.yardId) return [];
      if (!params.clientId && !props.clientId) return [];

      const res = await this.getAll({
        params,
      });

      const data = res.data.data.map((container: any) => ({
        label: container.number,
        id: container.id,
        containerType: container.type || '',
      }));

      return data;
    };

  uploadImages = (id: string, photos: ImageObjType[] = []) => {
    if (!photos.length) return true;

    const formData = new FormData();
    for (const i in photos.map((obj) => obj.file)) {
      formData.append(`images`, photos[i].file);
    }

    return axios.patch(`/containers/${id}/upload-images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  deleteImages = (id: string, photos: ImageObjType[] = []) => {
    if (!photos.length) return true;

    return axios.patch(`/containers/${id}/delete-images`, { names: photos.map((obj) => obj.name) });
  };

  updateImages = ({ id, photos = [] }: { id: string; photos: ImageObjType[] }) =>
    Promise.all([
      this.uploadImages(
        id,
        photos.filter((obj) => obj.action === 'upload'),
      ),
      this.deleteImages(
        id,
        photos.filter((obj) => obj.action === 'delete'),
      ),
    ]);

  downloadImages = (images: any[], name: string) => {
    const zip = new JSZip();

    const promises: any = [];

    images.forEach((image) => {
      const promise = axios
        .get(image.url, { responseType: 'blob' })
        .then((res) => res.data)
        .then((blob) => {
          zip.file(image.name, blob);
        });

      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        downloadBlob(content, `${name}.zip`);
      });
    });
  };

  uploadDocuments = (id: string, documents: ImageObjType[] = []) => {
    if (!documents.length) return true;

    const formData = new FormData();
    for (const i in documents.map((obj) => obj.file)) {
      formData.append(`documents`, documents[i].file);
    }

    return axios.patch(`/containers/${id}/upload-documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  deleteDocuments = (id: string, documents: ImageObjType[] = []) => {
    if (!documents.length) return true;

    return axios.patch(`/containers/${id}/delete-documents`, {
      names: documents.map((obj) => obj.name),
    });
  };

  updateDocuments = async ({ id, documents = [] }: { id: string; documents?: ImageObjType[] }) =>
    Promise.all([
      this.uploadDocuments(
        id,
        documents.filter((obj) => obj.action === 'upload'),
      ),
      this.deleteDocuments(
        id,
        documents.filter((obj) => obj.action === 'delete'),
      ),
    ]);

  downloadDocument = (href: string, name: string) => {
    return axios.get(href, { responseType: 'blob' }).then((res) => {
      downloadBlob(res.data, name);
    });
  };

  viewDocument = (href: any = '') => {
    if (!href) return;
    let url = href;

    if (typeof href === 'object') url = window.URL.createObjectURL(href);

    window.open(url, '_blank');

    if (typeof href === 'object') window.URL.revokeObjectURL(url);
  };

  add = (props: any) => axios.post(`/gate-ins/now`, props);

  edit = ({ id, ...props }: any) => axios.patch(`/containers/${id}`, props);

  transfer = ({ id, ...props }: any) => axios.patch(`/containers/${id}/transfer`, props);
}

export const containersService = new ContainersService();
