import { useState } from 'react';
import { useSaveFilters } from './use-save-filters';

interface UseSortProps {
  sortField: string;
  sortDirection: 'asc' | 'desc';
}

export const useSort = (defaultValue: UseSortProps) => {
  const [{ sortField: oldSortField, sortDirection: oldSortDirection }, setOldState] =
    useSaveFilters('sort', true, defaultValue);
  const [{ sortField, sortDirection }, setState] = useState({
    sortField: oldSortField,
    sortDirection: oldSortDirection,
  });

  const handleSort = (column?: string, isSet?: boolean) => {
    let newState: any;

    if (!column) {
      newState = defaultValue;
    } else if (isSet) {
      newState = { sortField: column, sortDirection: 'desc' };
    } else if (sortField !== column) {
      newState = { sortField: column, sortDirection: 'desc' };
    } else {
      newState = { sortField: column, sortDirection: sortDirection === 'desc' ? 'asc' : 'desc' };
    }

    setOldState(newState);
    setState(newState);
  };

  return { sortField, sortDirection, handleSort };
};
