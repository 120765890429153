import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from '../link';
import { colors } from '../../const';

const theme = (iconColor: keyof typeof colors) =>
  createTheme({
    palette: {
      primary: {
        main: colors[iconColor],
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            '& > svg': {
              fontSize: '2.4rem',
            },
          },
        },
      },
    },
  });

interface IconButtonProps extends MuiIconButtonProps {
  to?: string;
  iconColor?: keyof typeof colors;
  hasOuterTheme?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
  to,
  iconColor = '--gray-100',
  hasOuterTheme,
  children,
  ...props
}: IconButtonProps) => {
  return (
    <Link to={to}>
      <ThemeProvider
        theme={
          hasOuterTheme
            ? (outerTheme) => ({ ...theme(iconColor), ...outerTheme })
            : theme(iconColor)
        }
      >
        <MuiIconButton color={'primary'} {...props}>
          {children}
        </MuiIconButton>
      </ThemeProvider>
    </Link>
  );
};
