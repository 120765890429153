import { Link } from '../design-system';
import { REGEX } from '../const';

export const linkConverter = (text: string) => {
  const parts = text.split(REGEX.URL);

  const elements = parts.map((part, index) => {
    const isLink = REGEX.URL.test(part);

    return isLink ? (
      <Link key={index} to={part} target={'blank'} withunderline>
        {part}
      </Link>
    ) : (
      part
    );
  });

  return <>{elements}</>;
};
